import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './carouselSlider.css';
import { Carousel } from 'react-responsive-carousel';
import * as sessionSelectors from '../../redux/session/sessionSelectors';
import * as startPageNewsSelectors from '../../redux/startPageIndex/startPageIndexSelectors';
import * as onStartPageInit from '../../redux/startPageIndex/startPageIndexOperations';
import style from './indexCarousel.module.css';

class NewsCarousel extends Component {
  state = {};

  render() {
    const { newsListRU, newsListUK, locale } = this.props;
    const newsList = locale === 'uk' ? newsListUK : newsListRU;
    return (
      <div className="carousel">
        {newsList && Array.isArray(newsList) && (
          <Carousel
            showIndicators={false}
            showThumbs={false}
            showStatus={false}
            autoPlay
            infiniteLoop
          >
            {newsList.map(el => (
              <div className={style.element} key={el.id}>
                <img className={style.imgSlide}/>
                <p className={style.date}>{el.published}</p>
                <p className={style.title}>{el.title}</p>
                <p className={style.description}>{el.description}</p>
                <Link className={style.link} to={`/${locale}${el.url}`} />
              </div>
            ))}
          </Carousel>
        )}
      </div>
    );
  }
}

NewsCarousel.defaultProps = {
  newsListUK: [
    {
      id: 1,
      published: '01.01.2019',
      title: 'title',
      description: 'about',
      url: '/',
    },
  ],
};

NewsCarousel.propTypes = {
  newsListUK: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      published: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
};

const mapStateToProps = state => ({
  locale: sessionSelectors.locale(state),
  newsListRU: startPageNewsSelectors.startPageNewsRU(state),
  newsListUK: startPageNewsSelectors.startPageNewsUK(state),
});

const mapDispatchToProps = {
  onInitData: onStartPageInit.onStartPageNewsOperation,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsCarousel);
