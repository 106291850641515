import axios from 'axios';
import {
  singleNewsRequest,
  singleNewsSuccessUK,
  singleNewsSuccessRU,
  singleNewsErrorUK,
  singleNewsErrorRU,
} from './singleNewsActions';
import { locale } from '../session/sessionSelectors';
import { translitiration } from '../translitiration/translitirationActions';
import baseURL from '../baseURL';

const setAxiosLang = lang => {
  axios.defaults.headers.common['Accept-Language'] = lang;
};

// eslint-disable-next-line import/prefer-default-export
export const singleNewsOperation = url => (dispatch, getState) => {
  axios.defaults.baseURL = `${baseURL}`;
  dispatch(singleNewsRequest());

  setAxiosLang(locale(getState()));

  axios
    .get(`${url}`)
    .then(response => {
      if (locale(getState()) === 'uk') {
        try {
          dispatch(singleNewsSuccessUK(response.data));
          dispatch(translitiration(response.data.url.ru.url));
        } catch (error) {
          // dispatch(singleNewsErrorUK(error));
        }
      } else {
        try {
          dispatch(singleNewsSuccessRU(response.data));
          dispatch(translitiration(response.data.url.uk.url));
        } catch (error) {
          // dispatch(singleNewsErrorRU(error));
        }
      }
    })
    .catch(error => {
      console.log(error);
      if (locale(getState()) === 'uk') dispatch(singleNewsErrorUK(error));
      else {
        dispatch(singleNewsErrorRU(error));
      }
    });
};
