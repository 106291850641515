import React, { Component } from 'react';
import Media from 'react-media';
import { ReactSvgInjector, Mutate } from 'react-svg-injector';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  ViberShareButton,
} from 'react-share';
import { connect } from 'react-redux';
import style from './singleArticle.module.css';
import SideNav from './sideNav/sideNav';
import telegramm from '../../assets/img/icons/telegramArticle.svg';
import viber from '../../assets/img/icons/viberArticle.svg';
import facebook from '../../assets/img/icons/facebookArticle.svg';
import twitter from '../../assets/img/icons/twitterArticle.svg';
import * as sessionSelectors from '../../redux/session/sessionSelectors';
import * as singleNewsSelectors from '../../redux/singleNews/singleNewsSelectors';
import * as singleNewsOperation from '../../redux/singleNews/singleNewsOperations';
import { resetTranslitiration } from '../../redux/translitiration/translitirationActions';
import { resetSingleNews } from '../../redux/singleNews/singleNewsActions';

const publishedTime = time => {
  const newTime = new Date(time);
  return newTime;
};

function createMarkup(compiled) {
  return { __html: compiled };
}

const viberText = text => `${text.substring(0, 47)}...`;

class SinglePageArticle extends Component {
  state = {};

  componentDidMount = () => {
    window.scrollTo(0, 0);
    const { onInitData, resetTranslitiration, resetSingleNews } = this.props;
    resetTranslitiration();
    resetSingleNews();
    const url = this.props.location.pathname.slice(3);
    onInitData(url);
  };

  componentDidUpdate = prevProps => {
    const { onInitData, resetTranslitiration, resetSingleNews } = this.props;
    if (prevProps.location.pathname !== this.props.location.pathname) {
      resetTranslitiration();
      resetSingleNews();
      onInitData(this.props.location.pathname.slice(3));
    }
  };

  render() {
    const {
      singleNewsRU,
      singleNewsUK,
      locale,
      singleNewsErrorRU,
      singleNewsErrorUK,
      link,
      singleNewsLoading,
      routes,
    } = this.props;
    const { news } = routes;
    const singleNews = locale === 'uk' ? singleNewsUK : singleNewsRU;
    const errorPublication =
      locale === 'uk' ? singleNewsErrorUK : singleNewsErrorRU;
    return (
      <div className="wrapper">
        <div className={style.newsWrapper}>
          <Link className={style.link} to={`/${locale}/${news}`}>
            <svg
              className={style.arrow}
              width="8"
              height="12"
              viewBox="0 0 8 12"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M6.77762 11.8801L7.37874 11.2789C7.45901 11.1988 7.49902 11.1066 7.49902 11.0022C7.49902 10.8982 7.45901 10.8059 7.37874 10.7258L2.65323 6.00006L7.37861 1.27451C7.45889 1.19436 7.4989 1.10213 7.4989 0.997933C7.4989 0.893696 7.45889 0.801463 7.37861 0.721359L6.77745 0.120283C6.69735 0.04001 6.60512 0 6.50088 0C6.39673 0 6.3045 0.0401363 6.22439 0.120283L0.621065 5.72349C0.540919 5.80359 0.500867 5.89587 0.500867 6.00006C0.500867 6.10426 0.540919 6.19637 0.621065 6.27643L6.22439 11.8801C6.30454 11.9602 6.39677 12 6.50088 12C6.60512 12 6.69735 11.9602 6.77762 11.8801Z" />
            </svg>
            <span className={style.linkText}>{link}</span>
          </Link>
          {singleNews && (
            <div className={style.articleTitle}>
              <h1 className={style.articleTitleH1}>{singleNews.title}</h1>
            </div>
          )}
          {singleNews && (
            <Helmet
              title={`НиколаевВодоканал: ${singleNews.title}`}
              meta={[
                { rel: 'canonical', href: `${this.props.location.pathname}` },
                {
                  property: 'og:locale',
                  content: `${locale}_${locale.toUpperCase()}`,
                },
                { property: 'og:type', content: 'article' },
                { property: 'og:title', content: `${singleNews.title}` },
                {
                  property: 'og:description',
                  content: `${singleNews.description}`,
                },
                {
                  property: 'og:url',
                  content: `${document.location.href}`,
                },
                {
                  property: 'article:section',
                  content: `${singleNews.category}`,
                },
                {
                  property: 'article:published_time',
                  content: `${singleNews.published}`,
                },
                {
                  property: 'article:modified_time',
                  content: `${singleNews.published}`,
                },
                {
                  property: 'og:image',
                  content: `https://dev2.cybercreation.team${singleNews.icon}`,
                },
                {
                  property: 'og:image:width',
                  content: '300',
                },
                {
                  property: 'og:image:height',
                  content: '300',
                },
                {
                  property: 'og:image:alt',
                  content: `https://dev2.cybercreation.team${singleNews.image}`,
                },
                {
                  name: 'twitter:card',
                  content: 'summary',
                },
                {
                  name: 'twitter:title',
                  content: `${singleNews.title}`,
                },
                {
                  name: 'twitter:description',
                  content: `${singleNews.description}`,
                },
                {
                  name: 'twitter:image',
                  content: `https://dev2.cybercreation.team${singleNews.icon}`,
                },
                {
                  name: 'twitter:url',
                  content: `${document.location.href}`,
                },
              ]}
            />
          )}
          <div className={style.grid}>
            <div>
              {singleNews && (
                <div className={style.singleArticle}>
                  <div className={style.date}>
                    <span className={style.dateText}>
                      {singleNews.published}{' '}
                      {publishedTime(singleNews.published_).getHours()}:
                      {publishedTime(singleNews.published_).getMinutes()}
                    </span>{' '}
                    <div className={style.linkWrapper}>
                      <TelegramShareButton
                        url={document.location.href}
                        className={style.socialLink}
                        title={singleNews.title}
                      >
                        <ReactSvgInjector
                          src={telegramm}
                          alt="icon"
                          className={style.social}
                        >
                          <Mutate selector="g" />
                        </ReactSvgInjector>
                      </TelegramShareButton>
                      <ViberShareButton
                        title={viberText(singleNews.title)}
                        className={style.socialLink}
                        url={document.location.href}
                      >
                        <ReactSvgInjector
                          src={viber}
                          alt="icon"
                          className={style.social}
                        >
                          <Mutate selector="g" />
                        </ReactSvgInjector>
                      </ViberShareButton>
                      <FacebookShareButton
                        className={style.socialLink}
                        url={document.location.href}
                      >
                        <ReactSvgInjector
                          src={facebook}
                          alt="icon"
                          className={style.social}
                        >
                          <Mutate selector="g" />
                        </ReactSvgInjector>
                      </FacebookShareButton>
                      <TwitterShareButton
                        title={singleNews.title}
                        url={document.location.href}
                        className={style.socialLink}
                      >
                        <ReactSvgInjector
                          src={twitter}
                          alt="icon"
                          className={style.social}
                        >
                          <Mutate selector="g" />
                        </ReactSvgInjector>
                      </TwitterShareButton>
                    </div>
                  </div>
                  {singleNews.icon && (
                    <picture>
                      <source srcSet={singleNews.icon} type="image/webp" />
                      <source srcSet={singleNews.icon} type="image/jp2" />
                      <source srcSet={singleNews.icon} type="image/jpg" />
                      <img
                        className={style.imgFront}
                        src={singleNews.icon}
                        alt="news img"
                      />
                    </picture>
                  )}
                  <div
                    className={style.singleArticle}
                    dangerouslySetInnerHTML={createMarkup(singleNews.compiled)}
                  />
                </div>
              )}
            </div>
            <Media query={{ minWidth: 1200 }}>
              {matches =>
                matches && (
                  <div className={style.SideNav}>
                    {!singleNewsLoading && !errorPublication && singleNews && (
                      <SideNav routes={routes} />
                    )}
                  </div>
                )
              }
            </Media>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  locale: sessionSelectors.locale(state),
  singleNewsRU: singleNewsSelectors.singleNewsRU(state),
  singleNewsUK: singleNewsSelectors.singleNewsUK(state),
  singleNewsErrorRU: singleNewsSelectors.singleNewsErrorRU(state),
  singleNewsErrorUK: singleNewsSelectors.singleNewsErrorUK(state),
  singleNewsLoading: singleNewsSelectors.singleNewsLoading(state),
});

const mapDispatchToProps = {
  onInitData: singleNewsOperation.singleNewsOperation,
  resetTranslitiration,
  resetSingleNews,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SinglePageArticle),
);
