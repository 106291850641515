export const newsCategory1UK = state =>
  state.news.newsCategory1.newsCategory1UK;
export const newsCategory1RU = state =>
  state.news.newsCategory1.newsCategory1RU;
export const newsCategory1ErrorUK = state =>
  state.news.newsCategory1.newsCategory1ErrorRU;
export const newsCategory1ErrorRU = state =>
  state.news.newsCategory1.newsCategory1ErrorRU;
export const newsCategory1Loading = state => state.news.newsCategory1.loading;
export const newsCategory1PageNumberUK = state =>
  state.news.newsCategory1.newsCategory1PageNumberUK;
export const newsCategory1PageNumberRU = state =>
  state.news.newsCategory1.newsCategory1PageNumberRU;
