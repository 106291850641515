import axios from "axios"
import jwt from "jwt-decode"
import {
  // loginError,
  // loginRequest,
  loginRequestEnterprise,
  // loginSuccess,
  loginErrorData,
  loginRequestData,
  loginSuccessDataUA,
  loginSuccessDataRU,
  refreshSuccess,
  logOut,
  loginErrorEnterprise,
} from "./sessionActions"
import { getTokenLK, locale } from "./sessionSelectors"
import baseURL from "../baseURL"
import enterpriseApiUrl from "../enterpriseUrl";

const setAuthToken = (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`
}

const setAxiosLang = (lang) => {
  axios.defaults.headers.common["Accept-Language"] = lang
}

const clearAuthToken = () => {
  axios.defaults.headers.common.Authorization = null
}

const getDataUA = (token, dispatch) => {
  axios.defaults.baseURL = "https://cpp.infoxvod.com.ua/test_api"
  setAxiosLang("uk")
  dispatch(loginRequestData())
  setAuthToken(token)
  axios
    .get("/get-data.php")
    .then((response) => {
      try {
        dispatch(loginSuccessDataUA(response.data))
      } catch (error) {
        dispatch(loginErrorData(error))
      }
    })
    .catch((error) => {
      clearAuthToken()
      dispatch(loginErrorData(error))
    })
}

const getDataRU = (token, dispatch) => {
  axios.defaults.baseURL = "https://cpp.infoxvod.com.ua/test_api"
  setAxiosLang("ru")
  dispatch(loginRequestData())
  setAuthToken(token)
  axios
    .get("/get-data.php")
    .then((response) => {
      try {
        dispatch(loginSuccessDataRU(response.data))
      } catch (error) {
        dispatch(loginErrorData(error))
      }
    })
    .catch((error) => {
      clearAuthToken()
      dispatch(loginErrorData(error))
    })
}

export const login = (credentials) => (dispatch, getState) => {
  dispatch(loginRequestEnterprise())

  const lang = locale(getState())

  setAxiosLang(lang)

  axios.defaults.baseURL = `${baseURL}cpp`

  axios({
    method: "post",
    data: {
      login: credentials.mail,
      pwd: credentials.password,
    },
  })
    .then((response) => {
      try {
        if (response.data.url) window.open(response.data.url, "_blank")
      } catch (error) {
        if (error.response) {
          dispatch(loginErrorEnterprise(error.response))
        } else {
          dispatch(loginErrorEnterprise(error))
        }
      }
    })
    .catch((error) => {
      clearAuthToken()
      if (error.response) {
        dispatch(loginErrorEnterprise(error.response))
      } else {
        dispatch(loginErrorEnterprise(error))
      }
    })
}

export const loginEnterpise = (credentials) => (dispatch, getState) => {
  dispatch(loginRequestEnterprise())

  const lang = locale(getState())

  setAxiosLang(lang)

  axios.defaults.baseURL = `${enterpriseApiUrl}site/login-js/`

  axios({
    method: "post",
    data: {
      login: credentials.mail,
      password: credentials.password,
    },
  })
    .then((response) => {
      try {
        if (response.data.url) window.open(response.data.url, "_blank")
      } catch (error) {
        if (error.response) {
          dispatch(loginErrorEnterprise(error.response))
        } else {
          dispatch(loginErrorEnterprise(error))
        }
      }
    })
    .catch((error) => {
      clearAuthToken()
      if (error.response) {
        dispatch(loginErrorEnterprise(error.response))
      } else {
        dispatch(loginErrorEnterprise(error))
      }
    })
}

export const logOutOperation = () => (dispatch) => {
  clearAuthToken()
  dispatch(logOut())
}

export const refreshUserLK = () => (dispatch, getState) => {
  const token = getTokenLK(getState())
  if (!token) return
  const decoded = jwt(token).iat * 1000
  if (decoded < Date.now()) {
    logOutOperation()
    return
  }
  dispatch(refreshSuccess())
  const localeLang = locale(getState())
  if (localeLang === "uk") getDataUA(token, dispatch)
  else getDataRU(token, dispatch)
}
