import axios from 'axios';
import { mapRequest, mapSuccess, mapError } from './mapActions';
import baseURL from '../baseURL';

// eslint-disable-next-line import/prefer-default-export
export const mapOperation = () => dispatch => {
  axios.defaults.baseURL = `${baseURL}`;
  dispatch(mapRequest());

  axios
    .get('repairs')
    .then(response => {
      try {
        dispatch(mapSuccess(response.data));
      } catch (error) {
        dispatch(mapError(error));
      }
    })
    .catch(error => {
      console.log(error);
      dispatch(mapError(error));
    });
};
