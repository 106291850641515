import React, { Fragment } from 'react';
import StartPage from './pages/startPage/LangStartPage';
import ScrollUp from './components/scrollUpButton/scrollUpButton';

const App = () => (
  <Fragment>
    <StartPage />
    <ScrollUp />
  </Fragment>
);

export default App;
