import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Media from 'react-media';
import RegForm from './regForm/regForm';
import RegFormCorporation from './regForm/regFormCorporation';
import LoggedInfo from './loggedOffice/loggedOffice';
import * as sessionSelectors from '../../redux/session/sessionSelectors';
import * as sessionOperations from '../../redux/session/sessionOperations';
import * as sessionActions from '../../redux/session/sessionActions';
import style from './office.module.css';
import citizenryImg from '../../assets/img/icons/citizenry.svg';
import companyImg from '../../assets/img/icons/company.svg';

class Office extends Component {
  state = {
    value: 'citizenry',
  };

  componentDidMount() {
    if (this.props.locale === 'ru' && !this.props.userRU)
      this.props.refreshUserLK();
    if (this.props.locale === 'uk' && !this.props.userUA)
      this.props.refreshUserLK();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.locale !== this.props.currelocalentLocale &&
      this.props.locale === 'ru' &&
      !this.props.userRU
    ) {
      this.props.refreshUserLK();
    }
    if (
      prevProps.locale !== this.props.locale &&
      this.props.locale === 'uk' &&
      !this.props.userUA
    ) {
      this.props.refreshUserLK();
    }
  }

  handleOfficeCahnge = ({ target }) => {
    const { value } = target;
    this.setState({ value });
  };

  render() {
    const {
      autorization,
      authenticatedLK,
      onLogout,
      locale,
      userUA,
      userRU,
      loading,
      errorLK,
      setErrorNull,
      loginErrorLK,
      loginErrorEnterprise,
    } = this.props;
    const { value } = this.state;
    return (
      <div className={style.officeWrapper}>
        <Fragment>
          <h2 className={style.loggingHeader}>{autorization.title}</h2>
          <form className={style.radio}>
            <label
              className={
                value === 'citizenry'
                  ? style.radioLabelActive
                  : style.radioLabel
              }
              htmlFor="office1"
            >
              <span className={style.officeName}>
                <img
                  className={style.officeIcon}
                  src={citizenryImg}
                  alt="Office logo"
                />
                <Media query={{ minWidth: 1200 }}>
                  {matches => matches && autorization.citizenry.title}
                </Media>
              </span>
              <input
                name="office"
                value="citizenry"
                type="radio"
                id="office1"
                checked={value === 'citizenry'}
                onChange={this.handleOfficeCahnge}
                className={style.radioButton}
              />
            </label>
            <label
              className={
                value === 'company' ? style.radioLabelActive : style.radioLabel
              }
              htmlFor="office2"
            >
              <span className={style.officeName}>
                <img
                  className={style.officeIcon}
                  src={companyImg}
                  alt="Office logo"
                />
                <Media query={{ minWidth: 1200 }}>
                  {matches => matches && autorization.organization.title}
                </Media>
              </span>
              <input
                name="office"
                value="company"
                type="radio"
                id="office2"
                checked={value === 'company'}
                onChange={this.handleOfficeCahnge}
                className={style.radioButton}
              />
            </label>
          </form>
          {value === 'citizenry' && !authenticatedLK && (
            <RegForm
              setErrorNull={setErrorNull}
              textFields={autorization.citizenry}
              value={value}
              loginErrorLK={loginErrorLK}
            />
          )}
          {value === 'citizenry' && authenticatedLK && (
            <LoggedInfo
              textFields={autorization.logged}
              onLogout={onLogout}
              user={locale === 'ru' ? userRU : userUA}
              loading={loading}
              locale={locale}
              error={errorLK}
            />
          )}
          {value === 'company' && (
            <RegFormCorporation
              setErrorNull={setErrorNull}
              textFields={autorization.organization}
              value={value}
              loginErrorEnterprise={loginErrorEnterprise}
            />
          )}
        </Fragment>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  authenticatedLK: sessionSelectors.getIsAuthenticatedLK(state),
  userUA: sessionSelectors.getUserLKUA(state),
  userRU: sessionSelectors.getUserLKRU(state),
  loading: sessionSelectors.loading(state),
  errorLK: sessionSelectors.getErrorLK(state),
  locale: sessionSelectors.locale(state),
  loginErrorLK: sessionSelectors.getLoginErrorLK(state),
  loginErrorEnterprise: sessionSelectors.getLoginErrorEnterprise(state),
});

const mapDispatchToProps = {
  onLogout: sessionOperations.logOutOperation,
  refreshUserLK: sessionOperations.refreshUserLK,
  setErrorNull: sessionActions.setErrorNull,
};

export default connect(mapStateToProps, mapDispatchToProps)(Office);
