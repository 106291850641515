export const locale = state => state.session.locale;

export const getIsAuthenticatedLK = state => state.session.authenticatedLK;

export const loading = state => state.session.loading;

export const getTokenLK = state => state.session.tokenLK;

export const getUserLKUA = state => state.session.userLKUA;

export const getUserLKRU = state => state.session.userLKRU;

export const getErrorLK = state => state.session.error;

export const getLoginErrorLK = state => state.session.errorLogin;

export const getLoginErrorEnterprise = state =>
  state.session.errorLoginEnterprise;
