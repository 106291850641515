import { combineReducers } from 'redux';
import { ActionType } from './sessionActions';

const userLKUA = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.LOGIN_SUCCESS_DATA_UA:
      return payload;

    case ActionType.LOGOUT:
      return null;

    default:
      return state;
  }
};

const userLKRU = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.LOGIN_SUCCESS_DATA_RU:
      return payload;

    case ActionType.LOGOUT:
      return null;

    default:
      return state;
  }
};

const authenticatedLK = (state = false, { type }) => {
  switch (type) {
    case ActionType.LOGIN_SUCCESS:
    case ActionType.REFRESH_CURENT_SUCCESS:
      return true;

    case ActionType.LOGOUT:
      return false;

    default:
      return state;
  }
};

const tokenLK = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.LOGIN_SUCCESS:
      return payload.response.authorization;

    case ActionType.LOGOUT:
    case ActionType.LOGIN_ERROR:
      return null;

    default:
      return state;
  }
};

const error = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.LOGIN_ERROR_DATA:
      return payload.error;

    case ActionType.LOGIN_REQUEST_DATA:
      return null;

    default:
      return state;
  }
};

const errorLogin = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.LOGIN_ERROR:
      return payload;

    case ActionType.LOGIN_REQUEST:
    case ActionType.SET_ERROR_NULL:
      return null;

    default:
      return state;
  }
};

const errorLoginEnterprise = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.LOGIN_ERROR_ENTERPRISE:
      return payload;

    case ActionType.LOGIN_REQUEST_ENTERPRISE:
    case ActionType.SET_ERROR_NULL:
      return null;

    default:
      return state;
  }
};

const loading = (state = false, { type }) => {
  switch (type) {
    case ActionType.LOGIN_REQUEST_DATA:
      return true;

    case ActionType.LOGIN_ERROR_DATA:
    case ActionType.LOGIN_SUCCESS_DATA_RU:
    case ActionType.LOGIN_SUCCESS_DATA_UA:
      return false;

    default:
      return state;
  }
};

const locale = (state = 'uk', { type, payload }) => {
  switch (type) {
    case ActionType.SET_LOCALE:
      return payload;

    default:
      return state;
  }
};

export default combineReducers({
  userLKUA,
  userLKRU,
  tokenLK,
  error,
  errorLogin,
  errorLoginEnterprise,
  authenticatedLK,
  loading,
  locale,
});
