import axios from 'axios';
import {
  newsCategory2Request,
  newsCategory2SuccessUK,
  newsCategory2SuccessRU,
  newsCategory2ErrorUK,
  newsCategory2ErrorRU,
  incrmentNewsCategory2PageNumberUK,
  incrmentNewsCategory2PageNumberRU,
} from './newsCategory2Actions';
import { locale } from '../../session/sessionSelectors';
import baseURL from '../../baseURL';

import {
  newsCategory2PageNumberUK,
  newsCategory2PageNumberRU,
} from './newsCategory2Selectors';

const setAxiosLang = lang => {
  axios.defaults.headers.common['Accept-Language'] = lang;
};
// eslint-disable-next-line import/prefer-default-export
export const newsCategory2Operation = () => (dispatch, getState) => {
  axios.defaults.baseURL = `${baseURL}`;
  dispatch(newsCategory2Request());

  const page =
    locale(getState()) === 'uk'
      ? newsCategory2PageNumberUK(getState())
      : newsCategory2PageNumberRU(getState());

  setAxiosLang(locale(getState()));

  axios
    .get(`news?categoriesId=2&limit=12&page=${page}`)
    .then(response => {
      if (locale(getState()) === 'uk')
        try {
          if (response.data.remonti.length > 0 && response.data.remonti[0].id) {
            dispatch(incrmentNewsCategory2PageNumberUK());
            dispatch(newsCategory2SuccessUK(response.data.remonti));
          } else {
            dispatch(incrmentNewsCategory2PageNumberUK());
            dispatch(newsCategory2SuccessUK(null));
          }
        } catch (error) {
          dispatch(newsCategory2ErrorUK(error));
        }
      else if (locale(getState()) === 'ru') {
        try {
          if (response.data.remonti.length > 0 && response.data.remonti[0].id) {
            dispatch(incrmentNewsCategory2PageNumberRU());
            dispatch(newsCategory2SuccessRU(response.data.remonti));
          } else {
            dispatch(incrmentNewsCategory2PageNumberRU());
            dispatch(newsCategory2SuccessRU(null));
          }
        } catch (error) {
          dispatch(newsCategory2ErrorRU(error));
        }
      }
    })
    .catch(error => {
      console.log(error);
      if (locale(getState()) === 'uk') dispatch(newsCategory2ErrorUK(error));
      else {
        dispatch(newsCategory2ErrorRU(error));
      }
    });
};
