export const ActionType = {
  NEWS_CATEGORY_5_REQUEST: 'NEWS_CATEGORY_5_REQUEST',
  NEWS_CATEGORY_5_SUCCESS_UK: 'NEWS_CATEGORY_5_SUCCESS_UK',
  NEWS_CATEGORY_5_SUCCESS_RU: 'NEWS_CATEGORY_5_SUCCESS_RU',
  NEWS_CATEGORY_5_ERROR_UK: 'NEWS_CATEGORY_5_ERROR_UK',
  NEWS_CATEGORY_5_ERROR_RU: 'NEWS_CATEGORY_5_ERROR_RU',
  NEWS_CATEGORY_5_PAGE_NUMBER_UK: 'NEWS_CATEGORY_5_NUMBER_UK',
  NEWS_CATEGORY_5_PAGE_NUMBER_RU: 'NEWS_CATEGORY_5_NUMBER_RU',
};

export const newsCategory5Request = () => ({
  type: ActionType.NEWS_CATEGORY_5_REQUEST,
});

export const newsCategory5SuccessUK = response => ({
  type: ActionType.NEWS_CATEGORY_5_SUCCESS_UK,
  payload: response,
});

export const newsCategory5SuccessRU = response => ({
  type: ActionType.NEWS_CATEGORY_5_SUCCESS_RU,
  payload: response,
});

export const newsCategory5ErrorUK = error => ({
  type: ActionType.NEWS_CATEGORY_5_ERROR_UK,
  payload: error,
});

export const newsCategory5ErrorRU = error => ({
  type: ActionType.NEWS_CATEGORY_5_ERROR_RU,
  payload: error,
});

export const incrmentnewsCategory5PageNumberUK = () => ({
  type: ActionType.NEWS_CATEGORY_5_PAGE_NUMBER_UK,
});

export const incrmentnewsCategory5PageNumberRU = () => ({
  type: ActionType.NEWS_CATEGORY_5_PAGE_NUMBER_RU,
});
