import React from 'react'
import { Form } from 'react-bootstrap'

export const DistrictsSelect = ({ changeDistrictHandler, districts }) => {
  function renderList() {
    alert('t')
  }
  return (
    <Form.Control
      as='select'
      name='district'
      id='district'
      onChange={changeDistrictHandler}
    >
      {districts.map((district) => (
        <option value={district.id} key={district.id}>
          {district.name}
        </option>
      ))}
    </Form.Control>
  )
}
