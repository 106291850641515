import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import style from './sideNav.module.css';
import * as sessionSelectors from '../../../redux/session/sessionSelectors';
import * as newsNavSelectors from '../../../redux/news/newsNav/newsNavSelectors';

class SideNav extends Component {
  state = {};

  render() {
    const { locale, newsNavUK, newsNavRU, routes } = this.props;
    const { news } = routes;
    const sideNav = locale === 'uk' ? newsNavUK : newsNavRU;
    return (
      <ul className={style.sideList}>
        <li className={style.listItem}>
          <Link className={style.link} to={`/${locale}/${news}/`}>
            {locale === 'uk' ? 'Всі новини' : 'все новости'}
          </Link>
        </li>
        {sideNav &&
          Array.isArray(sideNav) &&
          sideNav.map(el => (
            <li className={style.listItem} key={el.id}>
              <Link className={style.link} to={`/${locale}/${news}/${el.url}`}>
                {el.name}
              </Link>
            </li>
          ))}
      </ul>
    );
  }
}

SideNav.defaultProps = {
  newsNavUK: [
    {
      id: '1',
      name: 'menu name',
      url: '',
    },
  ],
  newsNavRU: [
    {
      id: '1',
      name: 'menu name',
      url: '',
    },
  ],
};

SideNav.propTypes = {
  newsNavUK: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  newsNavRU: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
};

const mapStateToProps = state => ({
  locale: sessionSelectors.locale(state),
  newsNavUK: newsNavSelectors.newsNavUK(state),
  newsNavRU: newsNavSelectors.newsNavRU(state),
});

export default connect(mapStateToProps)(SideNav);
