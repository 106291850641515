import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import style from './menuChildren.module.css';
import fadeTransition from '../../../../../transitions/fade.module.css';
import * as sessionSelectors from '../../../../../redux/session/sessionSelectors';

class MenuItems extends Component {
  state = {
    open: false,
  };

  handleHover = () => {
    this.setState({ open: true });
  };

  handleLeave = () => {
    this.setState({ open: false });
  };

  onHandleClick = () => {
    this.setState({ open: false });
  };

  render() {
    const { links = [], title, locale } = this.props;
    const { open } = this.state;
    return (
      <div
        className={style.wrapper}
        onMouseLeave={this.handleLeave}
        onBlur={this.handleLeave}
      >
        <span
          className={!open ? style.textTitle : style.textTitleActive}
          onMouseOver={this.handleHover}
          onFocus={this.handleHover}
        >
          {title}
        </span>
        <CSSTransition
          in={open}
          timeout={0}
          classNames={fadeTransition}
          unmountOnExit
        >
          <Fragment>
            <ul className={style.list}>
              {links.map(singleLink => (
                <li className={style.listItem} key={singleLink.id}>
                  {!singleLink.external ? (
                    <Link
                      onClick={this.onHandleClick}
                      className={style.link}
                      to={singleLink.url ? `/${locale}${singleLink.url}` : '/'}
                    >
                      {singleLink.name}
                    </Link>
                  ) : (
                    <a
                      onClick={this.onHandleClick}
                      className={style.link}
                      href={singleLink.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {singleLink.name}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </Fragment>
        </CSSTransition>
      </div>
    );
  }
}

MenuItems.defaultProps = {
  links: [
    {
      id: '2',
      name: 'menuItem',
      url: '/',
      external: null,
    },
  ],
};

MenuItems.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
      external: PropTypes.number,
    }),
  ),
};

const mapStateToProps = state => ({
  locale: sessionSelectors.locale(state),
});

export default connect(mapStateToProps)(MenuItems);
