import { combineReducers } from 'redux';
import { ActionType } from './newsNavActions';

const loading = (state = false, { type }) => {
  switch (type) {
    case ActionType.NEWS_NAV_REQUEST:
      return true;

    case ActionType.NEWS_NAV_SUCCESS_UK:
    case ActionType.NEWS_NAV_SUCCESS_RU:
    case ActionType.NEWS_NAV_ERROR_UK:
    case ActionType.NEWS_NAV_ERROR_RU:
      return false;

    default:
      return state;
  }
};

const newsNavUK = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.NEWS_NAV_SUCCESS_UK:
      return payload;

    default:
      return state;
  }
};

const newsNavRU = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.NEWS_NAV_SUCCESS_RU:
      return payload;

    default:
      return state;
  }
};

const newsNavErrorUK = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.NEWS_NAV_ERROR_UK:
      return payload;

    case ActionType.NEWS_NAV_REQUEST:
    case ActionType.NEWS_NAV_SUCCESS_UK:
      return null;

    default:
      return state;
  }
};

const newsNavErrorRU = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.NEWS_NAV_ERROR_RU:
      return payload;

    case ActionType.NEWS_NAV_REQUEST:
    case ActionType.NEWS_NAV_SUCCESS_RU:
      return null;

    default:
      return state;
  }
};

export default combineReducers({
  loading,
  newsNavUK,
  newsNavRU,
  newsNavErrorUK,
  newsNavErrorRU,
});
