import React from 'react'
import { Table } from 'react-bootstrap'
import { connect } from 'react-redux'
import * as debtorsSelectors from '../../../redux/debtors/debtorsSelectors'
import * as debtorsActions from '../../../redux/debtors/debtorsActions'
import style from '../debtorsPage.module.css'

const DebtorsTable = ({ props }) => {
  function replacer(str) {
    let dateWithoutTime = str.split(' ', 1)

    return dateWithoutTime[0].replace(/-/gi, '.')
  }
  function renderItems(data) {
    return data.map((item) => (
      <tr key={item.shifr} className={style.mobileTableFont}>
        {props.serialNumberData.Data.length > 0 ?? <td>{item.shifr}</td>}
        <td>{item.street}</td>
        <td>{item.dom}</td>
        <td>{item.kvartira}</td>
        <td>{item.curr_dolg}</td>
        <td>{replacer(item.date_pay)}</td>
      </tr>
    ))
  }

  if (props.displayData.ready) {
    return (
      <div>
        <Table striped bordered hover responsive>
          <thead className={style.mobileTableFont}>
            <tr>
              {props.serialNumberData.Data.length > 0 ?? <th>Рах. №</th>}
              <th>Вул.</th>
              <th>Буд.</th>
              <th>Кв.</th>
              <th>Сума боргу</th>
              <th>Дата останньої оплати</th>
            </tr>
          </thead>
          <tbody>{renderItems(props.displayData.data)}</tbody>
        </Table>
      </div>
    )
  } else {
    return (
      <center>
        <p>Для відображення інформації потрібно заповнити форму.</p>
      </center>
    )
  }
}

const mapDispatchToProps = {
  showAlert: debtorsActions.showAlert,
}
const mapStateToProps = (state) => {
  return {
    props: {
      loading: debtorsSelectors.loading(state),
      alert: debtorsSelectors.alert(state),
      search: debtorsSelectors.search(state),
      districtsData: debtorsSelectors.districtsData(state),
      streetData: debtorsSelectors.streetData(state),
      serialNumberData: debtorsSelectors.serialNumberData(state),
      displayData: debtorsSelectors.displayData(state),
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DebtorsTable)
