import { combineReducers } from 'redux';
import { ActionType } from './newsCategory2Actions';

const loading = (state = false, { type }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_2_REQUEST:
      return true;

    case ActionType.NEWS_CATEGORY_2_SUCCESS_UK:
    case ActionType.NEWS_CATEGORY_2_SUCCESS_RU:
    case ActionType.NEWS_CATEGORY_2_ERROR_UK:
    case ActionType.NEWS_CATEGORY_2_ERROR_RU:
      return false;

    default:
      return state;
  }
};

const newsCategory2UK = (state = [], { type, payload }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_2_SUCCESS_UK:
      return state.concat(payload);

    default:
      return state;
  }
};

const newsCategory2RU = (state = [], { type, payload }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_2_SUCCESS_RU:
      return state.concat(payload);

    default:
      return state;
  }
};

const newsCategory2ErrorUK = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_2_ERROR_UK:
      return payload;

    case ActionType.NEWS_CATEGORY_2_REQUEST:
    case ActionType.NEWS_CATEGORY_2_SUCCESS_UK:
      return null;

    default:
      return state;
  }
};

const newsCategory2ErrorRU = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_2_ERROR_RU:
      return payload;

    case ActionType.NEWS_CATEGORY_2_REQUEST:
    case ActionType.NEWS_CATEGORY_2_SUCCESS_RU:
      return null;

    default:
      return state;
  }
};

const newsCategory2PageNumberUK = (state = 1, { type }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_2_PAGE_NUMBER_UK:
      return state + 1;

    default:
      return state;
  }
};

const newsCategory2PageNumberRU = (state = 1, { type }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_2_PAGE_NUMBER_RU:
      return state + 1;

    default:
      return state;
  }
};

export default combineReducers({
  loading,
  newsCategory2UK,
  newsCategory2RU,
  newsCategory2ErrorUK,
  newsCategory2ErrorRU,
  newsCategory2PageNumberUK,
  newsCategory2PageNumberRU,
});
