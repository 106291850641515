import React, { Component } from 'react';
import { connect } from 'react-redux';
import GoogleMap from '../googleMap/googleMap';
import MapInfo from './mapInfo/mapInfo';
import style from './mapControl.module.css';
import * as sessionSelectors from '../../redux/session/sessionSelectors';
import * as mapSelectors from '../../redux/map/mapSelectors';
import * as mapOperation from '../../redux/map/mapOperations';

class MapControl extends Component {
  state = {
    currentId: null,
    currentLat: '46.964691',
    currentLng: '31.983631',
  };

  componentDidMount = () => {
    const { onInitData } = this.props;
    onInitData();
  };

  setActiveId = currentId => {
    const { dataLang } = this.props;
    const crushArray = Object.values(dataLang);
    const currentCoordNew = crushArray.find(el => el.id === currentId);
    this.setState({
      currentId,
      currentLat: currentCoordNew.location.lat,
      currentLng: currentCoordNew.location.lng,
    });
  };

  handleClick = currentId => {
    if (currentId === this.state.currentId) {
      this.setState({ currentId: null });
    } else {
      this.setActiveId(currentId);
    }
  };

  handleClose = () => this.setState({ currentId: null });

  render() {
    const { currentId, currentLat, currentLng } = this.state;
    const { mapHeader, search, dataLang, mapLoading } = this.props;

    const crushArray = Object.values(dataLang);

    return (
      <div className="wrapper">

      </div>
    );
  }
}

const mapStateToProps = state => ({
  locale: sessionSelectors.locale(state),
  dataLang: mapSelectors.map(state),
  mapError: mapSelectors.mapError(state),
  mapLoading: mapSelectors.mapLoading(state),
});

const mapDispatchToProps = {
  onInitData: mapOperation.mapOperation,
};

export default connect(mapStateToProps, mapDispatchToProps)(MapControl);
