import React, { Fragment } from 'react';
import style from './header.module.css';
import TopHeader from './topHeader/topHeader';
import BottomHeader from './bottomHeader/bottomHeader';

const Header = ({ header }) => (
  <header>
    <TopHeader {...header} />
    <BottomHeader header={header} />
  </header>
);

export default Header;
