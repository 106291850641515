import React, { Component } from 'react';
import { LocalizationContext } from '../../context/localization';
import style from './localSelector.module.css';

const getBtnClass = (btnLocale, currentLocale) =>
  btnLocale === currentLocale ? style.active : style.button;
const activity = (btnLocale, currentLocale) => {
  return btnLocale === currentLocale;
};

export default class LocaleSelector extends Component {
  static contextType = LocalizationContext;

  render() {
    const { currentLocale, locales, changeLocale } = this.context;
    return (
      <section className={style.selector}>
        {locales.map(locale => (
          <button
            key={locale}
            onClick={() => changeLocale(locale)}
            className={getBtnClass(locale, currentLocale)}
            disabled={activity(locale, currentLocale)}
          >
            {locale === 'uk' ? 'UA' : 'RU'}
          </button>
        ))}
      </section>
    );
  }
}
