export const ActionType = {
  START_PAGE_NEWS_REQUEST: 'START_PAGE_NEWS_REQUEST',
  START_PAGE_NEWS_SUCCESS_UK: 'START_PAGE_NEWS_SUCCESS_UK',
  START_PAGE_NEWS_SUCCESS_RU: 'START_PAGE_NEWS_SUCCESS_RU',
  START_PAGE_NEWS_ERROR_UK: 'START_PAGE_NEWS_ERROR_UK',
  START_PAGE_NEWS_ERROR_RU: 'START_PAGE_NEWS_ERROR_RU',
};

export const startPageNewsRequest = () => ({
  type: ActionType.START_PAGE_NEWS_REQUEST,
});

export const startPageNewsSuccessUK = response => ({
  type: ActionType.START_PAGE_NEWS_SUCCESS_UK,
  payload: response,
});

export const startPageNewsSuccessRU = response => ({
  type: ActionType.START_PAGE_NEWS_SUCCESS_RU,
  payload: response,
});

export const startPageNewsErrorUK = error => ({
  type: ActionType.START_PAGE_NEWS_ERROR_UK,
  payload: error,
});

export const startPageNewsErrorRU = error => ({
  type: ActionType.START_PAGE_NEWS_ERROR_RU,
  payload: error,
});
