import React from 'react';
import { DialogContent } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { ReactSvgInjector, Mutate } from 'react-svg-injector';
import arrow from '../../../../../assets/img/icons/arrow-right-copy.svg';
import style from './menuMob.module.css';
import * as sessionSelectors from '../../../../../redux/session/sessionSelectors';
import * as initData from '../../../../../redux/initData/initDataSelectors';

const Arrow = () => (
  <ReactSvgInjector src={arrow} className={style.arrow}>
    <Mutate selector="g" />
  </ReactSvgInjector>
);

const ExpansionPanel = withStyles({
  root: {
    fontFamily: 'Open Sans',
    color: '#FBFBFB',
    transition: 'color .3s ease',
    fontSize: 20,
    backgroundXolor: 'none !important',
    boxShadow: 'none !important',
    marginBottom: 'none !important',
    textTransform: 'uppercase',
    textAlign: 'center',
    '&$expanded': {
      margin: '0',
      color: '#00A3DE;',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 0,
    '&$expanded': {
      minHeight: 0,
    },
  },
  content: {
    justifyContent: 'center',
    '&$expanded': {
      margin: '12px 0',
      backgroundColor: 'transparent',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: '0 24px 0 24px',
    backgroundColor: 'transparent',
  },
}))(MuiExpansionPanelDetails);

function CustomizedExpansionPanels({
  handleClose,
  modalUK = [],
  modalRU = [],
  locale,
}) {
  const modal = locale === 'uk' ? modalUK : modalRU;
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <DialogContent>
        {modal &&
          modal.map((item, i) => (
            <ExpansionPanel
              key={item.id}
              square
              expanded={expanded === `panel${i + 1}`}
              onChange={handleChange(`panel${i + 1}`)}
              className={style.panel}
            >
              <ExpansionPanelSummary
                aria-controls={`panel${i + 1}d-content`}
                id={`panel${i + 1}d-header`}
                className={style.panelSummary}
              >
                {item.children && item.children.length > 0 ? (
                  <p className={style.textWrapper}>
                    {item.name}
                    <Arrow />
                  </p>
                ) : !item.external ? (
                  <Link
                    className={style.link}
                    onClick={handleClose}
                    to={item.url ? `/${locale}${item.url}` : '/'}
                  >
                    {item.name}
                  </Link>
                ) : (
                  <a
                    className={style.link}
                    onClick={handleClose}
                    href={item.url}
                  >
                    {' '}
                    {item.name}
                  </a>
                )}
              </ExpansionPanelSummary>
              {item.children && item.children.length > 0 && (
                <ExpansionPanelDetails>
                  <ul className={style.innerList}>
                    {item.children.map(innerEl => (
                      <li key={innerEl.id} className={style.innerListItem}>
                        {!innerEl.external ? (
                          <Link
                            className={style.link}
                            onClick={handleClose}
                            to={innerEl.url ? `/${locale}${innerEl.url}` : '/'}
                          >
                            {innerEl.name}
                          </Link>
                        ) : (
                          <a
                            className={style.link}
                            onClick={handleClose}
                            href={innerEl.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {innerEl.name}
                          </a>
                        )}
                      </li>
                    ))}
                  </ul>
                </ExpansionPanelDetails>
              )}
            </ExpansionPanel>
          ))}
      </DialogContent>
    </div>
  );
}

CustomizedExpansionPanels.defaultProps = {
  modalUK: [
    {
      id: '1',
      name: 'menu',
      external: null,
      url: '',
      children: [
        {
          id: '2',
          name: 'menuItem',
          url: '/',
          external: null,
        },
      ],
    },
  ],
  modalRU: [
    {
      id: '1',
      name: 'menu',
      external: null,
      url: '',
      children: [
        {
          id: '2',
          name: 'menuItem',
          url: '/',
          external: null,
        },
      ],
    },
  ],
};

CustomizedExpansionPanels.propTypes = {
  modalUK: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      external: PropTypes.bool,
      url: PropTypes.string,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          url: PropTypes.string,
          external: PropTypes.number,
        }),
      ),
    }),
  ),
  modalRU: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      external: PropTypes.number,
      url: PropTypes.string,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          url: PropTypes.string,
          external: PropTypes.number,
        }),
      ),
    }),
  ),
};

const mapStateToProps = state => ({
  locale: sessionSelectors.locale(state),
  modalUK: initData.initDataModalUK(state),
  modalRU: initData.initDataModalRU(state),
});

export default connect(mapStateToProps)(CustomizedExpansionPanels);
