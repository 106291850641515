import { combineReducers } from 'redux';
import { ActionType } from './publicationActions';

const loading = (state = false, { type }) => {
  switch (type) {
    case ActionType.PUBLICATION_REQUEST:
      return true;

    case ActionType.PUBLICATION_SUCCESS_UK:
    case ActionType.PUBLICATION_SUCCESS_RU:
    case ActionType.PUBLICATION_ERROR_UK:
    case ActionType.PUBLICATION_ERROR_RU:
      return false;

    default:
      return state;
  }
};

const publicationUK = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.PUBLICATION_SUCCESS_UK:
      return payload;

    case ActionType.RESET_PUBLICATION:
      return null;

    default:
      return state;
  }
};

const publicationRU = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.PUBLICATION_SUCCESS_RU:
      return payload;

    case ActionType.RESET_PUBLICATION:
      return null;

    default:
      return state;
  }
};

const publicationErrorUK = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.PUBLICATION_ERROR_UK:
      return payload;

    case ActionType.PUBLICATION_REQUEST:
    case ActionType.PUBLICATION_SUCCESS_UK:
    case ActionType.RESET_PUBLICATION:
      return null;

    default:
      return state;
  }
};

const publicationErrorRU = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.PUBLICATION_ERROR_RU:
      return payload;

    case ActionType.PUBLICATION_REQUEST:
    case ActionType.PUBLICATION_SUCCESS_RU:
    case ActionType.RESET_PUBLICATION:
      return null;

    default:
      return state;
  }
};

export default combineReducers({
  loading,
  publicationUK,
  publicationRU,
  publicationErrorUK,
  publicationErrorRU,
});
