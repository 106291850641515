import { combineReducers } from 'redux';
import { ActionType } from './startPageIndexActions';

const loading = (state = false, { type }) => {
  switch (type) {
    case ActionType.START_PAGE_NEWS_REQUEST:
      return true;

    case ActionType.START_PAGE_NEWS_SUCCESS_UK:
    case ActionType.START_PAGE_NEWS_SUCCESS_RU:
    case ActionType.START_PAGE_NEWS_ERROR_UK:
    case ActionType.START_PAGE_NEWS_ERROR_RU:
      return false;

    default:
      return state;
  }
};

const startPageNewsUK = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.START_PAGE_NEWS_SUCCESS_UK:
      return payload;

    default:
      return state;
  }
};

const startPageNewsRU = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.START_PAGE_NEWS_SUCCESS_RU:
      return payload;

    default:
      return state;
  }
};

const startPageNewsErrorUK = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.START_PAGE_NEWS_ERROR_UK:
      return payload;

    default:
      return state;
  }
};

const startPageNewsErrorRU = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.START_PAGE_NEWS_ERROR_RU:
      return payload;

    default:
      return state;
  }
};

export default combineReducers({
  loading,
  startPageNewsUK,
  startPageNewsRU,
  startPageNewsErrorUK,
  startPageNewsErrorRU,
});
