import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import style from './indexArticlesDesk.module.css';

const length = someString => {
  try {
    if (someString.length <= 56) return someString;
    return `${someString.slice(0, 53)}...`;
  } catch (error) {}
};

const IndexArticlesDesk = ({ articles = [], locale }) => (
  <Fragment>
    {articles.map(article => (
      <li className={style.listItem} key={article.id}>
        <div className={style.imgWrapper}>
          <picture>
            <source srcSet={article.icon} type="image/webp" />
            <source srcSet={article.icon} type="image/jp2" />
            <source srcSet={article.icon} type="image/jpg" />
            <img className={style.img} src={article.icon} alt="news img" />
          </picture>
          <div className={style.darkBack} />
        </div>
        <div className={style.textArticle}>
          <h3 className={style.title}>{length(article.title)}</h3>
          <p className={style.text}>{article.brief}</p>
        </div>
        <Link className={style.linkArticle} to={`/${locale}${article.url}`} />
        <div className={style.date}>{article.published}</div>
        <div className={style.line} />
      </li>
    ))}
  </Fragment>
);

IndexArticlesDesk.defaultProps = {
  articles: [
    {
      icon: '',
      id: 1,
      brief: 'Article about',
      url: ' ',
      published: '01.01.2019',
    },
  ],
};

IndexArticlesDesk.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      id: PropTypes.number,
      brief: PropTypes.string,
      url: PropTypes.string,
      published: PropTypes.string,
    }),
  ),
};

export default IndexArticlesDesk;
