import { combineReducers } from 'redux';
import { ActionType } from './newsCategory6Actions';

const loading = (state = false, { type }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_6_REQUEST:
      return true;

    case ActionType.NEWS_CATEGORY_6_SUCCESS_UK:
    case ActionType.NEWS_CATEGORY_6_SUCCESS_RU:
    case ActionType.NEWS_CATEGORY_6_ERROR_UK:
    case ActionType.NEWS_CATEGORY_6_ERROR_RU:
      return false;

    default:
      return state;
  }
};

const newsCategory6UK = (state = [], { type, payload }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_6_SUCCESS_UK:
      return state.concat(payload);

    default:
      return state;
  }
};

const newsCategory6RU = (state = [], { type, payload }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_6_SUCCESS_RU:
      return state.concat(payload);

    default:
      return state;
  }
};

const newsCategory6ErrorUK = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_6_ERROR_UK:
      return payload;

    case ActionType.NEWS_CATEGORY_6_REQUEST:
    case ActionType.NEWS_CATEGORY_6_SUCCESS_UK:
      return null;

    default:
      return state;
  }
};

const newsCategory6ErrorRU = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_6_ERROR_RU:
      return payload;

    case ActionType.NEWS_CATEGORY_6_REQUEST:
    case ActionType.NEWS_CATEGORY_6_SUCCESS_RU:
      return null;

    default:
      return state;
  }
};

const newsCategory6PageNumberUK = (state = 1, { type }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_6_PAGE_NUMBER_UK:
      return state + 1;

    default:
      return state;
  }
};

const newsCategory6PageNumberRU = (state = 1, { type }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_6_PAGE_NUMBER_RU:
      return state + 1;

    default:
      return state;
  }
};

export default combineReducers({
  loading,
  newsCategory6UK,
  newsCategory6RU,
  newsCategory6ErrorUK,
  newsCategory6ErrorRU,
  newsCategory6PageNumberUK,
  newsCategory6PageNumberRU,
});
