import axios from 'axios';
import {
  newsCategory1Request,
  newsCategory1SuccessUK,
  newsCategory1SuccessRU,
  newsCategory1ErrorUK,
  newsCategory1ErrorRU,
  incrmentNewsCategory1PageNumberUK,
  incrmentNewsCategory1PageNumberRU,
} from './newsCategory1Actions';
import { locale } from '../../session/sessionSelectors';
import baseURL from '../../baseURL';

import {
  newsCategory1PageNumberUK,
  newsCategory1PageNumberRU,
} from './newsCategory1Selectors';

const setAxiosLang = lang => {
  axios.defaults.headers.common['Accept-Language'] = lang;
};
// eslint-disable-next-line import/prefer-default-export
export const newsCategory1Operation = () => (dispatch, getState) => {
  axios.defaults.baseURL = `${baseURL}`;
  dispatch(newsCategory1Request());

  const page =
    locale(getState()) === 'uk'
      ? newsCategory1PageNumberUK(getState())
      : newsCategory1PageNumberRU(getState());

  setAxiosLang(locale(getState()));

  axios
    .get(`news?categoriesId=1&limit=12&page=${page}`)
    .then(response => {
      if (locale(getState()) === 'uk')
        try {
          if (response.data.novini.length > 0 && response.data.novini[0].id) {
            dispatch(incrmentNewsCategory1PageNumberUK());
            dispatch(newsCategory1SuccessUK(response.data.novini));
          } else {
            dispatch(incrmentNewsCategory1PageNumberUK());
            dispatch(newsCategory1SuccessUK(null));
          }
        } catch (error) {
          dispatch(newsCategory1ErrorUK(error));
        }
      else if (locale(getState()) === 'ru') {
        try {
          if (response.data.novini.length > 0 && response.data.novini[0].id) {
            dispatch(incrmentNewsCategory1PageNumberRU());
            dispatch(newsCategory1SuccessRU(response.data.novini));
          } else {
            dispatch(incrmentNewsCategory1PageNumberRU());
            dispatch(newsCategory1SuccessRU(null));
          }
        } catch (error) {
          dispatch(newsCategory1ErrorRU(error));
        }
      }
    })
    .catch(error => {
      console.log(error);
      if (locale(getState()) === 'uk') dispatch(newsCategory1ErrorUK(error));
      else {
        dispatch(newsCategory1ErrorRU(error));
      }
    });
};
