export const ActionType = {
  NEWS_NAV_REQUEST: 'NEWS_NAV_REQUEST',
  NEWS_NAV_SUCCESS_UK: 'NEWS_NAV_SUCCESS_UK',
  NEWS_NAV_SUCCESS_RU: 'NEWS_NAV_SUCCESS_RU',
  NEWS_NAV_ERROR_UK: 'NEWS_NAV_ERROR_UK',
  NEWS_NAV_ERROR_RU: 'NEWS_NAV_ERROR_RU',
};

export const newsNavRequest = () => ({
  type: ActionType.NEWS_NAV_REQUEST,
});

export const newsNavSuccessUK = response => ({
  type: ActionType.NEWS_NAV_SUCCESS_UK,
  payload: response,
});

export const newsNavSuccessRU = response => ({
  type: ActionType.NEWS_NAV_SUCCESS_RU,
  payload: response,
});

export const newsNavErrorUK = error => ({
  type: ActionType.NEWS_NAV_ERROR_UK,
  payload: error,
});

export const newsNavErrorRU = error => ({
  type: ActionType.NEWS_NAV_ERROR_RU,
  payload: error,
});
