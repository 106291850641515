import React, { Component } from 'react'
import { withRouter } from 'react-router'
import DebtorsTable from './components/DebtorsTable'
import DebtorsForm from './components/DebtorsForm'
import {} from '../../redux/store'

/* Styles */
import style from './debtorsPage.module.css'
import Media from 'react-media'
import SideNavPublication from '../singlePublication/singlePublicationSideNav/singlePublicationSideNav'

class DebtorsPage extends Component {
  currentDate = new Date().toLocaleDateString('ru-RU')
  render() {
    return (
      <div className={`wrapper ${style.chatPageWrapper}`}>
        <div className={style.debtorsWrapper}>
          <h1 className={style.debtorsTitleH1}>
            БОРЖНИКИ МКП "МИКОЛАЇВВОДОКАНАЛ"
          </h1>

          <div className={style.grid}>
            <div className={(style.debtorsContent, style.borderTop)}>
              {/* <h4 className="debtorsTableMessageH4"> */}
              <p>
                Абоненти, які мають заборгованість більше 1000 грн. за послуги
                водопостачання та водовідведення на {this.currentDate}
              </p>
              {/* </h4> */}
              <DebtorsForm />
              <div>
                <DebtorsTable />
              </div>
              <p>
                <strong>УВАГА!</strong>
              </p>
              <p>
                Абоненти, які мають заборгованість можуть бути{' '}
                <strong>відключені</strong> від послуг водопостачання та
                водовідведення. МКП "Миколаївводоканал" може стягнути
                заборгованість через суд.
              </p>
              <p>Сплачуйте, будь ласка, за послуги своєчасно.</p>
              <p>З повагою МКП "Миколаївводоканал".</p>
            </div>
            <Media query={{ minWidth: 1200 }}>
              {
                <div className={style.sideNews}>
                  <SideNavPublication />
                </div>
              }
            </Media>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(DebtorsPage)
