import axios from 'axios';
import {
  allNewsRequest,
  allNewsSuccessUK,
  allNewsSuccessRU,
  allNewsErrorUK,
  allNewsErrorRU,
  incrmentAllNewsPageNumberUK,
  incrmentAllNewsPageNumberRU,
} from './allNewsActions';
import { locale } from '../../session/sessionSelectors';
import { allNewsPageNumberUK, allNewsPageNumberRU } from './allNewsSelectors';
import baseURL from '../../baseURL';

const setAxiosLang = lang => {
  axios.defaults.headers.common['Accept-Language'] = lang;
};
// eslint-disable-next-line import/prefer-default-export
export const allNewsOperation = () => (dispatch, getState) => {
  axios.defaults.baseURL = `${baseURL}`;
  dispatch(allNewsRequest());

  setAxiosLang(locale(getState()));

  const page =
    locale(getState()) === 'uk'
      ? allNewsPageNumberUK(getState())
      : allNewsPageNumberRU(getState());

  axios
    .get(`news?categoriesId=0&limit=12&page=${page}`)
    .then(response => {
      if (locale(getState()) === 'uk')
        try {
          if (response.data.all.length > 0 && response.data.all[0].id) {
            dispatch(incrmentAllNewsPageNumberUK());
            dispatch(allNewsSuccessUK(response.data.all));
          } else {
            dispatch(incrmentAllNewsPageNumberUK());
            dispatch(allNewsSuccessUK(null));
          }
        } catch (error) {
          dispatch(allNewsErrorUK(error));
        }
      else if (locale(getState()) === 'ru') {
        try {
          if (response.data.all.length > 0 && response.data.all[0].id) {
            dispatch(incrmentAllNewsPageNumberRU());
            dispatch(allNewsSuccessRU(response.data.all));
          } else {
            dispatch(incrmentAllNewsPageNumberRU());
            dispatch(allNewsSuccessRU(null));
          }
        } catch (error) {
          dispatch(allNewsErrorRU(error));
        }
      }
    })
    .catch(error => {
      console.log(error);
      if (locale(getState()) === 'uk') dispatch(allNewsErrorUK(error));
      else {
        dispatch(allNewsErrorRU(error));
      }
    });
};
