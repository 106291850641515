import React, { Component, Fragment } from 'react';
import Media from 'react-media';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DateFinder from './dateFinder/dateFinder';
import DateFinderMob from './dateFinderMob/dateFinderMob';
import NewsNavMob from './newsNavMob/newsNavMob';
import style from './newsNav.module.css';
import * as sessionSelectors from '../../redux/session/sessionSelectors';
import * as newsNavSelectors from '../../redux/news/newsNav/newsNavSelectors';
import { resetTranslitiration } from '../../redux/translitiration/translitirationActions';

const yearsArr = () => {
  const startYear = 2015;
  const dateNow = new Date();
  const nowYear = dateNow.getFullYear();
  const yearArrTemp = [];
  for (let i = 0; i <= nowYear - startYear; i += 1)
    yearArrTemp.push(startYear + i);
  return yearArrTemp;
};

class NewsNav extends Component {
  componentDidMount = () => {
    const { resetTranslitiration } = this.props;
    resetTranslitiration();
  };

  render() {
    const {
      newsNavUK = [],
      newsNavRU = [],
      calendar,
      locale,
      routes,
      header,
    } = this.props;
    const allName = locale === 'uk' ? 'всi' : 'все';
    const newsNav = locale === 'uk' ? newsNavUK : newsNavRU;
    const { news } = routes;
    return (
      <Fragment>
        <h1 className={style.head}>
          {header}{' '}
          <Media query={{ maxWidth: 1199 }}>
            {matches =>
              matches && (
                <DateFinderMob
                  news={news}
                  currentYearArr={yearsArr()}
                  {...calendar}
                />
              )
            }
          </Media>
        </h1>
        <div className={style.navWrapper}>
          <Media query={{ minWidth: 1200 }}>
            {matches =>
              matches ? (
                <ul className={style.linkList}>
                  <li className={style.linkItem}>
                    <NavLink
                      exact
                      activeClassName={style.selected}
                      className={style.link}
                      to={`/${locale}/${news}`}
                    >
                      {allName}
                    </NavLink>
                  </li>
                  {newsNav &&
                    Array.isArray(newsNav) &&
                    newsNav.map(el => (
                      <li className={style.linkItem} key={el.id}>
                        <NavLink
                          exact
                          activeClassName={style.selected}
                          className={style.link}
                          to={`/${locale}/${news}/${el.url}`}
                        >
                          {el.name}
                        </NavLink>
                      </li>
                    ))}
                </ul>
              ) : (
                <div className={style.order}>
                  <NewsNavMob
                    routes={routes}
                    allName={allName}
                    newsNav={newsNav}
                  />
                </div>
              )
            }
          </Media>
          <div className={style.calendar}>
            <Media query={{ minWidth: 1200 }}>
              {matches =>
                matches && (
                  <DateFinder
                    news={news}
                    currentYearArr={yearsArr()}
                    {...calendar}
                  />
                )
              }
            </Media>
          </div>
        </div>
      </Fragment>
    );
  }
}

NewsNav.defaultProps = {
  newsNavUK: [
    {
      id: '1',
      name: 'menu name',
      url: '',
    },
  ],
  newsNavRU: [
    {
      id: '1',
      name: 'menu name',
      url: '',
    },
  ],
};

NewsNav.propTypes = {
  newsNavUK: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  newsNavRU: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
};

const mapStateToProps = state => ({
  locale: sessionSelectors.locale(state),
  newsNavUK: newsNavSelectors.newsNavUK(state),
  newsNavRU: newsNavSelectors.newsNavRU(state),
  newsNavErrorUK: newsNavSelectors.newsNavErrorUK(state),
  newsNavErrorRU: newsNavSelectors.newsNavErrorRU(state),
});

const mapDispatchToProps = {
  resetTranslitiration,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsNav);
