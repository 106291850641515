import { Motion, spring } from 'react-motion';
import PropTypes from 'prop-types';
import SizeReporter from 'react-size-reporter';
import React, { Component, Fragment } from 'react';
import style from './motionComponent.module.css';

export default class MotionComponent extends Component {
  state = {
    height: 0,
  };

  handleSizeChange = ({ height }) => {
    this.setState({
      height,
    });
  };

  render() {
    const { currentId, crush } = this.props;
    const { height } = this.state;
    return (
      <Fragment>
        <Motion
          style={{
            height: spring(currentId && currentId === crush.id ? height : 0),
          }}
        >
          {interpolatedStyle => (
            <div
              className={style.listWrapper}
              style={{
                height: interpolatedStyle.height,
              }}
            >
              <SizeReporter onSizeChange={this.handleSizeChange}>
                <ul className={style.streetList}>
                  <li className={style.streetListItem}>{crush.brief}</li>
                  {/* {crush.places.map(el => (
                    <li className={style.streetListItem} key={el}>
                      {el}
                    </li>
                  ))} */}
                </ul>
              </SizeReporter>
            </div>
          )}
        </Motion>
      </Fragment>
    );
  }
}

MotionComponent.defaultProps = {
  crush: {
    brief: 'description',
  },
};

MotionComponent.propTypes = {
  crush: PropTypes.shape({
    brief: PropTypes.string,
  }),
};
