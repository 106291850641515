export const newsCategory6UK = state =>
  state.news.newsCategory6.newsCategory6UK;
export const newsCategory6RU = state =>
  state.news.newsCategory6.newsCategory6RU;
export const newsCategory6ErrorUK = state =>
  state.news.newsCategory6.newsCategory6ErrorRU;
export const newsCategory6ErrorRU = state =>
  state.news.newsCategory6.newsCategory6ErrorRU;
export const newsCategory6Loading = state => state.news.newsCategory6.loading;
export const newsCategory6PageNumberUK = state =>
  state.news.newsCategory6.newsCategory6PageNumberUK;
export const newsCategory6PageNumberRU = state =>
  state.news.newsCategory6.newsCategory6PageNumberRU;
