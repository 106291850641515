import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import style from './magicIndexDesk.module.css';
import IndexContentBlock from './indexContentBlock/indexContentBlock';
import * as sessionSelectors from '../../../redux/session/sessionSelectors';
import * as initDataSelectors from '../../../redux/initData/initDataSelectors';

const MagicIndexDesk = ({ locale, viber, office, vidzetyUK, vidzetyRU }) => {
  const vidzety = locale === 'uk' ? vidzetyUK : vidzetyRU;
  return (
    <div className={style.middleWrapper}>
      <div className={style.slideViberOffice}>
        <div className={style.slideViber}>
          <div className={style.slide} />
          <div className={style.viber} />
        </div>
        <div className={style.office} />
      </div>
      <div className={style.bottomThree}>
        {vidzety &&
          Array.isArray(vidzety) &&
          vidzety.map(el => (
            <div key={el.id} className={style.bottomThreeChild}>
              <div className={style.bottomThreeChildWrapper}>
                <img
                  src={el.icon}
                  alt="icon"
                  className={style.bottomThreeChildImg}
                />
                <p className={style.bottomThreeChildText}>{el.name}</p>
                <Link
                  to={`/${locale}${el.url}`}
                  className={style.bottomThreeChildLink}
                />
              </div>
            </div>
          ))}
      </div>
      <IndexContentBlock office={office} viber={viber} />
    </div>
  );
};

MagicIndexDesk.defaultProps = {
  vidzetyUK: [
    {
      id: '1',
      icon: '',
      name: 'serviceName',
      url: '/',
    },
  ],
  vidzetyRU: [
    {
      id: '1',
      icon: '',
      name: 'serviceName',
      url: '/',
    },
  ],
};

MagicIndexDesk.propTypes = {
  vidzetyUK: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      icon: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  vidzetyRU: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      icon: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
};

const mapStateToProps = state => ({
  locale: sessionSelectors.locale(state),
  vidzetyUK: initDataSelectors.initDataVidzhetyUK(state),
  vidzetyRU: initDataSelectors.initDataVidzhetyRU(state),
});

export default connect(mapStateToProps)(MagicIndexDesk);
