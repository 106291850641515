import axios from 'axios'
import {
  currentDataRequest,
  currentDataSuccess,
  currentDataError,
  streetDataRequest,
  streetDataSuccess,
  streetDataError,
  snDataRequest,
  snDataSuccess,
  snDataError,
  saveDistrictsData,
  showAlert,
  setDisplayData,
  collapseForm,
  setDataReadyToDisplay,
  resetDisplayData,
  resetSearchValues,
  snDataReset,
} from './debtorsActions'

import { store } from '../store'

const getDistrictData = () => (dispatch, getState) => {
  const districtId = store.getState().debtors.search.district.id
  dispatch(currentDataRequest())

  axios({
    method: 'GET',
    url: `debtors?district=${districtId}`,
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      try {
        dispatch(currentDataSuccess(response.data))
        dispatch(
          saveDistrictsData({
            id: districtId,
            streets: response.data,
          })
        )
      } catch (error) {
        dispatch(currentDataError(error))
      }
    })
    .catch((error) => {
      console.log(error)
      dispatch(currentDataError(error))
    })
}

const getStreetData = () => (dispatch, getState) => {
  const streetId = store.getState().debtors.search.street.id

  dispatch(streetDataRequest())

  axios({
    method: 'GET',
    url: `debtors?street=${streetId}`,
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      try {
        dispatch(streetDataSuccess(response.data))
      } catch (error) {
        dispatch(streetDataError(error))
      }
    })
    .catch((error) => {
      console.log(error)
      dispatch(streetDataError(error))
    })
}

const displayDataOperation = (searchType) => (dispatch, getState) => {
  dispatch(resetDisplayData())
  dispatch(snDataReset())
  switch (searchType) {
    case 1:
      const streetData = store.getState().debtors.streetData.Data

      const building = store.getState().debtors.search.building

      const output =
        building.length > 0
          ? streetData.filter((street) => street.dom === building)
          : streetData

      if (output.length > 0) {
        dispatch(setDisplayData(output))
        dispatch(setDataReadyToDisplay(true))
        dispatch(collapseForm())
        /* window.scrollTo(0, 0) */
      } else {
        dispatch(showAlert('За данною вулицею боржників не знайдено.', 10))
      }

      break

    case 2:
      let sn = store.getState().debtors.search.serialNumber

      if (sn.length === 8) {
        switch (sn[0]) {
          case '9':
            sn = '21' + sn
            break
          case '7':
            sn = '55' + sn
            break
          default:
            sn = '06' + sn
            break
        }
      }

      dispatch(snDataRequest())

      axios({
        method: 'GET',
        url: `debtors?sn=${sn}`,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          try {
            if (response.data.length > 0) {
              dispatch(snDataSuccess(response.data))
              dispatch(setDisplayData(response.data))
              dispatch(setDataReadyToDisplay(true))
              dispatch(collapseForm())
              /*  window.scrollTo(0, 0) */
            } else {
              const message =
                'Не знайдено такого розрахункового рахунку, або розрахунковий рахунок відсутній у списку боржників.'
              dispatch(showAlert(message, 10))
              dispatch(snDataError(message))
            }
          } catch (error) {
            dispatch(snDataError(error))
          }
        })
        .catch((error) => {
          console.log(error)
          dispatch(snDataError(error))
        })
      break

    default:
      break
  }
  dispatch(resetSearchValues())
}

export { getDistrictData, getStreetData, displayDataOperation }
