import { combineReducers } from 'redux';
import { ActionType } from './newsCategory3Actions';

const loading = (state = false, { type }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_3_REQUEST:
      return true;

    case ActionType.NEWS_CATEGORY_3_SUCCESS_UK:
    case ActionType.NEWS_CATEGORY_3_SUCCESS_RU:
    case ActionType.NEWS_CATEGORY_3_ERROR_UK:
    case ActionType.NEWS_CATEGORY_3_ERROR_RU:
      return false;

    default:
      return state;
  }
};

const newsCategory3UK = (state = [], { type, payload }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_3_SUCCESS_UK:
      return state.concat(payload);

    default:
      return state;
  }
};

const newsCategory3RU = (state = [], { type, payload }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_3_SUCCESS_RU:
      return state.concat(payload);

    default:
      return state;
  }
};

const newsCategory3ErrorUK = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_3_ERROR_UK:
      return payload;

    case ActionType.NEWS_CATEGORY_3_REQUEST:
    case ActionType.NEWS_CATEGORY_3_SUCCESS_UK:
      return null;

    default:
      return state;
  }
};

const newsCategory3ErrorRU = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_3_ERROR_RU:
      return payload;

    case ActionType.NEWS_CATEGORY_3_REQUEST:
    case ActionType.NEWS_CATEGORY_3_SUCCESS_RU:
      return null;

    default:
      return state;
  }
};

const newsCategory3PageNumberUK = (state = 1, { type }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_3_PAGE_NUMBER_UK:
      return state + 1;

    default:
      return state;
  }
};

const newsCategory3PageNumberRU = (state = 1, { type }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_3_PAGE_NUMBER_RU:
      return state + 1;

    default:
      return state;
  }
};

export default combineReducers({
  loading,
  newsCategory3UK,
  newsCategory3RU,
  newsCategory3ErrorUK,
  newsCategory3ErrorRU,
  newsCategory3PageNumberUK,
  newsCategory3PageNumberRU,
});
