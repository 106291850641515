import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import MagicIndex from '../../components/magicIndex/magicIndex';
import { resetTranslitiration } from '../../redux/translitiration/translitirationActions';
import IndexArticles from '../../components/indexArticles/indexArticles';
import Map from '../../components/mapControl/mapControl';

class IndexPage extends Component {
  componentDidMount = () => {
    const { resetTranslitiration } = this.props;
    resetTranslitiration();
  };

  render() {
    const { viber, office, viberMob, newsIndex, routes, map } = this.props;
    return (
      <Fragment>
        <MagicIndex viberMob={viberMob} viber={viber} office={office} />
        <IndexArticles newsIndex={newsIndex} routes={routes} />
        <Map {...map} />
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  resetTranslitiration,
};

export default connect(null, mapDispatchToProps)(IndexPage);
