import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import ReactGA from 'react-ga';
import './index.css';
import App from './App';
import { store, persistor } from './redux/store';
import Localization from './context/localization';

const trackingId = 'UA-34468912-1';
ReactGA.initialize(trackingId);

const Root = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Localization>
          <Route component={App} />
        </Localization>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

export default Root;
