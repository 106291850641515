import { combineReducers } from 'redux';
import { ActionType } from './sideNewsActions';

const loading = (state = false, { type }) => {
  switch (type) {
    case ActionType.SIDE_NEWS_REQUEST:
      return true;

    case ActionType.SIDE_NEWS_SUCCESS_UK:
    case ActionType.SIDE_NEWS_SUCCESS_RU:
    case ActionType.SIDE_NEWS_ERROR_UK:
    case ActionType.SIDE_NEWS_ERROR_RU:
      return false;

    default:
      return state;
  }
};

const sideNewsUK = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.SIDE_NEWS_SUCCESS_UK:
      return payload;

    default:
      return state;
  }
};

const sideNewsRU = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.SIDE_NEWS_SUCCESS_RU:
      return payload;

    default:
      return state;
  }
};

const sideNewsErrorUK = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.SIDE_NEWS_ERROR_RU:
      return payload;

    case ActionType.SIDE_NEWS_REQUEST:
    case ActionType.SIDE_NEWS_SUCCESS_UK:
      return null;

    default:
      return state;
  }
};

const sideNewsErrorRU = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.SIDE_NEWS_ERROR_RU:
      return payload;

    case ActionType.SIDE_NEWS_REQUEST:
    case ActionType.SIDE_NEWS_SUCCESS_RU:
      return null;

    default:
      return state;
  }
};

export default combineReducers({
  loading,
  sideNewsUK,
  sideNewsRU,
  sideNewsErrorUK,
  sideNewsErrorRU,
});
