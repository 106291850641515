export const ActionType = {
  PUBLICATION_REQUEST: 'PUBLICATION_REQUEST',
  PUBLICATION_SUCCESS_UK: 'PUBLICATION_SUCCESS_UK',
  PUBLICATION_SUCCESS_RU: 'PUBLICATION_SUCCESS_RU',
  PUBLICATION_ERROR_UK: 'PUBLICATION_ERROR_UK',
  PUBLICATION_ERROR_RU: 'PUBLICATION_ERROR_RU',
  RESET_PUBLICATION: 'RESET_PUBLICATION',
};

export const publicationRequest = () => ({
  type: ActionType.PUBLICATION_REQUEST,
});

export const publicationSuccessUK = response => ({
  type: ActionType.PUBLICATION_SUCCESS_UK,
  payload: response,
});

export const publicationSuccessRU = response => ({
  type: ActionType.PUBLICATION_SUCCESS_RU,
  payload: response,
});

export const publicationErrorUK = error => ({
  type: ActionType.PUBLICATION_ERROR_UK,
  payload: error,
});

export const publicationErrorRU = error => ({
  type: ActionType.PUBLICATION_ERROR_RU,
  payload: error,
});

export const resetPublication = () => ({
  type: ActionType.RESET_PUBLICATION,
});
