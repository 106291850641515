export const newsCategory4UK = state =>
  state.news.newsCategory4.newsCategory4UK;
export const newsCategory4RU = state =>
  state.news.newsCategory4.newsCategory4RU;
export const newsCategory4ErrorUK = state =>
  state.news.newsCategory4.newsCategory4ErrorRU;
export const newsCategory4ErrorRU = state =>
  state.news.newsCategory4.newsCategory4ErrorRU;
export const newsCategory4Loading = state => state.news.newsCategory4.loading;
export const newsCategory4PageNumberUK = state =>
  state.news.newsCategory4.newsCategory4PageNumberUK;
export const newsCategory4PageNumberRU = state =>
  state.news.newsCategory4.newsCategory4PageNumberRU;
