export const ActionType = {
  TRANSLITIRATION: 'TRANSLITIRATION',
  TRANSLITIRATION_ERROR: 'TRANSLITIRATION_ERROR',
  RESET_TRANSLITIRATION: 'RESET_TRANSLITIRATION',
};

export const translitiration = response => ({
  type: ActionType.TRANSLITIRATION,
  payload: response,
});

export const resetTranslitiration = () => ({
  type: ActionType.RESET_TRANSLITIRATION,
});

export const translitirationError = error => ({
  type: ActionType.TRANSLITIRATION_ERROR,
  payload: error,
});
