import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import style from './indexContentBlockMob.module.css';
import viberLogoMobImg from '../../../../assets/img/icons/viberLogoMob.svg';
import * as sessionSelectors from '../../../../redux/session/sessionSelectors';
import * as initDataSelectors from '../../../../redux/initData/initDataSelectors';

const IndexContentBlockMob = ({ viberMob, vidzetyUK, vidzetyRU, locale }) => {
  const vidzety = locale === 'uk' ? vidzetyUK : vidzetyRU;
  return (
    <div className={style.contentBlockWrapper}>
      <a
        className={style.viberLink}
        href="https://al-s.top/mkH2O"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className={style.viberImg}
          src={viberLogoMobImg}
          alt="viber logo"
        />
        <span className={style.viberText}>{viberMob}</span>
      </a>
      {vidzety &&
        Array.isArray(vidzety) &&
        vidzety.map(el => (
          <div key={el.id} className={style.blockWrapper}>
            <Link className={style.blockLink} to={`/${locale}${el.url}`}>
              <img className={style.blockIcon} src={el.icon} alt="icon" />
              {el.name}
            </Link>
            <div className={style.blockLine} />
          </div>
        ))}
    </div>
  );
};

IndexContentBlockMob.defaultProps = {
  vidzetyUK: [
    {
      id: '1',
      icon: '',
      name: 'serviceName',
      url: '/',
    },
  ],
  vidzetyRU: [
    {
      id: '1',
      icon: '',
      name: 'serviceName',
      url: '/',
    },
  ],
};

IndexContentBlockMob.propTypes = {
  vidzetyUK: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      icon: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  vidzetyRU: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      icon: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
};

const mapStateToProps = state => ({
  locale: sessionSelectors.locale(state),
  vidzetyUK: initDataSelectors.initDataVidzhetyUK(state),
  vidzetyRU: initDataSelectors.initDataVidzhetyRU(state),
});

export default connect(mapStateToProps)(IndexContentBlockMob);
