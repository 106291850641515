export const ActionType = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_REQUEST_DATA: 'LOGIN_REQUEST_DATA',
  LOGIN_SUCCESS_DATA_UA: 'LOGIN_SUCCESS_DATA_UA',
  LOGIN_SUCCESS_DATA_RU: 'LOGIN_SUCCESS_DATA_RU',
  LOGIN_ERROR_DATA: 'LOGIN_ERROR_DATA',
  LOGIN_ERROR: 'LOGIN_ERROR',
  SIGNUP_REQUEST: 'SIGNUP_REQUEST',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_ERROR: 'SIGNUP_ERROR',
  LOGOUT: 'LOGOUT',
  REFRESH_CURENT_SUCCESS: 'REFRESH_CURENT_SUCCESS',

  LOGIN_REQUEST_ENTERPRISE: 'LOGIN_REQUEST_ENTERPRISE',
  LOGIN_SUCCESS_ENTERPRISE: 'LOGIN_SUCCESS_ENTERPRISE',
  LOGIN_ERROR_ENTERPRISE: 'LOGIN_ERROR_ENTERPRISE',

  SET_LOCALE: 'SET_LOCALE',

  SET_ERROR_NULL: 'SET_ERROR_NULL',
};

export const setLocale = localeLang => ({
  type: ActionType.SET_LOCALE,
  payload: localeLang,
});

export const refreshSuccess = response => ({
  type: ActionType.REFRESH_CURENT_SUCCESS,
  payload: { response },
});

export const loginRequest = () => ({
  type: ActionType.LOGIN_REQUEST,
});

export const loginSuccess = response => ({
  type: ActionType.LOGIN_SUCCESS,
  payload: { response },
});

export const loginError = error => ({
  type: ActionType.LOGIN_ERROR,
  payload: error,
});

export const loginRequestData = () => ({
  type: ActionType.LOGIN_REQUEST_DATA,
});

export const loginSuccessDataUA = response => ({
  type: ActionType.LOGIN_SUCCESS_DATA_UA,
  payload: response,
});

export const loginSuccessDataRU = response => ({
  type: ActionType.LOGIN_SUCCESS_DATA_RU,
  payload: response,
});

export const loginErrorData = error => ({
  type: ActionType.LOGIN_ERROR_DATA,
  payload: { error },
});

export const logOut = () => ({
  type: ActionType.LOGOUT,
});

export const loginRequestEnterprise = () => ({
  type: ActionType.LOGIN_REQUEST_ENTERPRISE,
});

export const loginSuccessEnterprise = response => ({
  type: ActionType.LOGIN_SUCCESS_ENTERPRISE,
  payload: { response },
});

export const loginErrorEnterprise = error => ({
  type: ActionType.LOGIN_ERROR_ENTERPRISE,
  payload: error,
});

export const setErrorNull = () => ({
  type: ActionType.SET_ERROR_NULL,
});
