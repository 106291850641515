import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import NewsNav from '../../components/newsNav/newsNav';
import BuildingNewsList from './buildingNewsList/buildingNewsList';

class NewsCategoriesPage extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    const { newsContent, routes } = this.props;
    return (
      <div className="wrapper">
        <NewsNav
          routes={routes}
          header={newsContent.header}
          calendar={newsContent.calendar}
        />
        <BuildingNewsList routes={routes} button={newsContent.button} />
      </div>
    );
  }
}

export default withRouter(NewsCategoriesPage);
