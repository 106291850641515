import { combineReducers } from 'redux'
import { ActionType } from './debtorsActions'

const loading = (state = false, { type }) => {
  switch (type) {
    case ActionType.ENABLE_LOADER:
    case ActionType.CURRENT_DISTRICT_DATA_REQUEST:
    case ActionType.STREET_DATA_REQUEST:
    case ActionType.SN_DATA_REQUEST:
      return true
    case ActionType.DISABLE_LOADER:
    case ActionType.CURRENT_DISTRICT_DATA_SUCCESS:
    case ActionType.CURRENT_DISTRICT_DATA_ERROR:
    case ActionType.STREET_DATA_SUCCESS:
    case ActionType.STREET_DATA_ERROR:
    case ActionType.SN_DATA_SUCCESS:
    case ActionType.SN_DATA_ERROR:
      return false

    default:
      return state
  }
}

const initialSearchState = {
  district: { id: 0, name: 0 },
  street: '',
  building: '',
  serialNumber: '',
}

const search = (state = initialSearchState, { type, payload }) => {
  switch (type) {
    case ActionType.CHANGE_DISTRICT:
      return { ...state, ...payload }
    case ActionType.CHANGE_STREET:
      return { ...state, ...payload }
    case ActionType.CHANGE_BUILDING:
      return { ...state, ...payload }
    case ActionType.CHANGE_PERSONS_SERIAL_NUMBER:
      return { ...state, ...payload }
    case ActionType.RESET_SEARCH_VALUES:
      return { ...state, street: '', building: '', serialNumber: '' }
    default:
      return state
  }
}

const alert = (state = { text: null }, { type, payload }) => {
  switch (type) {
    case ActionType.SHOW_ALERT:
      return { ...state, text: payload }
    case ActionType.HIDE_ALERT:
      return { ...state, text: null }
    default:
      return state
  }
}

const initialDistrictsDataState = {
  currentData: null,
  currentDataError: null,
  storage: [{ id: 0, name: '-', data: null, streets: null, buildings: null }],
}

const districtsData = (
  state = initialDistrictsDataState,
  { type, payload }
) => {
  switch (type) {
    case ActionType.CURRENT_DISTRICT_DATA_SUCCESS:
      return { ...state, currentData: payload }
    case ActionType.CURRENT_DISTRICT_DATA_ERROR:
      return { ...state, currentDataError: payload }
    case ActionType.RESET_DISTRICT_CURRENT_DATA:
      return { ...state, currentData: null }
    case ActionType.SAVE_DISTRICTS_DATA:
      return {
        ...state,
        storage: state.storage.concat(payload),
      }

    default:
      return state
  }
}

const initialStreetDataState = {
  id: null,
  Data: [],
  DataError: null,
}

const streetData = (state = initialStreetDataState, { type, payload }) => {
  switch (type) {
    case ActionType.STREET_DATA_SUCCESS:
      return { ...state, Data: payload }
    case ActionType.STREET_DATA_ERROR:
      return { ...state, DataError: payload }
    case ActionType.RESET_STREET_DATA:
      return { ...state, Data: [] }
    default:
      return state
  }
}

const initialserialNumberDataState = {
  Data: [],
  DataError: null,
}

const serialNumberData = (
  state = initialserialNumberDataState,
  { type, payload }
) => {
  switch (type) {
    case ActionType.SN_DATA_SUCCESS:
      return { ...state, Data: payload }
    case ActionType.SN_DATA_ERROR:
      return { ...state, DataError: payload }
    case ActionType.RESET_SN_DATA:
      return { ...state, Data: [] }
    default:
      return state
  }
}

const displayData = (state = { data: [], ready: false }, { type, payload }) => {
  switch (type) {
    case ActionType.SET_DISPLAY_DATA:
      return { ...state, data: payload }
    case ActionType.RESET_DISPLAY_DATA:
      return { ...state, data: [], ready: false }
    case ActionType.SET_DATA_READY_TO_DISPLAY:
      return { ...state, ready: payload }
    default:
      return state
  }
}

const form = (state = { isOpened: true }, { type }) => {
  switch (type) {
    case ActionType.OPEN_FORM:
      return { ...state, isOpened: true }
    case ActionType.COLLAPSE_FORM:
      return { ...state, isOpened: false }
    default:
      return state
  }
}

export default combineReducers({
  loading,
  form,
  alert,
  search,
  districtsData,
  streetData,
  serialNumberData,
  displayData,
})
