export const newsCategory3UK = state =>
  state.news.newsCategory3.newsCategory3UK;
export const newsCategory3RU = state =>
  state.news.newsCategory3.newsCategory3RU;
export const newsCategory3ErrorUK = state =>
  state.news.newsCategory3.newsCategory3ErrorRU;
export const newsCategory3ErrorRU = state =>
  state.news.newsCategory3.newsCategory3ErrorRU;
export const newsCategory3Loading = state => state.news.newsCategory3.loading;
export const newsCategory3PageNumberUK = state =>
  state.news.newsCategory3.newsCategory3PageNumberUK;
export const newsCategory3PageNumberRU = state =>
  state.news.newsCategory3.newsCategory3PageNumberRU;
