import axios from 'axios';
import {
  newsCategory7Request,
  newsCategory7SuccessUK,
  newsCategory7SuccessRU,
  newsCategory7ErrorUK,
  newsCategory7ErrorRU,
  incrmentnewsCategory7PageNumberUK,
  incrmentnewsCategory7PageNumberRU,
} from './newsCategory7Actions';
import { locale } from '../../session/sessionSelectors';
import baseURL from '../../baseURL';

import {
  newsCategory7PageNumberUK,
  newsCategory7PageNumberRU,
} from './newsCategory7Selectors';

const setAxiosLang = lang => {
  axios.defaults.headers.common['Accept-Language'] = lang;
};
// eslint-disable-next-line import/prefer-default-export
export const newsCategory7Operation = () => (dispatch, getState) => {
  axios.defaults.baseURL = `${baseURL}`;
  dispatch(newsCategory7Request());

  const page =
    locale(getState()) === 'uk'
      ? newsCategory7PageNumberUK(getState())
      : newsCategory7PageNumberRU(getState());

  setAxiosLang(locale(getState()));

  axios
    .get(`news?categoriesId=7&limit=12&page=${page}`)
    .then(response => {
      if (locale(getState()) === 'uk')
        try {
          if (
            response.data.publichna_informatciia.length > 0 &&
            response.data.publichna_informatciia[0].id
          ) {
            dispatch(incrmentnewsCategory7PageNumberUK());
            dispatch(
              newsCategory7SuccessUK(response.data.publichna_informatciia),
            );
          } else {
            dispatch(incrmentnewsCategory7PageNumberUK());
            dispatch(newsCategory7SuccessUK(null));
          }
        } catch (error) {
          dispatch(newsCategory7ErrorUK(error));
        }
      else if (locale(getState()) === 'ru') {
        try {
          if (
            response.data.publichna_informatciia.length > 0 &&
            response.data.publichna_informatciia[0].id
          ) {
            dispatch(incrmentnewsCategory7PageNumberRU());
            dispatch(
              newsCategory7SuccessRU(response.data.publichna_informatciia),
            );
          } else {
            dispatch(incrmentnewsCategory7PageNumberRU());
            dispatch(newsCategory7SuccessRU(null));
          }
        } catch (error) {
          dispatch(newsCategory7ErrorRU(error));
        }
      }
    })
    .catch(error => {
      console.log(error);
      if (locale(getState()) === 'uk') dispatch(newsCategory7ErrorUK(error));
      else {
        dispatch(newsCategory7ErrorRU(error));
      }
    });
};
