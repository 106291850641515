export const ActionType = {
  NEWS_CATEGORY_4_REQUEST: 'NEWS_CATEGORY_4_REQUEST',
  NEWS_CATEGORY_4_SUCCESS_UK: 'NEWS_CATEGORY_4_SUCCESS_UK',
  NEWS_CATEGORY_4_SUCCESS_RU: 'NEWS_CATEGORY_4_SUCCESS_RU',
  NEWS_CATEGORY_4_ERROR_UK: 'NEWS_CATEGORY_4_ERROR_UK',
  NEWS_CATEGORY_4_ERROR_RU: 'NEWS_CATEGORY_4_ERROR_RU',
  NEWS_CATEGORY_4_PAGE_NUMBER_UK: 'NEWS_CATEGORY_4_NUMBER_UK',
  NEWS_CATEGORY_4_PAGE_NUMBER_RU: 'NEWS_CATEGORY_4_NUMBER_RU',
};

export const newsCategory4Request = () => ({
  type: ActionType.NEWS_CATEGORY_4_REQUEST,
});

export const newsCategory4SuccessUK = response => ({
  type: ActionType.NEWS_CATEGORY_4_SUCCESS_UK,
  payload: response,
});

export const newsCategory4SuccessRU = response => ({
  type: ActionType.NEWS_CATEGORY_4_SUCCESS_RU,
  payload: response,
});

export const newsCategory4ErrorUK = error => ({
  type: ActionType.NEWS_CATEGORY_4_ERROR_UK,
  payload: error,
});

export const newsCategory4ErrorRU = error => ({
  type: ActionType.NEWS_CATEGORY_4_ERROR_RU,
  payload: error,
});

export const incrmentNewsCategory4PageNumberUK = () => ({
  type: ActionType.NEWS_CATEGORY_4_PAGE_NUMBER_UK,
});

export const incrmentNewsCategory4PageNumberRU = () => ({
  type: ActionType.NEWS_CATEGORY_4_PAGE_NUMBER_RU,
});
