export const ActionType = {
  NEWS_DATE_REQUEST: 'NEWS_DATE_REQUEST',
  NEWS_DATE_SUCCESS_UK: 'NEWS_DATE_SUCCESS_UK',
  NEWS_DATE_SUCCESS_RU: 'NEWS_DATE_SUCCESS_RU',
  NEWS_DATE_ERROR_UK: 'NEWS_DATE_ERROR_UK',
  NEWS_DATE_ERROR_RU: 'NEWS_DATE_ERROR_RU',
  NEWS_DATE_PAGE_NUMBER_UK: 'NEWS_DATE_NUMBER_UK',
  NEWS_DATE_PAGE_NUMBER_RU: 'NEWS_DATE_NUMBER_RU',
  NEWS_DATE_RESET: 'NEWS_DATE_RESET',
};

export const newsDateRequest = () => ({
  type: ActionType.NEWS_DATE_REQUEST,
});

export const newsDateSuccessUK = response => ({
  type: ActionType.NEWS_DATE_SUCCESS_UK,
  payload: response,
});

export const newsDateSuccessRU = response => ({
  type: ActionType.NEWS_DATE_SUCCESS_RU,
  payload: response,
});

export const newsDateErrorUK = error => ({
  type: ActionType.NEWS_DATE_ERROR_UK,
  payload: error,
});

export const newsDateErrorRU = error => ({
  type: ActionType.NEWS_DATE_ERROR_RU,
  payload: error,
});

export const incrmentNewsDatePageNumberUK = () => ({
  type: ActionType.NEWS_DATE_PAGE_NUMBER_UK,
});

export const incrmentNewsDatePageNumberRU = () => ({
  type: ActionType.NEWS_DATE_PAGE_NUMBER_RU,
});

export const newsDateReset = () => ({
  type: ActionType.NEWS_DATE_RESET,
});
