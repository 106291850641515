import axios from 'axios';
import {
  startPageNewsRequest,
  startPageNewsSuccessUK,
  startPageNewsSuccessRU,
  startPageNewsErrorUK,
  startPageNewsErrorRU,
} from './startPageIndexActions';
import { locale } from '../session/sessionSelectors';
import baseURL from '../baseURL';

const setAxiosLang = lang => {
  axios.defaults.headers.common['Accept-Language'] = lang;
};

// eslint-disable-next-line import/prefer-default-export
export const onStartPageNewsOperation = () => (dispatch, getState) => {
  dispatch(startPageNewsRequest());

  setAxiosLang(locale(getState()));

  axios
    .get(`${baseURL}news?categoriesId=0&limit=6&page=1`)
    .then(response => {
      if (locale(getState()) === 'uk')
        try {
          dispatch(startPageNewsSuccessUK(response.data.all));
        } catch (error) {
          dispatch(startPageNewsErrorUK(error));
        }
      else {
        try {
          dispatch(startPageNewsSuccessRU(response.data.all));
        } catch (error) {
          dispatch(startPageNewsErrorRU(error));
        }
      }
    })
    .catch(error => {
      console.log(error);
      if (locale(getState()) === 'uk') dispatch(startPageNewsErrorUK(error));
      else {
        dispatch(startPageNewsErrorRU(error));
      }
    });
};
