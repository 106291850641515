import axios from 'axios';
import {
  newsCategory6Request,
  newsCategory6SuccessUK,
  newsCategory6SuccessRU,
  newsCategory6ErrorUK,
  newsCategory6ErrorRU,
  incrmentnewsCategory6PageNumberUK,
  incrmentnewsCategory6PageNumberRU,
} from './newsCategory6Actions';
import { locale } from '../../session/sessionSelectors';
import baseURL from '../../baseURL';

import {
  newsCategory6PageNumberUK,
  newsCategory6PageNumberRU,
} from './newsCategory6Selectors';

const setAxiosLang = lang => {
  axios.defaults.headers.common['Accept-Language'] = lang;
};
// eslint-disable-next-line import/prefer-default-export
export const newsCategory6Operation = () => (dispatch, getState) => {
  axios.defaults.baseURL = `${baseURL}`;
  dispatch(newsCategory6Request());

  const page =
    locale(getState()) === 'uk'
      ? newsCategory6PageNumberUK(getState())
      : newsCategory6PageNumberRU(getState());

  setAxiosLang(locale(getState()));

  axios
    .get(`news?categoriesId=6&limit=12&page=${page}`)
    .then(response => {
      if (locale(getState()) === 'uk')
        try {
          if (response.data.tcikave.length > 0 && response.data.tcikave[0].id) {
            dispatch(incrmentnewsCategory6PageNumberUK());
            dispatch(newsCategory6SuccessUK(response.data.tcikave));
          } else {
            dispatch(incrmentnewsCategory6PageNumberUK());
            dispatch(newsCategory6SuccessUK(null));
          }
        } catch (error) {
          dispatch(newsCategory6ErrorUK(error));
        }
      else if (locale(getState()) === 'ru') {
        try {
          if (response.data.tcikave.length > 0 && response.data.tcikave[0].id) {
            dispatch(incrmentnewsCategory6PageNumberRU());
            dispatch(newsCategory6SuccessRU(response.data.tcikave));
          } else {
            dispatch(incrmentnewsCategory6PageNumberRU());
            dispatch(newsCategory6SuccessRU(null));
          }
        } catch (error) {
          dispatch(newsCategory6ErrorRU(error));
        }
      }
    })
    .catch(error => {
      console.log(error);
      if (locale(getState()) === 'uk') dispatch(newsCategory6ErrorUK(error));
      else {
        dispatch(newsCategory6ErrorRU(error));
      }
    });
};
