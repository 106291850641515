import React from 'react';
import Office from '../../office/office';
import IndexContentBlockMob from './indexContentBlockMob/indexContentBlockMob';

const MagicIndexMob = ({ office, viberMob }) => (
  <div className="wrapper">
    <Office autorization={office} />
    <IndexContentBlockMob viberMob={viberMob} />
  </div>
);

export default MagicIndexMob;
