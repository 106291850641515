export const ActionType = {
  NEWS_CATEGORY_7_REQUEST: 'NEWS_CATEGORY_7_REQUEST',
  NEWS_CATEGORY_7_SUCCESS_UK: 'NEWS_CATEGORY_7_SUCCESS_UK',
  NEWS_CATEGORY_7_SUCCESS_RU: 'NEWS_CATEGORY_7_SUCCESS_RU',
  NEWS_CATEGORY_7_ERROR_UK: 'NEWS_CATEGORY_7_ERROR_UK',
  NEWS_CATEGORY_7_ERROR_RU: 'NEWS_CATEGORY_7_ERROR_RU',
  NEWS_CATEGORY_7_PAGE_NUMBER_UK: 'NEWS_CATEGORY_7_NUMBER_UK',
  NEWS_CATEGORY_7_PAGE_NUMBER_RU: 'NEWS_CATEGORY_7_NUMBER_RU',
};

export const newsCategory7Request = () => ({
  type: ActionType.NEWS_CATEGORY_7_REQUEST,
});

export const newsCategory7SuccessUK = response => ({
  type: ActionType.NEWS_CATEGORY_7_SUCCESS_UK,
  payload: response,
});

export const newsCategory7SuccessRU = response => ({
  type: ActionType.NEWS_CATEGORY_7_SUCCESS_RU,
  payload: response,
});

export const newsCategory7ErrorUK = error => ({
  type: ActionType.NEWS_CATEGORY_7_ERROR_UK,
  payload: error,
});

export const newsCategory7ErrorRU = error => ({
  type: ActionType.NEWS_CATEGORY_7_ERROR_RU,
  payload: error,
});

export const incrmentnewsCategory7PageNumberUK = () => ({
  type: ActionType.NEWS_CATEGORY_7_PAGE_NUMBER_UK,
});

export const incrmentnewsCategory7PageNumberRU = () => ({
  type: ActionType.NEWS_CATEGORY_7_PAGE_NUMBER_RU,
});
