import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AllNews from './allNews';
import CategoryNews from './newsCategiries';
import NewsDate from './newsDate';
import * as sessionSelectors from '../../../redux/session/sessionSelectors';
import {
  newsNavUK,
  newsNavRU,
} from '../../../redux/news/newsNav/newsNavSelectors';
import style from './buildingNewsList.module.css';

class BuildingNewsList extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    const { button, locale, newsNavUK, newsNavRU, routes } = this.props;
    const newsLang = locale === 'uk' ? newsNavUK : newsNavRU;
    const { news } = routes;

    return (
      <div className={style.newsWrapper}>
        <Switch>
          {newsLang &&
            Array.isArray(newsLang) &&
            newsLang.map(route => (
              <Route
                key={route.id}
                path={`/${locale}/${news}/${route.url}`}
                render={() => (
                  <CategoryNews button={button} routeNumber={route.id} />
                )}
              />
            ))}
          <Route
            path={`/${locale}/${news}/date/:date`}
            render={() => <NewsDate button={button} />}
          />
          <Route
            exact
            path={`/${locale}/${news}`}
            render={() => <AllNews button={button} />}
          />
        </Switch>
      </div>
    );
  }
}

BuildingNewsList.defaultProps = {
  newsNavUK: [
    {
      id: '1',
      name: 'menu name',
      url: '',
    },
  ],
  newsNavRU: [
    {
      id: '1',
      name: 'menu name',
      url: '',
    },
  ],
};

BuildingNewsList.propTypes = {
  newsNavUK: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  newsNavRU: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
};

const mapStateToProps = state => ({
  locale: sessionSelectors.locale(state),
  newsNavUK: newsNavUK(state),
  newsNavRU: newsNavRU(state),
});

export default connect(mapStateToProps)(BuildingNewsList);
