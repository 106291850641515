import React from 'react'
import style from './indexContentBlock.module.css'
import IndexCarousel from '../../../indexCarousel/indexCarousel'
import viberImage from '../../../../assets/img/contentImage/qr-code.svg'
import viberLogo from '../../../../assets/img/icons/viber.svg'
import Office from '../../../office/office'

const IndexContentBlock = ({ viber, office }) => (
  <div className={style.wrapper}>
    <div className={style.left}>
      <div className={style.slider}>
        <IndexCarousel />
      </div>
      <div className={style.viber}>
        <img
          src={viberImage}
          className={style.viberQrCode}
          alt='viber qr code'
        />
        <a
          href='https://al-s.top/mkH2O'
          target='_blank'
          rel='noopener noreferrer'
          className={style.viberText}
        >
          {viber}{' '}
          <span className={style.viberEng}>
            viber-bot{' '}
            <img src={viberLogo} className={style.viberLogo} alt='viber logo' />
          </span>
        </a>
      </div>
    </div>
    <div className={style.office}>
      <Office autorization={office} />
    </div>
  </div>
)

export default IndexContentBlock
