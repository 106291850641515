export const ActionType = {
  ALL_NEWS_REQUEST: 'ALL_NEWS_REQUEST',
  ALL_NEWS_SUCCESS_UK: 'ALL_NEWS_SUCCESS_UK',
  ALL_NEWS_SUCCESS_RU: 'ALL_NEWS_SUCCESS_RU',
  ALL_NEWS_ERROR_UK: 'ALL_NEWS_ERROR_UK',
  ALL_NEWS_ERROR_RU: 'ALL_NEWS_ERROR_RU',
  ALL_NEWS_PAGE_NUMBER_UK: 'ALL_NEWS_PAGE_NUMBER_UK',
  ALL_NEWS_PAGE_NUMBER_RU: 'ALL_NEWS_PAGE_NUMBER_RU',
};

export const allNewsRequest = () => ({
  type: ActionType.ALL_NEWS_REQUEST,
});

export const allNewsSuccessUK = response => ({
  type: ActionType.ALL_NEWS_SUCCESS_UK,
  payload: response,
});

export const allNewsSuccessRU = response => ({
  type: ActionType.ALL_NEWS_SUCCESS_RU,
  payload: response,
});

export const allNewsErrorUK = error => ({
  type: ActionType.ALL_NEWS_ERROR_UK,
  payload: error,
});

export const allNewsErrorRU = error => ({
  type: ActionType.ALL_NEWS_ERROR_RU,
  payload: error,
});

export const incrmentAllNewsPageNumberUK = () => ({
  type: ActionType.ALL_NEWS_PAGE_NUMBER_UK,
});

export const incrmentAllNewsPageNumberRU = () => ({
  type: ActionType.ALL_NEWS_PAGE_NUMBER_RU,
});
