import React, { Fragment, Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import NewsListDesk from '../../../components/newsList/newsListDesk/newsListDesk';
import NewsListMob from '../../../components/newsList/newsListMob/newsListMob';
import style from './buildingNewsList.module.css';
import * as sessionSelectors from '../../../redux/session/sessionSelectors';
import * as newsCategory1Selector from '../../../redux/news/newsCategory1/newsCategory1Selectors';
import * as newsCategory2Selector from '../../../redux/news/newsCategory2/newsCategory2Selectors';
import * as newsCategory3Selector from '../../../redux/news/newsCategory3/newsCategory3Selectors';
import * as newsCategory4Selector from '../../../redux/news/newsCategory4/newsCategory4Selectors';
import * as newsCategory5Selector from '../../../redux/news/newsCategory5/newsCategory5Selectors';
import * as newsCategory6Selector from '../../../redux/news/newsCategory6/newsCategory6Selectors';
import * as newsCategory7Selector from '../../../redux/news/newsCategory7/newsCategory7Selectors';
import * as newsCategory1Operation from '../../../redux/news/newsCategory1/newsCategory1Operations';
import * as newsCategory2Operation from '../../../redux/news/newsCategory2/newsCategory2Operations';
import * as newsCategory3Operation from '../../../redux/news/newsCategory3/newsCategory3Operations';
import * as newsCategory4Operation from '../../../redux/news/newsCategory4/newsCategory4Operations';
import * as newsCategory5Operation from '../../../redux/news/newsCategory5/newsCategory5Operations';
import * as newsCategory6Operation from '../../../redux/news/newsCategory6/newsCategory6Operations';
import * as newsCategory7Operation from '../../../redux/news/newsCategory7/newsCategory7Operations';

class NewsCategories extends Component {
  state = {};

  initSelector = i => {
    switch (i) {
      case 1:
        return this.props.onInitData1();

      case 2:
        return this.props.onInitData2();

      case 3:
        return this.props.onInitData3();

      case 4:
        return this.props.onInitData4();

      case 5:
        return this.props.onInitData5();

      case 6:
        return this.props.onInitData6();

      case 7:
        return this.props.onInitData7();

      default:
        break;
    }
  };

  innerSelectorUK = index => {
    switch (index) {
      case 1:
        return this.props.newsCategory1UK;

      case 2:
        return this.props.newsCategory2UK;

      case 3:
        return this.props.newsCategory3UK;

      case 4:
        return this.props.newsCategory4UK;

      case 5:
        return this.props.newsCategory5UK;

      case 6:
        return this.props.newsCategory6UK;

      case 7:
        return this.props.newsCategory7UK;

      default:
        break;
    }
  };

  innerSelectorRU = index => {
    switch (index) {
      case 1:
        return this.props.newsCategory1RU;

      case 2:
        return this.props.newsCategory2RU;

      case 3:
        return this.props.newsCategory3RU;

      case 4:
        return this.props.newsCategory4RU;

      case 5:
        return this.props.newsCategory5RU;

      case 6:
        return this.props.newsCategory6RU;

      case 7:
        return this.props.newsCategory7RU;

      default:
        break;
    }
  };

  onLoadMore = () => {
    const { routeNumber } = this.props;
    this.initSelector(Number(routeNumber));
  };

  componentDidMount = () => {
    const { routeNumber } = this.props;
    if (
      (this.props.locale === 'uk' &&
        !this.innerSelectorUK(Number(routeNumber)).length) ||
      (this.props.locale === 'ru' &&
        !this.innerSelectorRU(Number(routeNumber)).length)
    ) {
      this.initSelector(Number(routeNumber));
    }
  };

  render() {
    const {
      button,
      routeNumber,
      locale,
      newsCategory1UK = [],
      newsCategory1RU = [],
      newsCategory2UK = [],
      newsCategory2RU = [],
      newsCategory3UK = [],
      newsCategory3RU = [],
      newsCategory4UK = [],
      newsCategory4RU = [],
      newsCategory5UK = [],
      newsCategory5RU = [],
      newsCategory6UK = [],
      newsCategory6RU = [],
      newsCategory7UK = [],
      newsCategory7RU = [],
      // newsCategory1ErrorUK,
      // newsCategory1ErrorRU,
      // newsCategory2ErrorUK,
      // newsCategory2ErrorRU,
      // newsCategory3ErrorUK,
      // newsCategory3ErrorRU,
      // newsCategory4ErrorUK,
      // newsCategory4ErrorRU,
      // newsCategory5ErrorUK,
      // newsCategory5ErrorRU,
      // newsCategory6ErrorUK,
      // newsCategory6ErrorRU,
      // newsCategory7ErrorUK,
      // newsCategory7ErrorRU,
      newsCategory1Loading,
      newsCategory2Loading,
      newsCategory3Loading,
      newsCategory4Loading,
      newsCategory5Loading,
      newsCategory6Loading,
      newsCategory7Loading,
    } = this.props;
    const categoryLang1 = locale === 'uk' ? newsCategory1UK : newsCategory1RU;
    const categoryLang2 = locale === 'uk' ? newsCategory2UK : newsCategory2RU;
    const categoryLang3 = locale === 'uk' ? newsCategory3UK : newsCategory3RU;
    const categoryLang4 = locale === 'uk' ? newsCategory4UK : newsCategory4RU;
    const categoryLang5 = locale === 'uk' ? newsCategory5UK : newsCategory5RU;
    const categoryLang6 = locale === 'uk' ? newsCategory6UK : newsCategory6RU;
    const categoryLang7 = locale === 'uk' ? newsCategory7UK : newsCategory7RU;
    const innerSelector = index => {
      switch (index) {
        case 1:
          return categoryLang1.filter(el => el !== null);

        case 2:
          return categoryLang2.filter(el => el !== null);

        case 3:
          return categoryLang3.filter(el => el !== null);

        case 4:
          return categoryLang4.filter(el => el !== null);

        case 5:
          return categoryLang5.filter(el => el !== null);

        case 6:
          return categoryLang6.filter(el => el !== null);

        case 7:
          return categoryLang7.filter(el => el !== null);

        default:
          break;
      }
    };

    const innerSelectorForButton = index => {
      switch (index) {
        case 1:
          return categoryLang1;

        case 2:
          return categoryLang2;

        case 3:
          return categoryLang3;

        case 4:
          return categoryLang4;

        case 5:
          return categoryLang5;

        case 6:
          return categoryLang6;

        case 7:
          return categoryLang7;

        default:
          break;
      }
    };

    const loadingSelector = index => {
      switch (index) {
        case 1:
          return newsCategory1Loading;

        case 2:
          return newsCategory2Loading;

        case 3:
          return newsCategory3Loading;

        case 4:
          return newsCategory4Loading;

        case 5:
          return newsCategory5Loading;

        case 6:
          return newsCategory6Loading;

        case 7:
          return newsCategory7Loading;

        default:
          break;
      }
    };

    const text =
      locale === 'uk'
        ? 'немає новин по цій категорії'
        : 'нет новостей по этой категории';

    return (
      <Fragment>
        {innerSelector(Number(routeNumber)) &&
          innerSelector(Number(routeNumber)).length > 0 && (
            <Media query={{ minWidth: 1200 }}>
              {matches =>
                matches ? (
                  <NewsListDesk news={innerSelector(Number(routeNumber))} />
                ) : (
                  <NewsListMob news={innerSelector(Number(routeNumber))} />
                )
              }
            </Media>
          )}
        {innerSelector(Number(routeNumber)).length > 11 &&
          innerSelectorForButton(Number(routeNumber))[
            innerSelectorForButton(Number(routeNumber)).length - 1
          ] !== null && (
            <button
              disabled={loadingSelector(Number(routeNumber))}
              onClick={this.onLoadMore}
              className={style.showMore}
            >
              {button}
            </button>
          )}
        {innerSelector(Number(routeNumber)).length === 0 &&
          !loadingSelector(Number(routeNumber)) && (
            <h2 className={style.error}>{text}</h2>
          )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  locale: sessionSelectors.locale(state),

  newsCategory1UK: newsCategory1Selector.newsCategory1UK(state),
  newsCategory1RU: newsCategory1Selector.newsCategory1RU(state),

  newsCategory2UK: newsCategory2Selector.newsCategory2UK(state),
  newsCategory2RU: newsCategory2Selector.newsCategory2RU(state),

  newsCategory3UK: newsCategory3Selector.newsCategory3UK(state),
  newsCategory3RU: newsCategory3Selector.newsCategory3RU(state),

  newsCategory4UK: newsCategory4Selector.newsCategory4UK(state),
  newsCategory4RU: newsCategory4Selector.newsCategory4RU(state),

  newsCategory5UK: newsCategory5Selector.newsCategory5UK(state),
  newsCategory5RU: newsCategory5Selector.newsCategory5RU(state),

  newsCategory6UK: newsCategory6Selector.newsCategory6UK(state),
  newsCategory6RU: newsCategory6Selector.newsCategory6RU(state),

  newsCategory7UK: newsCategory7Selector.newsCategory7UK(state),
  newsCategory7RU: newsCategory7Selector.newsCategory7RU(state),

  newsCategory1ErrorUK: newsCategory1Selector.newsCategory1ErrorUK(state),
  newsCategory1ErrorRU: newsCategory1Selector.newsCategory1ErrorRU(state),

  newsCategory2ErrorUK: newsCategory2Selector.newsCategory2ErrorUK(state),
  newsCategory2ErrorRU: newsCategory2Selector.newsCategory2ErrorRU(state),

  newsCategory3ErrorUK: newsCategory3Selector.newsCategory3ErrorUK(state),
  newsCategory3ErrorRU: newsCategory3Selector.newsCategory3ErrorRU(state),

  newsCategory4ErrorUK: newsCategory4Selector.newsCategory4ErrorUK(state),
  newsCategory4ErrorRU: newsCategory4Selector.newsCategory4ErrorRU(state),

  newsCategory5ErrorUK: newsCategory5Selector.newsCategory5ErrorUK(state),
  newsCategory5ErrorRU: newsCategory5Selector.newsCategory5ErrorRU(state),

  newsCategory6ErrorUK: newsCategory6Selector.newsCategory6ErrorUK(state),
  newsCategory6ErrorRU: newsCategory6Selector.newsCategory6ErrorRU(state),

  newsCategory7ErrorUK: newsCategory7Selector.newsCategory7ErrorUK(state),
  newsCategory7ErrorRU: newsCategory7Selector.newsCategory7ErrorRU(state),

  newsCategory1Loading: newsCategory1Selector.newsCategory1Loading(state),

  newsCategory2Loading: newsCategory2Selector.newsCategory2Loading(state),

  newsCategory3Loading: newsCategory3Selector.newsCategory3Loading(state),

  newsCategory4Loading: newsCategory4Selector.newsCategory4Loading(state),

  newsCategory5Loading: newsCategory5Selector.newsCategory5Loading(state),

  newsCategory6Loading: newsCategory6Selector.newsCategory6Loading(state),

  newsCategory7Loading: newsCategory7Selector.newsCategory7Loading(state),
});

const mapDispatchToProps = {
  onInitData1: newsCategory1Operation.newsCategory1Operation,
  onInitData2: newsCategory2Operation.newsCategory2Operation,
  onInitData3: newsCategory3Operation.newsCategory3Operation,
  onInitData4: newsCategory4Operation.newsCategory4Operation,
  onInitData5: newsCategory5Operation.newsCategory5Operation,
  onInitData6: newsCategory6Operation.newsCategory6Operation,
  onInitData7: newsCategory7Operation.newsCategory7Operation,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsCategories);
