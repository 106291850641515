import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import style from './dateFinder.module.css';
import * as sessionSelectors from '../../../redux/session/sessionSelectors';

const setMonth = month => (month === '0' ? `${month + 1}` : `0${month + 1}`);

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    width: 202,
    height: 31,
    fontSize: '14px',
    lineHeight: '19px',
    background: '#315F8F',
    '& .MuiInput-underline:before': {
      content: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiSelect-icon': {
      fill: '#ffffff',
      right: '-5px',
    },
    '& .MuiInputBase-inputSelect': {
      padding: '0 10px 0 10px',
    },
  },
  formControl: {
    margin: 0,
    minWidth: 102,
    textTransform: 'capitalize',
    '&:nth-child(2)': {
      minWidth: 64,
    },
  },
  selectEmpty: {
    marginTop: 0,
    textTransform: 'capitalize',
    color: '#FBFBFB',
  },
}));

function SimpleSelect(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    month: '0',
    year: '2019',
  });

  function handleChange(event) {
    setValues(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }));
  }
  const {
    monthes = [],
    month,
    year,
    locale,
    currentYearArr = [],
    news,
  } = props;

  return (
    <form className={classes.root} autoComplete="off">
      <FormControl className={classes.formControl}>
        <Select
          value={values.month}
          onChange={handleChange}
          name="month"
          className={classes.selectEmpty}
        >
          <MenuItem value="" disabled>
            {month}
          </MenuItem>
          {monthes.map((el, i) => (
            <MenuItem value={i} key={el}>
              {el}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <Select
          value={values.year}
          onChange={handleChange}
          name="year"
          displayEmpty
          className={classes.selectEmpty}
        >
          <MenuItem value="" disabled>
            {year}
          </MenuItem>
          {currentYearArr.map(el => (
            <MenuItem value={el} key={el}>
              {el}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Link
        className={style.linkArrow}
        to={`/${locale}/${news}/date/${values.year}month${
          values.month > 8 ? values.month + 1 : setMonth(values.month)
        }`}
      >
        <svg
          className={style.arrow}
          width="18"
          height="15"
          viewBox="0 0 19 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M18.7071 8.70711C19.0976 8.31658 19.0976 7.68342 18.7071 7.29289L12.3431 0.928932C11.9526 0.538408 11.3195 0.538408 10.9289 0.928932C10.5384 1.31946 10.5384 1.95262 10.9289 2.34315L16.5858 8L10.9289 13.6569C10.5384 14.0474 10.5384 14.6805 10.9289 15.0711C11.3195 15.4616 11.9526 15.4616 12.3431 15.0711L18.7071 8.70711ZM0 9L18 9V7L0 7L0 9Z" />
        </svg>
      </Link>
    </form>
  );
}

const mapStateToProps = state => ({
  locale: sessionSelectors.locale(state),
});

export default connect(mapStateToProps)(SimpleSelect);
