import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import * as sessionOperations from '../../../redux/session/sessionOperations';
import style from './regForm.module.css';
import loginErrorImg from '../../../assets/img/icons/errorImage.svg';

const regExpLogin = '^abn([0-9]{3,6})$';
const regExpPassword = '^[0-9]{10}$';

const CssTextField = withStyles({
  root: {
    fontFamily: 'Open sans',
    fontSize: 16,
    lineHeight: 22,
    color: '#FBFBFB',
    '&:nth-child(2)': {
      marginBottom: 25,
    },
    '& .MuiInput-input': {
      backgroundRepeat: 'no-repeat',
      color: '#FBFBFB',
      padding: '6px 10px 7px 10px',
    },
    '& label': {
      fontSize: 12,
      color: '#FBFBFB',
      marginLeft: 10,
      whiteSpace: 'nowrap',
    },
    '& label.Mui-focused': {
      color: '#FBFBFB',
    },
    '&:hover .MuiInput-underline:before': {
      borderBottomColor: '#315F8F',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#315F8F',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#315F8F',
      },
    },
    '& .MuiInput-underline::before': {
      borderBottom: '1px solid #315F8F',
    },
  },
})(TextField);

class RegFormCorporation extends Component {
  state = {
    mail: '',
    password: '',
  };

  handleChange = ({ target }) => {
    const { name, value } = target;

    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.onLoginEnterprise({ ...this.state });
  };

  render() {
    const { mail, password } = this.state;
    const { textFields, setErrorNull } = this.props;

    const {
      email,
      enterPassword,
      forgot,
      instruction,
      buttonEnter,
      buttonRegistration,
      loginErrorEnterprise,
    } = textFields;

    return (
      <form
        onClick={setErrorNull}
        onSubmit={this.handleSubmit}
        className={style.form}
      >
        <CssTextField
          onChange={this.handleChange}
          name="mail"
          value={mail}
          type="text"
          id="standard-input"
          label={email}
          required
          inputProps={{
            pattern: regExpLogin,
          }}
        />
        <CssTextField
          onChange={this.handleChange}
          name="password"
          value={password}
          type="password"
          id="standard-password"
          label={enterPassword}
          inputProps={{ pattern: regExpPassword }}
          required
        />
        <div className={style.buttonWrapper}>
          <button className={style.button} type="submit">
            {buttonEnter}
          </button>
          <a
            href="https://cent.vodokanal.mk.ua/#REGISTER"
            target="_blank"
            rel="noopener noreferrer"
            className={style.linkButton}
          >
            {buttonRegistration}
          </a>
          {loginErrorEnterprise && (
            <div onClick={setErrorNull} className={style.errorWrapper}>
              <img
                className={style.errorIcon}
                src={loginErrorImg}
                alt="error icon"
              />
              <p className={style.errorText}>
                {(loginErrorEnterprise.data &&
                  loginErrorEnterprise.data.text) ||
                  'Some network error'}
              </p>
            </div>
          )}
        </div>
        <a
          href="https://cent.vodokanal.mk.ua/#RESTORE"
          target="_blank"
          rel="noopener noreferrer"
          className={style.help}
        >
          {forgot}
        </a>
        <a
          href="http://cuse.vodokanal.mk.ua/cpp.vodokanal.mk.ua.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className={style.help}
        >
          {instruction}
        </a>
      </form>
    );
  }
}

const mapDispatchToProps = {
  onLoginEnterprise: sessionOperations.loginEnterpise,
};

export default connect(null, mapDispatchToProps)(RegFormCorporation);
