import PropTypes from 'prop-types';
import React, { Component } from 'react';
import style from './mapInfo.module.css';
import searchIcon from '../../../assets/img/icons/search.svg';
import MotionComponent from './motionComponent/motionComponent';
import placeholderImg from '../../../assets/img/icons/icon-placeholder.svg';

const hour = time => {
  const date = new Date(Date.parse(time));
  return date;
};

const percentStyles = {
  position: 'absolute',
  zIndex: 2,
  top: 0,
  left: 0,
  height: '100%',
  background: '#0B8EC5',
  clipPath: 'polygon(4px 0, 100% 0%, calc(100% - 4px) 100%, 0 100%)',
};

const timePercent = (timeStart, timeEnd) => {
  const timeFull = Date.parse(timeEnd) - Date.parse(timeStart);
  const timeCollapsed = Date.now() - Date.parse(timeStart);

  return Math.round((timeCollapsed / timeFull) * 100) <= 100
    ? Math.round((timeCollapsed / timeFull) * 100)
    : 100;
};

function currentCrushChange(crushes, value) {
  const currentCrush = crushes.filter(crush =>
    crush.address.toLowerCase().includes(value.toLowerCase()),
  );
  return currentCrush;
}

export default class MapInfo extends Component {
  state = {
    searchValue: '',
  };

  handleChange = ({ target }) => this.setState({ searchValue: target.value });

  render() {
    const { currentId, dataMap, handleClick, search, mapLoading } = this.props;
    const { searchValue } = this.state;
    const currentCrushes =
      searchValue === '' ? dataMap : currentCrushChange(dataMap, searchValue);
    return (
      <div className={style.wrapper}>
        <div className={style.form}>
          <input
            className={style.input}
            onChange={this.handleChange}
            type="text"
            name="search"
            placeholder={search}
            value={searchValue}
          />
          <div className={style.searchImgWrapper}>
            <img
              className={style.searchImg}
              alt="search icon"
              src={searchIcon}
            />
          </div>
        </div>
        <ul className={style.list}>
          {currentCrushes.length > 0
            ? currentCrushes.map(crush => (
                <li
                  onClick={() => handleClick(crush.id)}
                  key={crush.id}
                  className={style.listItem}
                >
                  <p className={style.streetName}>
                    <img
                      src={placeholderImg}
                      alt="street placeholder"
                      className={style.placeholderImg}
                    />
                    <span>{crush.address}</span>
                  </p>
                  <p className={style.crushName}>{crush.name}</p>
                  <div className={style.dateWrapper}>
                    <p className={style.timeStart}>
                      <span className={style.hours}>
                        {hour(crush.fact_start).getHours() >= 10
                          ? hour(crush.fact_start).getHours()
                          : `0${hour(crush.fact_start).getHours()}`}
                        .
                        {hour(crush.fact_start).getMinutes() >= 10
                          ? hour(crush.fact_start).getMinutes()
                          : `0${hour(crush.fact_start).getMinutes()}`}
                      </span>
                      <span className={style.date}>
                        {hour(crush.fact_start).getUTCDate() > 9
                          ? hour(crush.fact_start).getUTCDate()
                          : `0${hour(crush.fact_start).getUTCDate()}`}
                        .
                        {hour(crush.fact_start).getUTCMonth() > 8
                          ? hour(crush.fact_start).getUTCMonth() + 1
                          : `0${hour(crush.fact_start).getUTCMonth() + 1}`}
                        .{hour(crush.fact_start).getUTCFullYear()}
                      </span>
                    </p>
                    <p className={style.timeEnd}>
                      <span className={style.hours}>
                        {hour(crush.plan_finish).getHours() >= 10
                          ? hour(crush.plan_finish).getHours()
                          : `0${hour(crush.plan_finish).getHours()}`}
                        .
                        {hour(crush.plan_finish).getMinutes() >= 10
                          ? hour(crush.plan_finish).getMinutes()
                          : `0${hour(crush.plan_finish).getMinutes()}`}
                      </span>
                      <span className={style.date}>
                        {hour(crush.plan_finish).getUTCDate() > 9
                          ? hour(crush.plan_finish).getUTCDate()
                          : `0${hour(crush.plan_finish).getUTCDate()}`}
                        .
                        {hour(crush.plan_finish).getUTCMonth() > 8
                          ? hour(crush.plan_finish).getUTCMonth() + 1
                          : `0${hour(crush.plan_finish).getUTCMonth() + 1}`}
                        .{hour(crush.plan_finish).getUTCFullYear()}
                      </span>
                    </p>
                  </div>
                  <div className={style.timeDiagram}>
                    <span className={style.timePercent}>
                      {timePercent(crush.fact_start, crush.plan_finish)}%
                    </span>
                    <div
                      style={{
                        ...percentStyles,
                        width: `${timePercent(
                          crush.fact_start,
                          crush.plan_finish,
                        )}%`,
                      }}
                    />
                  </div>
                  <MotionComponent currentId={currentId} crush={crush} />
                </li>
              ))
            : !mapLoading && (
                <p className={style.noRezults}>No matching results!</p>
              )}
        </ul>
      </div>
    );
  }
}

MapInfo.defaultProps = {
  dataMap: [
    {
      id: '',
      name: 'some street',
      address: 'some crush',
      fact_start: '2019-11-24T06:10',
      plan_finish: '2019-11-24T15:00',
      type: 'v',
      places: ['some streets'],
      location: {
        lat: '46.479787',
        lng: '30.737273',
      },
    },
  ],
};

MapInfo.propTypes = {
  dataMap: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      address: PropTypes.string,
      fact_start: PropTypes.string,
      plan_finish: PropTypes.string,
      type: PropTypes.string,
      places: PropTypes.arrayOf(PropTypes.string),
      location: PropTypes.shape({
        lat: PropTypes.string,
        lng: PropTypes.string,
      }),
    }),
  ),
};
