import { combineReducers } from 'redux';
import { ActionType } from './newsDateActions';

const loading = (state = false, { type }) => {
  switch (type) {
    case ActionType.NEWS_DATE_REQUEST:
      return true;

    case ActionType.NEWS_DATE_SUCCESS_UK:
    case ActionType.NEWS_DATE_SUCCESS_RU:
    case ActionType.NEWS_DATE_ERROR_UK:
    case ActionType.NEWS_DATE_ERROR_RU:
    case ActionType.NEWS_DATE_RESET:
      return false;

    default:
      return state;
  }
};

const newsDateUK = (state = [], { type, payload }) => {
  switch (type) {
    case ActionType.NEWS_DATE_SUCCESS_UK:
      return state.concat(payload);

    case ActionType.NEWS_DATE_RESET:
      return [];

    default:
      return state;
  }
};

const newsDateRU = (state = [], { type, payload }) => {
  switch (type) {
    case ActionType.NEWS_DATE_SUCCESS_RU:
      return state.concat(payload);

    case ActionType.NEWS_DATE_RESET:
      return [];

    default:
      return state;
  }
};

const newsDateErrorUK = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.NEWS_DATE_ERROR_UK:
      return payload;

    case ActionType.NEWS_DATE_REQUEST:
    case ActionType.NEWS_DATE_SUCCESS_UK:
    case ActionType.NEWS_DATE_RESET:
      return null;

    default:
      return state;
  }
};

const newsDateErrorRU = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.NEWS_DATE_ERROR_RU:
      return payload;

    case ActionType.NEWS_DATE_REQUEST:
    case ActionType.NEWS_DATE_SUCCESS_RU:
    case ActionType.NEWS_DATE_RESET:
      return null;

    default:
      return state;
  }
};

const newsDatePageNumberUK = (state = 1, { type }) => {
  switch (type) {
    case ActionType.NEWS_DATE_PAGE_NUMBER_UK:
      return state + 1;

    case ActionType.NEWS_DATE_RESET:
      return 1;

    default:
      return state;
  }
};

const newsDatePageNumberRU = (state = 1, { type }) => {
  switch (type) {
    case ActionType.NEWS_DATE_PAGE_NUMBER_RU:
      return state + 1;

    case ActionType.NEWS_DATE_RESET:
      return 1;

    default:
      return state;
  }
};

export default combineReducers({
  loading,
  newsDateUK,
  newsDateRU,
  newsDateErrorUK,
  newsDateErrorRU,
  newsDatePageNumberUK,
  newsDatePageNumberRU,
});
