import React, { Component, createContext } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ru from '../locales/ru.json';
import uk from '../locales/uk.json';
import * as sessionSelectors from '../redux/session/sessionSelectors';
import { setLocale } from '../redux/session/sessionActions';
import { newsNavUK, newsNavRU } from '../redux/news/newsNav/newsNavSelectors';
import { translitiration } from '../redux/translitiration/translitirationSelectors';

const locales = { uk, ru };

export const LocalizationContext = createContext({
  currentLocale: 'uk',
  localizedContent: locales.uk,
  locales: Object.keys(locales),
  changeLocale: () => null,
});

const regDate = /[0-9]+month[0-9]+/;

const hashSwitcher = hash => {
  switch (hash) {
    case '#people&povidomiti-pro-avariiu':
      return '#people&soobshchit-ob-avarii';

    case '#people&soobshchit-ob-avarii':
      return '#people&povidomiti-pro-avariiu';

    case '#people&zapisatis-na-priyom':
      return '#people&zapisatsia-na-priem';

    case '#people&zapisatsia-na-priem':
      return '#people&zapisatis-na-priyom';

    case '#people&zamoviti-dovidku':
      return '#people&zakazat-spravku';

    case '#people&zakazat-spravku':
      return '#people&zamoviti-dovidku';

    case '#people&viklikati-kontrolera':
      return '#people&vyzvat-kontrolera';

    case '#people&vyzvat-kontrolera':
      return '#people&viklikati-kontrolera';

    case '#people&spovistiti-pro-kradizhku-vodi':
      return '#people&soobshchit-o-krazhe-vody';

    case '#people&soobshchit-o-krazhe-vody':
      return '#people&spovistiti-pro-kradizhku-vodi';

    case '#people&zalishiti-zvernennia':
      return '#people&ostavit-soobshchenie';

    case '#people&ostavit-soobshchenie':
      return '#people&zalishiti-zvernennia';

    case '#people&zamoviti-povirku-abo-vstanovlennia-lichilnika':
      return '#people&zakazat-poverku-ili-ustanovku-schetchika';

    case '#people&zakazat-poverku-ili-ustanovku-schetchika':
      return '#people&zamoviti-povirku-abo-vstanovlennia-lichilnika';

    case '#people&zamoviti-analiz-vodi':
      return '#people&zakazat-analiz-vody';

    case '#people&zakazat-analiz-vody':
      return '#people&zamoviti-analiz-vodi';

    default:
      return '';
  }
};

const switcher = url => {
  switch (url) {
    case '/info/pro-pidpriemstvo/istoriia':
      return '/info/pro-predpriiatie/istoriia';

    case '/info/pro-predpriiatie/istoriia':
      return '/info/pro-pidpriemstvo/istoriia';

    case '/info/pro-pidpriemstvo/zvernennia':
      return '/info/pro-predpriiatie/obrashcheniia';

    case '/info/pro-predpriiatie/obrashcheniia':
      return '/info/pro-pidpriemstvo/zvernennia';

    case '/novini':
      return '/novosti';

    case '/novosti':
      return '/novini';

    case '/enterprise':
      return '/enterprise';

    case '/press':
      return '/press';

    case '/novini/tarifi':
      return '/novosti/tarify';

    case '/novosti/tarify':
      return '/novini/tarifi';

    case '/novini/remonti':
      return '/novosti/remonty';

    case '/novosti/remonty':
      return '/novini/remonti';

    case '/novini/podiyi':
      return '/novosti/sobytiia';

    case '/novosti/sobytiia':
      return '/novini/podiyi';

    case '/novini/povidomlennia':
      return '/novosti/soobshcheniia';

    case '/novosti/soobshcheniia':
      return '/novini/povidomlennia';

    case '/info':
      return '/info';

    case `/novini/date/${regDate.exec(url.slice(13))}`:
      return `/novosti/date/2019month01`;

    case `/novosti/date/${regDate.exec(url.slice(14))}`:
      return `/novini/date/2019month01`;

    default:
      return '/';
  }
};

export class Localization extends Component {
  state = {
    currentLocale: this.props.locale,
    localizedContent: locales[this.props.locale],
    locales: Object.keys(locales),
  };

  componentDidMount = () => {
    const urlLang = this.props.location.pathname.slice(1, 3);
    if (
      (urlLang === 'ru' || urlLang === 'uk') &&
      urlLang !== this.state.currentLocale
    ) {
      this.setState({
        currentLocale: urlLang,
        localizedContent: locales[urlLang],
      });
      this.props.setLocale(urlLang);
    }
  };

  changeLocale = locale => {
    const { translitiration } = this.props;
    const url = this.props.location.pathname.slice(3);
    this.setState(
      {
        currentLocale: locale,
        localizedContent: locales[locale],
      },
      () =>
        // eslint-disable-next-line no-nested-ternary
        translitiration
          ? this.props.history.push(
              `/${this.state.currentLocale}/${translitiration}`,
            )
          : this.props.location.hash
          ? this.props.history.push(
              `/${this.state.currentLocale}${switcher(url)}${hashSwitcher(
                this.props.location.hash,
              )}`,
            )
          : this.props.history.push(
              `/${this.state.currentLocale}${switcher(url)}`,
            ),
    );
    this.props.setLocale(locale);
  };

  static Consumer = LocalizationContext.Consumer;

  render() {
    return (
      <LocalizationContext.Provider
        value={{ ...this.state, changeLocale: this.changeLocale }}
      >
        {this.props.children}
      </LocalizationContext.Provider>
    );
  }
}

const mapStateToProps = state => ({
  locale: sessionSelectors.locale(state),
  newsNavUK: newsNavUK(state),
  newsNavRU: newsNavRU(state),
  translitiration: translitiration(state),
});

const mapDispatchToProps = {
  setLocale,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Localization));
