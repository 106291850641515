import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import ReduxThunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import sessionReducer from './session/sessionReducer'
import initDataReducer from './initData/initDataReducer'
import publication from './publication/publicationReducer'
import startPageNews from './startPageIndex/startPageIndexReducer'
import sideNews from './sideNews/sideNewsReducer'
import news from './news/newsReducer'
import singleNews from './singleNews/singleNewsReducer'
import translitiration from './translitiration/translitirationreducer'
import map from './map/mapReducer'
import debtors from './debtors/debtorsReducer'

const sessionPersistConfig = {
  key: 'session',
  storage,
  whitelist: ['tokenLK', 'tokenEnterprise', 'locale'],
}

const rootReducer = combineReducers({
  session: persistReducer(sessionPersistConfig, sessionReducer),
  initData: initDataReducer,
  publication,
  startPageNews,
  sideNews,
  news,
  singleNews,
  translitiration,
  map,
  debtors,
})

const middleware = [ReduxThunk]

const enchancer =
  process.env.NODE_ENV === 'development'
    ? composeWithDevTools(applyMiddleware(...middleware))
    : applyMiddleware(...middleware)

export const store = createStore(rootReducer, enchancer)

export const persistor = persistStore(store)
