import { combineReducers } from 'redux';
import { ActionType } from './allNewsActions';

const loading = (state = false, { type }) => {
  switch (type) {
    case ActionType.ALL_NEWS_REQUEST:
      return true;

    case ActionType.ALL_NEWS_SUCCESS_UK:
    case ActionType.ALL_NEWS_SUCCESS_RU:
    case ActionType.ALL_NEWS_ERROR_UK:
    case ActionType.ALL_NEWS_ERROR_RU:
      return false;

    default:
      return state;
  }
};

const allNewsUK = (state = [], { type, payload }) => {
  switch (type) {
    case ActionType.ALL_NEWS_SUCCESS_UK:
      return state.concat(payload);

    default:
      return state;
  }
};

const allNewsRU = (state = [], { type, payload }) => {
  switch (type) {
    case ActionType.ALL_NEWS_SUCCESS_RU:
      return state.concat(payload);

    default:
      return state;
  }
};

const allNewsErrorUK = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.ALL_NEWS_ERROR_UK:
      return payload;

    case ActionType.ALL_NEWS_REQUEST:
    case ActionType.ALL_NEWS_SUCCESS_UK:
      return null;

    default:
      return state;
  }
};

const allNewsErrorRU = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.ALL_NEWS_ERROR_RU:
      return payload;

    case ActionType.ALL_NEWS_REQUEST:
    case ActionType.ALL_NEWS_SUCCESS_RU:
      return null;

    default:
      return state;
  }
};

const allNewsPageNumberUK = (state = 1, { type }) => {
  switch (type) {
    case ActionType.ALL_NEWS_PAGE_NUMBER_UK:
      return state + 1;

    default:
      return state;
  }
};

const allNewsPageNumberRU = (state = 1, { type }) => {
  switch (type) {
    case ActionType.ALL_NEWS_PAGE_NUMBER_RU:
      return state + 1;

    default:
      return state;
  }
};

export default combineReducers({
  loading,
  allNewsUK,
  allNewsRU,
  allNewsErrorUK,
  allNewsErrorRU,
  allNewsPageNumberUK,
  allNewsPageNumberRU,
});
