export const ActionType = {
  NEWS_CATEGORY_1_REQUEST: 'NEWS_CATEGORY_1_REQUEST',
  NEWS_CATEGORY_1_SUCCESS_UK: 'NEWS_CATEGORY_1_SUCCESS_UK',
  NEWS_CATEGORY_1_SUCCESS_RU: 'NEWS_CATEGORY_1_SUCCESS_RU',
  NEWS_CATEGORY_1_ERROR_UK: 'NEWS_CATEGORY_1_ERROR_UK',
  NEWS_CATEGORY_1_ERROR_RU: 'NEWS_CATEGORY_1_ERROR_RU',
  NEWS_CATEGORY_1_PAGE_NUMBER_UK: 'NEWS_CATEGORY_1_NUMBER_UK',
  NEWS_CATEGORY_1_PAGE_NUMBER_RU: 'NEWS_CATEGORY_1_NUMBER_RU',
};

export const newsCategory1Request = () => ({
  type: ActionType.NEWS_CATEGORY_1_REQUEST,
});

export const newsCategory1SuccessUK = response => ({
  type: ActionType.NEWS_CATEGORY_1_SUCCESS_UK,
  payload: response,
});

export const newsCategory1SuccessRU = response => ({
  type: ActionType.NEWS_CATEGORY_1_SUCCESS_RU,
  payload: response,
});

export const newsCategory1ErrorUK = error => ({
  type: ActionType.NEWS_CATEGORY_1_ERROR_UK,
  payload: error,
});

export const newsCategory1ErrorRU = error => ({
  type: ActionType.NEWS_CATEGORY_1_ERROR_RU,
  payload: error,
});

export const incrmentNewsCategory1PageNumberUK = () => ({
  type: ActionType.NEWS_CATEGORY_1_PAGE_NUMBER_UK,
});

export const incrmentNewsCategory1PageNumberRU = () => ({
  type: ActionType.NEWS_CATEGORY_1_PAGE_NUMBER_RU,
});
