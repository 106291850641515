export const ActionType = {
  NEWS_CATEGORY_2_REQUEST: 'NEWS_CATEGORY_2_REQUEST',
  NEWS_CATEGORY_2_SUCCESS_UK: 'NEWS_CATEGORY_2_SUCCESS_UK',
  NEWS_CATEGORY_2_SUCCESS_RU: 'NEWS_CATEGORY_2_SUCCESS_RU',
  NEWS_CATEGORY_2_ERROR_UK: 'NEWS_CATEGORY_2_ERROR_UK',
  NEWS_CATEGORY_2_ERROR_RU: 'NEWS_CATEGORY_2_ERROR_RU',
  NEWS_CATEGORY_2_PAGE_NUMBER_UK: 'NEWS_CATEGORY_2_NUMBER_UK',
  NEWS_CATEGORY_2_PAGE_NUMBER_RU: 'NEWS_CATEGORY_2_NUMBER_RU',
};

export const newsCategory2Request = () => ({
  type: ActionType.NEWS_CATEGORY_2_REQUEST,
});

export const newsCategory2SuccessUK = response => ({
  type: ActionType.NEWS_CATEGORY_2_SUCCESS_UK,
  payload: response,
});

export const newsCategory2SuccessRU = response => ({
  type: ActionType.NEWS_CATEGORY_2_SUCCESS_RU,
  payload: response,
});

export const newsCategory2ErrorUK = error => ({
  type: ActionType.NEWS_CATEGORY_2_ERROR_UK,
  payload: error,
});

export const newsCategory2ErrorRU = error => ({
  type: ActionType.NEWS_CATEGORY_2_ERROR_RU,
  payload: error,
});

export const incrmentNewsCategory2PageNumberUK = () => ({
  type: ActionType.NEWS_CATEGORY_2_PAGE_NUMBER_UK,
});

export const incrmentNewsCategory2PageNumberRU = () => ({
  type: ActionType.NEWS_CATEGORY_2_PAGE_NUMBER_RU,
});
