import React from 'react';
import { Dialog, Slide, DialogContent, DialogTitle } from '@material-ui/core';
import style from './modalMenu.module.css';
import './resetGlobals.css';
import MenuMob from './menuLangMob/menuMob';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ModalMenu = ({ handleClose, open }) => {
  return (
    <Dialog
      TransitionComponent={Transition}
      fullScreen
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      className="modalMobile"
    >
      <DialogTitle>
        <div className="wrapper">
          <div className={style.close} onClick={handleClose}>
            <div className={style.closeLine} />
            <div className={style.closeLine} />
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="wrapper">
          <MenuMob handleClose={handleClose} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ModalMenu;
