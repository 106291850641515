import axios from 'axios';
import {
  newsCategory4Request,
  newsCategory4SuccessUK,
  newsCategory4SuccessRU,
  newsCategory4ErrorUK,
  newsCategory4ErrorRU,
  incrmentNewsCategory4PageNumberUK,
  incrmentNewsCategory4PageNumberRU,
} from './newsCategory4Actions';
import { locale } from '../../session/sessionSelectors';
import baseURL from '../../baseURL';

import {
  newsCategory4PageNumberUK,
  newsCategory4PageNumberRU,
} from './newsCategory4Selectors';

const setAxiosLang = lang => {
  axios.defaults.headers.common['Accept-Language'] = lang;
};
// eslint-disable-next-line import/prefer-default-export
export const newsCategory4Operation = () => (dispatch, getState) => {
  axios.defaults.baseURL = `${baseURL}`;
  dispatch(newsCategory4Request());

  const page =
    locale(getState()) === 'uk'
      ? newsCategory4PageNumberUK(getState())
      : newsCategory4PageNumberRU(getState());

  setAxiosLang(locale(getState()));

  axios
    .get(`news?categoriesId=4&limit=12&page=${page}`)
    .then(response => {
      if (locale(getState()) === 'uk')
        try {
          if (response.data.zviti.length > 0 && response.data.zviti[0].id) {
            dispatch(incrmentNewsCategory4PageNumberUK());
            dispatch(newsCategory4SuccessUK(response.data.zviti));
          } else {
            dispatch(incrmentNewsCategory4PageNumberUK());
            dispatch(newsCategory4SuccessUK(null));
          }
        } catch (error) {
          dispatch(newsCategory4ErrorUK(error));
        }
      else if (locale(getState()) === 'ru') {
        try {
          if (response.data.zviti.length > 0 && response.data.zviti[0].id) {
            dispatch(incrmentNewsCategory4PageNumberRU());
            dispatch(newsCategory4SuccessRU(response.data.zviti));
          } else {
            dispatch(incrmentNewsCategory4PageNumberRU());
            dispatch(newsCategory4SuccessRU(null));
          }
        } catch (error) {
          dispatch(newsCategory4ErrorRU(error));
        }
      }
    })
    .catch(error => {
      console.log(error);
      if (locale(getState()) === 'uk') dispatch(newsCategory4ErrorUK(error));
      else {
        dispatch(newsCategory4ErrorRU(error));
      }
    });
};
