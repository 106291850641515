import React from 'react';
import Media from 'react-media';
import BottomHeaderMob from './bottomHeaderMob/bottomHeaderMob';
import BottomHeaderDesk from './bottomHeaderDesk/bottomHeaderDesk';
import style from './bottomHeader.module.css';

const BottomHeader = ({ header }) => (
  <div className={style.backgroundWrapper}>
    <div className="wrapper">
      <Media query={{ maxWidth: 1199 }}>
        {matches =>
          matches ? (
            <BottomHeaderMob {...header} />
          ) : (
            <BottomHeaderDesk {...header} />
          )
        }
      </Media>
    </div>
  </div>
);

export default BottomHeader;
