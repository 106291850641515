import axios from 'axios';
import {
  initDataRequest,
  initDataSuccessUK,
  initDataSuccessRU,
  initDataVidzhetySuccessUK,
  initDataVidzhetySuccessRU,
  initDataErrorUK,
  initDataErrorRU,
} from './initDataActions';
import { locale } from '../session/sessionSelectors';
import baseURL from '../baseURL';

const setAxiosLang = lang => {
  axios.defaults.headers.common['Accept-Language'] = lang;
};

// eslint-disable-next-line import/prefer-default-export
export const onInitDataOperation = () => (dispatch, getState) => {
  axios.defaults.baseURL = `${baseURL}`;
  dispatch(initDataRequest());

  setAxiosLang(locale(getState()));

  axios
    .get('menu')
    .then(response => {
      if (locale(getState()) === 'uk') {
        try {
          dispatch(initDataVidzhetySuccessUK(response.data.vidzhiti));
          dispatch(initDataSuccessUK(response.data.verkhne));
        } catch (error) {
          dispatch(initDataErrorUK(error));
        }
      } else {
        try {
          dispatch(initDataVidzhetySuccessRU(response.data.vidzhiti));
          dispatch(initDataSuccessRU(response.data.verkhne));
        } catch (error) {
          dispatch(initDataErrorRU(error));
        }
      }
    })
    .catch(error => {
      console.log(error);
      if (locale(getState()) === 'uk') dispatch(initDataErrorUK(error));
      else {
        dispatch(initDataErrorRU(error));
      }
    });
};
