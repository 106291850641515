import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import style from './newsListMob.module.css';
import * as sessionSelectors from '../../../redux/session/sessionSelectors';

const length = someString => {
  try {
    if (someString.length <= 45) return someString;
    return `${someString.slice(0, 42)}...`;
  } catch (error) {}
};

const NewsListMob = ({ news = [], locale }) => (
  <Fragment>
    {news.length > 0 && Array.isArray(news) && (
      <ul className={style.listMob}>
        {news.map(article => (
          <li className={style.listItem} key={article.id}>
            <div className={style.imgWrapper}>
              <picture>
                <source srcSet={article.icon} type="image/webp" />
                <source srcSet={article.icon} type="image/jp2" />
                <source srcSet={article.icon} type="image/jpg" />
                <img className={style.img} src={article.icon} alt="news img" />
              </picture>
            </div>
            <div className={style.textArticle}>
              <h3 className={style.title}>{length(article.title)}</h3>
              <p className={style.text}>{article.brief}</p>
            </div>
            <Link className={style.link} to={`/${locale}${article.url}`} />
            <div className={style.date}>{article.published}</div>
          </li>
        ))}
      </ul>
    )}
    {!news.length && (
      <h2 className={style.error}>Not found news by this category</h2>
    )}
  </Fragment>
);

NewsListMob.defaultProps = {
  news: [
    {
      icon: '',
      id: 1,
      brief: 'Article about',
      url: ' ',
      published: '01.01.2019',
    },
  ],
};

NewsListMob.propTypes = {
  news: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      id: PropTypes.number,
      brief: PropTypes.string,
      url: PropTypes.string,
      published: PropTypes.string,
    }),
  ),
};

const mapStateToProps = state => ({
  locale: sessionSelectors.locale(state),
});

export default connect(mapStateToProps)(NewsListMob);
