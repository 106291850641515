export const ActionType = {
  NEWS_CATEGORY_3_REQUEST: 'NEWS_CATEGORY_3_REQUEST',
  NEWS_CATEGORY_3_SUCCESS_UK: 'NEWS_CATEGORY_3_SUCCESS_UK',
  NEWS_CATEGORY_3_SUCCESS_RU: 'NEWS_CATEGORY_3_SUCCESS_RU',
  NEWS_CATEGORY_3_ERROR_UK: 'NEWS_CATEGORY_3_ERROR_UK',
  NEWS_CATEGORY_3_ERROR_RU: 'NEWS_CATEGORY_3_ERROR_RU',
  NEWS_CATEGORY_3_PAGE_NUMBER_UK: 'NEWS_CATEGORY_3_NUMBER_UK',
  NEWS_CATEGORY_3_PAGE_NUMBER_RU: 'NEWS_CATEGORY_3_NUMBER_RU',
};

export const newsCategory3Request = () => ({
  type: ActionType.NEWS_CATEGORY_3_REQUEST,
});

export const newsCategory3SuccessUK = response => ({
  type: ActionType.NEWS_CATEGORY_3_SUCCESS_UK,
  payload: response,
});

export const newsCategory3SuccessRU = response => ({
  type: ActionType.NEWS_CATEGORY_3_SUCCESS_RU,
  payload: response,
});

export const newsCategory3ErrorUK = error => ({
  type: ActionType.NEWS_CATEGORY_3_ERROR_UK,
  payload: error,
});

export const newsCategory3ErrorRU = error => ({
  type: ActionType.NEWS_CATEGORY_3_ERROR_RU,
  payload: error,
});

export const incrmentNewsCategory3PageNumberUK = () => ({
  type: ActionType.NEWS_CATEGORY_3_PAGE_NUMBER_UK,
});

export const incrmentNewsCategory3PageNumberRU = () => ({
  type: ActionType.NEWS_CATEGORY_3_PAGE_NUMBER_RU,
});
