import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import style from './appeal.module.css';
import { resetTranslitiration } from '../../../redux/translitiration/translitirationActions';
import * as sessionSelectors from '../../../redux/session/sessionSelectors';
import headImg from '../../../assets/img/icons/appealHead.svg';
import baseURL from '../../../redux/baseURL';
import SinglePublicationSideNav from "../singlePublicationSideNav/singlePublicationSideNav";

const regExpMail =
  '^((([0-9A-Za-z]{1}[-0-9A-z.]{1,}[0-9A-Za-z]{1})|([0-9А-Яа-я]{1}[-0-9А-я.]{1,}[0-9А-Яа-я]{1}))@([-A-Za-z]{1,}.){1,2}[-A-Za-z]{2,})$';

const regExpPhone =
  '38(050|066|095|099|063|073|093|067|068|096|097|098|091|092|094|039|031|032|033|034|035|036|038|041|043|044|045|046)[0-9]{7}$';

const CssTextField = withStyles({
  root: {
    fontFamily: 'Open sans',
    fontSize: 16,
    lineHeight: 22,
    color: '#162E41',
    marginBottom: '10px',
    '& .MuiInput-input': {
      backgroundRepeat: 'no-repeat',
      color: '#162E41',
      padding: '6px 10px 7px 10px',
    },
    '&:nth-child(5)': {
      marginBottom: 30,
    },
    '& label': {
      fontSize: 12,
      color: '#162E41',
      marginLeft: 10,
      whiteSpace: 'nowrap',
    },
    '& label.Mui-focused': {
      color: '#162E41',
    },
    '&:hover .MuiInput-underline:before': {
      borderBottomColor: '#315F8F',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#315F8F',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#315F8F',
      },
    },
    '& .MuiInput-underline::before': {
      borderBottom: '1px solid #315F8F',
    },
  },
})(TextField);

class Stub extends Component {
  state = {
    nameState: '',
    addressState: '',
    telState: '380',
    mailState: '',
    appealState: '',
    loading: false,
    successState: null,
    errorState: null,
    selectedFiles: [],
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.props.resetTranslitiration();
  };

  handleChange = ({ target }) => {
    const { name, value } = target;

    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const data = new FormData();
    for (let x = 0; x < this.state.selectedFiles.length; x += 1) {
      data.append(`files${x}`, this.state.selectedFiles[x]);
    }
    data.append('fullName', this.state.nameState);
    data.append('address', this.state.addressState);
    data.append('phone', this.state.telState);
    data.append('email', this.state.mailState);
    data.append('message', this.state.appealState);
    this.setState({ loading: true });
    axios({
      method: 'post',
      url: `${baseURL}internet-reception`,
      data,
    })
      .then(response => {
        this.setState({
          nameState: '',
          addressState: '',
          telState: '380',
          mailState: '',
          appealState: '',
          loading: false,
          successState: response.data,
          errorState: false,
          selectedFiles: [],
        });
      })
      .catch(error => {
        try {
          if (error.response && error.response.data) {
            this.setState({
              nameState: '',
              addressState: '',
              telState: '380',
              mailState: '',
              appealState: '',
              loading: false,
              successState: null,
              errorState: error.response.data,
              selectedFiles: [],
            });
          } else {
            this.setState({
              nameState: '',
              addressState: '',
              telState: '380',
              mailState: '',
              appealState: '',
              loading: false,
              successState: null,
              errorState: this.props.appeal.error,
              selectedFiles: [],
            });
          }
        } catch (errorCatch) {
          console.log(errorCatch);
        }
        console.log(error);
      })
      .finally(this.setState({ loading: false }));
  };

  onClickStatus = () => {
    this.setState({
      successState: null,
      errorState: null,
    });
  };

  getUploadParams = ({ meta }) => {
    return { url: 'https://httpbin.org/post' };
  };

  handleChangeStatus = ({ meta, file }, status) => {
    if (status === 'done')
      this.setState(prevState => ({
        selectedFiles: [...prevState.selectedFiles, file],
      }));

    try {
      if (status === 'removed' && file)
        this.setState(prevState => ({
          selectedFiles: prevState.selectedFiles.filter(
            el => el.name !== file.name,
          ),
        }));
    } catch (error) {}
  };

  render() {
    const { locale, appeal } = this.props;
    const {
      title,
      head,
      name,
      address,
      tel,
      email,
      appealText,
      button,
      filesQuantity,
      format,
      add,
    } = appeal;
    const {
      nameState,
      addressState,
      telState,
      mailState,
      appealState,
      loading,
      successState,
      errorState,
      selectedFiles,
    } = this.state;
    return (
      <div className="wrapper wrapper-internet-connect">
        <div className={style.appealWrapper}>
          <h1 className={style.appealName}>{head}</h1>
          <div className={style.formWrapper}>
            <h2 className={style.appealFormName}>
              <div className={style.headImgWrapper}>
                <img src={headImg} alt="icon" className={style.headImg} />
              </div>
              <span className={style.titleText}>{title}</span>
            </h2>
            {errorState && (
              <div onClick={this.onClickStatus} className={style.error}>
                {errorState}
              </div>
            )}
            {successState && (
              <div onClick={this.onClickStatus} className={style.success}>
                {successState}
              </div>
            )}
            <form
              onSubmit={this.handleSubmit}
              onClick={this.onClickStatus}
              className={style.form}
            >
              <CssTextField
                onChange={this.handleChange}
                name="nameState"
                value={nameState}
                type="text"
                id="standard-input"
                label={name}
                inputProps={{ minLength: '3', maxLength: '100' }}
                required
              />
              <CssTextField
                onChange={this.handleChange}
                name="addressState"
                value={addressState}
                type="text"
                id="standard-input"
                label={address}
              />
              <CssTextField
                onChange={this.handleChange}
                name="telState"
                value={telState}
                type="text"
                id="standard-input"
                label={tel}
                required
                inputProps={{
                  pattern: regExpPhone,
                }}
              />
              <CssTextField
                onChange={this.handleChange}
                name="mailState"
                value={mailState}
                type="email"
                id="standard-input"
                label={email}
                required
                inputProps={{
                  pattern: regExpMail,
                }}
              />
              <CssTextField
                onChange={this.handleChange}
                name="appealState"
                value={appealState}
                type="text"
                id="standard-input"
                label={appealText}
                required
                multiline
                rows="4"
                inputProps={{ minLength: '15', maxLength: '1500' }}
              />
              {!successState && !errorState && (
                <Dropzone
                  getUploadParams={this.getUploadParams}
                  onChangeStatus={this.handleChangeStatus}
                  maxFiles={5}
                  maxSizeBytes={5242880}
                  accept="image/png,image/jpg,image/jpeg,application/pdf"
                  inputContent={format}
                  inputWithFilesContent={add}
                  submitButtonContent={button}
                  styles={{
                    dropzone: {
                      marginBottom: 30,
                      overflowX: 'hidden',
                      border: 'none',
                      borderRadius: 0,
                      minHeight: 46,
                      overflowY: 'auto',
                    },
                    inputLabel: {
                      color: '#FBFBFB',
                      fontSize: 16,
                      fontWeight: 'normal',
                      padding: '5px 25px',
                      background: 'rgba(22, 46, 65, 0.8)',
                      justifySelf: 'center',
                    },
                    inputLabelWithFiles: {
                      order: 1,
                      alignSelf: 'center',
                      textTransform: 'uppercase',
                      color: '#FBFBFB',
                      borderRadius: 0,
                      background: 'rgba(22, 46, 65, 0.8)',
                      width: '100%',
                      fontFamily: 'Open Sans',
                      fontWeight: 'normal',
                    },
                    preview: {
                      order: 2,
                    },
                    submitButtonContainer: {
                      position: 'absolute',
                      margin: 0,
                      bottom: 0,
                      cursor: 'pointer',
                      zIndex: 999,
                    },
                    submitButton: {
                      borderRadius: 0,
                    },
                  }}
                />
              )}

              <button className={style.submit} type="submit">
                {button}
              </button>
            </form>
          </div>
        </div>
        <SinglePublicationSideNav/>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  locale: sessionSelectors.locale(state),
});

const mapDispatchToProps = {
  resetTranslitiration,
};

export default connect(mapStateToProps, mapDispatchToProps)(Stub);
