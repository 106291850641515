import React, { Component, Fragment } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Localization from '../../context/localization'
import * as sessionSelectors from '../../redux/session/sessionSelectors'
import Header from '../../components/header/header'
import IndexPage from '../indexPage/indexPage'
import Footer from '../../components/footer/footer'
import NewsCategoriesPage from '../newsCategoriesPage/newsCategoriesPage'
import * as newsNavSelectors from '../../redux/news/newsNav/newsNavSelectors'
import SinglePageArticle from '../singleArticle/singleArticle'
import Debtors from '../debtorsPage/debtorsPage'
import InProgress from '../errorPage/errorPage'
import SingleArticle from '../singlePublication/singlePublication'
import Appeal from '../singlePublication/appeal/appeal'

class StartPage extends Component {
  state = {}

  render() {
    const {
      header,
      localizesObj,
      viber,
      office,
      viberMob,
      newsIndex,
      routes,
      map,
      footer,
      newsContent,
      singleNews,
      error,
      appeal,
    } = this.props
    const { newsNavUK = [], newsNavRU = [], locale } = localizesObj
    const { newsStartPage } = routes
    return (
      <Fragment>
        <Header header={header} />
        <main>
          <Switch>
            <Route
              exact
              path={['/uk/', '/ru/']}
              render={() => (
                <IndexPage
                  viber={viber}
                  office={office}
                  viberMob={viberMob}
                  newsIndex={newsIndex}
                  routes={routes}
                  map={map}
                />
              )}
            />
            {newsNavUK &&
              Array.isArray(newsNavUK) &&
              newsNavUK.map((el) => (
                <Route
                  key={el.id}
                  path={`/${locale}/${newsStartPage}/${el.url}/:id`}
                  render={() => (
                    <SinglePageArticle routes={routes} {...singleNews} />
                  )}
                />
              ))}
            {newsNavRU &&
              Array.isArray(newsNavRU) &&
              newsNavRU.map((el) => (
                <Route
                  key={el.id}
                  path={`/${locale}/${newsStartPage}/${el.url}/:id`}
                  render={() => (
                    <SinglePageArticle routes={routes} {...singleNews} />
                  )}
                />
              ))}
            <Route
              exact
              path={[
                `/${locale}/categorii`,
                `/${locale}/categorii/proekti`,
                `/${locale}/categorii/tcikave`,
                `/${locale}/categorii/realizatciia-proektiv`,
                `/${locale}/categorii/publichna-informatciia`,
                `/${locale}/categorii/zviti`,
                `/${locale}/categorii/novini`,
                `/${locale}/categorii/remonti`,
                `/${locale}/categorii/date/:date`,
              ]}
              render={() => (
                <NewsCategoriesPage routes={routes} newsContent={newsContent} />
              )}
            />
            <Route
              path={`/${locale}/info/vidzheti/internet-priymalnia`}
              render={() => <Appeal appeal={appeal} />}
            />
            <Route
              path={`/${locale}/info/dlia-naselennia/borzhniki-mkp-mikolayivvodokanal`}
              render={() => <Debtors />}
            />
            <Route path={`/${locale}/debtors`} render={() => <Debtors />} />
            <Route
              path={`/${locale}/info/:publication`}
              render={() => <SingleArticle {...singleNews} />}
            />
            <Route render={() => <InProgress error={error} />} />
          </Switch>
        </main>
        <Footer {...footer} />
      </Fragment>
    )
  }
}

StartPage.defaultProps = {
  newsNavUK: [
    {
      id: '1',
      url: '',
    },
  ],
  newsNavRU: [
    {
      id: '1',
      url: '',
    },
  ],
}

StartPage.propTypes = {
  newsNavUK: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  newsNavRU: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
    })
  ),
}

const mapStateToProps = (state) => ({
  locale: sessionSelectors.locale(state),
  newsNavUK: newsNavSelectors.newsNavUK(state),
  newsNavRU: newsNavSelectors.newsNavRU(state),
})

function LocalizedStartPage(localizesObj) {
  return (
    <Localization.Consumer>
      {({ localizedContent, currentLocale }) => (
        <StartPage
          mapStateToProps
          localizesObj={localizesObj}
          {...localizedContent}
          currentLocale={currentLocale}
        />
      )}
    </Localization.Consumer>
  )
}

export default connect(mapStateToProps)(LocalizedStartPage)
