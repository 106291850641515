import { combineReducers } from 'redux';
import { ActionType } from './initDataActions';

const loading = (state = false, { type }) => {
  switch (type) {
    case ActionType.INIT_DATA_REQUEST:
      return true;

    case ActionType.INIT_DATA_MODAL_SUCCESS_UK:
    case ActionType.INIT_DATA_MODAL_SUCCESS_RU:
    case ActionType.INIT_DATA_ERROR_UK:
    case ActionType.INIT_DATA_ERROR_RU:
      return false;

    default:
      return state;
  }
};

const initDataModalUK = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.INIT_DATA_MODAL_SUCCESS_UK:
      return payload;

    default:
      return state;
  }
};

const initDataModalRU = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.INIT_DATA_MODAL_SUCCESS_RU:
      return payload;

    default:
      return state;
  }
};

const initDataVidzhetyUK = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.INIT_DATA_VIDZHETY_SUCCESS_UK:
      return payload;

    default:
      return state;
  }
};

const initDataVidzhetyRU = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.INIT_DATA_VIDZHETY_SUCCESS_RU:
      return payload;

    default:
      return state;
  }
};

const initErrorUK = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.INIT_DATA_ERROR_UK:
      return payload;

    case ActionType.INIT_DATA_VIDZHETY_SUCCESS_UK:
    case ActionType.INIT_DATA_REQUEST:
      return null;

    default:
      return state;
  }
};

const initErrorRU = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.INIT_DATA_ERROR_RU:
      return payload;

    case ActionType.INIT_DATA_VIDZHETY_SUCCESS_RU:
    case ActionType.INIT_DATA_REQUEST:
      return null;

    default:
      return state;
  }
};

export default combineReducers({
  loading,
  initDataModalUK,
  initDataModalRU,
  initDataVidzhetyUK,
  initDataVidzhetyRU,
  initErrorUK,
  initErrorRU,
});
