import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import StartPage from './startPage';
import * as sessionSelectors from '../../redux/session/sessionSelectors';
import * as initData from '../../redux/initData/initDataOperations';
import * as initDataSelectors from '../../redux/initData/initDataSelectors';
import * as newsNavOperation from '../../redux/news/newsNav/newsNavOperations';
import * as newsNavSelectors from '../../redux/news/newsNav/newsNavSelectors';

class LangStartPage extends Component {
  state = {};

  componentDidMount = () => {
    const { onInitData, onInitDataNav } = this.props;
    if (
      (this.props.locale === 'uk' && !this.props.initDataModalUK) ||
      (this.props.locale === 'ru' && !this.props.initDataModalRU)
    )
      onInitData();
    if (
      (this.props.locale === 'uk' && !this.props.newsNavUK) ||
      (this.props.locale === 'ru' && !this.props.newsNavRU)
    )
      onInitDataNav();
  };

  componentDidUpdate = prevProps => {
    const { onInitData, onInitDataNav } = this.props;
    if (
      prevProps.locale !== this.props.locale &&
      this.props.locale === 'ru' &&
      !this.props.initDataModalRU
    ) {
      onInitData();
    }
    if (
      prevProps.locale !== this.props.locale &&
      this.props.locale === 'uk' &&
      !this.props.initDataModalUK
    ) {
      onInitData();
    }
    if (
      prevProps.locale !== this.props.locale &&
      this.props.locale === 'ru' &&
      !this.props.newsNavRU
    ) {
      onInitDataNav();
    }
    if (
      prevProps.locale !== this.props.locale &&
      this.props.locale === 'uk' &&
      !this.props.newsNavUK
    ) {
      onInitDataNav();
    }
  };

  render() {
    const { locale } = this.props;
    return (
      <Switch>
        <Route
          path="/"
          exact
          render={() => {
            return <Redirect to={`${locale}/`} />;
          }}
        />
        <Route path="/uk/*" render={() => <StartPage />} />
        <Route path="/ru/*" render={() => <StartPage />} />
      </Switch>
    );
  }
}

const mapDispatchToProps = {
  onInitData: initData.onInitDataOperation,
  onInitDataNav: newsNavOperation.newsNavOperation,
};

const mapStateToProps = state => ({
  locale: sessionSelectors.locale(state),
  initDataModalUK: initDataSelectors.initDataModalUK(state),
  initDataModalRU: initDataSelectors.initDataModalRU(state),
  newsNavUK: newsNavSelectors.newsNavUK(state),
  newsNavRU: newsNavSelectors.newsNavRU(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(LangStartPage);
