import axios from 'axios';
import {
  newsNavRequest,
  newsNavSuccessUK,
  newsNavSuccessRU,
  newsNavErrorUK,
  newsNavErrorRU,
} from './newsNavActions';
import { locale } from '../../session/sessionSelectors';
import baseURL from '../../baseURL';

const setAxiosLang = lang => {
  axios.defaults.headers.common['Accept-Language'] = lang;
};

// eslint-disable-next-line import/prefer-default-export
export const newsNavOperation = () => (dispatch, getState) => {
  axios.defaults.baseURL = `${baseURL}`;
  dispatch(newsNavRequest());

  setAxiosLang(locale(getState()));

  axios
    .get('news-categories')
    .then(response => {
      if (locale(getState()) === 'uk') {
        try {
          dispatch(newsNavSuccessUK(response.data));
        } catch (error) {
          dispatch(newsNavErrorUK(error));
        }
      } else {
        try {
          dispatch(newsNavSuccessRU(response.data));
        } catch (error) {
          dispatch(newsNavErrorRU(error));
        }
      }
    })
    .catch(error => {
      console.log(error);
      if (locale(getState()) === 'uk') dispatch(newsNavErrorUK(error));
      else {
        dispatch(newsNavErrorRU(error));
      }
    });
};
