import React, { Component } from "react"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import Media from "react-media"
import style from "./singlePublication.module.css"
import * as sessionSelectors from "../../redux/session/sessionSelectors"
import * as publicationSelectors from "../../redux/publication/publicationSelectors"
import * as publicationOperation from "../../redux/publication/publicationOperations"
import { resetTranslitiration } from "../../redux/translitiration/translitirationActions"
import { resetPublication } from "../../redux/publication/publicationActions"
import SideNavPublication from "./singlePublicationSideNav/singlePublicationSideNav"

function createMarkup(compiled) {
  return { __html: compiled }
}

class SingleArticle extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0)
    const { onInitData, resetTranslitiration, resetPublication } = this.props
    resetTranslitiration()
    resetPublication()
    onInitData(this.props.location.pathname.slice(9))
  }

  componentDidUpdate = (prevProps) => {
    const { onInitData, resetTranslitiration, resetPublication } = this.props
    if (prevProps.location.pathname !== this.props.location.pathname) {
      resetTranslitiration()
      resetPublication()
      onInitData(this.props.location.pathname.slice(9))
    }
  }

  render() {
    const {
      publicationRU,
      publicationUK,
      locale,
      // publicationErrorRU,
      // publicationErrorUK,
    } = this.props
    const publication = locale === "uk" ? publicationUK : publicationRU
    // const errorPublication =
    //   locale === 'uk' ? publicationErrorUK : publicationErrorRU;

    return (
      <div className="wrapper">
        <div className={style.newsWrapper}>
          <div className={style.articleTitle}>
            {publication && (
              <h1 className={style.articleTitleH1}>{publication.title}</h1>
            )}
          </div>
          <div className={style.grid}>
            <div>
              {publication && (
                <div className={style.borderTop}>
                  {publication.icon && (
                    <picture>
                      <source srcSet={publication.icon} type="image/webp" />
                      <source srcSet={publication.icon} type="image/jp2" />
                      <source srcSet={publication.icon} type="image/jpg" />
                      <img
                        className={style.imgFront}
                        src={publication.icon}
                        alt="publication img"
                      />
                    </picture>
                  )}
                  <div
                    className={style.singleArticle}
                    dangerouslySetInnerHTML={createMarkup(publication.compiled)}
                  />
                </div>
              )}
            </div>
            <Media query={{ minWidth: 1200 }}>
              {(matches) =>
                matches && (
                  <div className={style.sideNews}>
                    <SideNavPublication />
                  </div>
                )
              }
            </Media>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  locale: sessionSelectors.locale(state),
  publicationRU: publicationSelectors.publicationRU(state),
  publicationUK: publicationSelectors.publicationUK(state),
  publicationErrorRU: publicationSelectors.publicationErrorRU(state),
  publicationErrorUK: publicationSelectors.publicationErrorUK(state),
})

const mapDispatchToProps = {
  resetTranslitiration,
  onInitData: publicationOperation.publicationOperation,
  resetPublication,
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SingleArticle)
)
