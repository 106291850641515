export const ActionType = {
  MAP_REQUEST: 'MAP_REQUEST',
  MAP_SUCCESS: 'MAP_SUCCESS',
  MAP_ERROR: 'MAP_ERROR',
};

export const mapRequest = () => ({
  type: ActionType.MAP_REQUEST,
});

export const mapSuccess = response => ({
  type: ActionType.MAP_SUCCESS,
  payload: response,
});

export const mapError = error => ({
  type: ActionType.MAP_ERROR,
  payload: error,
});
