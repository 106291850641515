import { combineReducers } from 'redux';
import { ActionType } from './translitirationActions';

const writeRranslitiration = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.TRANSLITIRATION:
      return payload;

    case ActionType.RESET_TRANSLITIRATION:
      return null;

    default:
      return state;
  }
};

const writeRranslitirationError = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.TRANSLITIRATION_ERROR:
      return payload;

    case ActionType.RESET_TRANSLITIRATION:
      return null;

    default:
      return state;
  }
};

export default combineReducers({
  writeRranslitiration,
  writeRranslitirationError,
});
