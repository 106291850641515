import axios from 'axios';
import {
  publicationRequest,
  publicationSuccessUK,
  publicationSuccessRU,
  publicationErrorUK,
  publicationErrorRU,
} from './publicationActions';
import { locale } from '../session/sessionSelectors';
import { translitiration } from '../translitiration/translitirationActions';
import baseURL from '../baseURL';

const setAxiosLang = lang => {
  axios.defaults.headers.common['Accept-Language'] = lang;
};

// eslint-disable-next-line import/prefer-default-export
export const publicationOperation = url => (dispatch, getState) => {
  axios.defaults.baseURL = `${baseURL}info/`;
  dispatch(publicationRequest());

  setAxiosLang(locale(getState()));

  axios
    .get(`${url}`)
    .then(response => {
      if (locale(getState()) === 'uk') {
        try {
          dispatch(publicationSuccessUK(response.data));
          dispatch(translitiration(response.data.url.ru.url));
        } catch (error) {
          dispatch(publicationErrorUK(error));
        }
      } else {
        try {
          dispatch(publicationSuccessRU(response.data));
          dispatch(translitiration(response.data.url.uk.url));
        } catch (error) {
          dispatch(publicationErrorRU(error));
        }
      }
    })
    .catch(error => {
      console.log(error);
      if (locale(getState()) === 'uk') dispatch(publicationErrorUK(error));
      else {
        dispatch(publicationErrorRU(error));
      }
    });
};
