import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import nickLogo from '../../../../assets/img/logo/logoNik.svg';
import style from './bottomHeaderDesk.module.css';
import * as sessionSelectors from '../../../../redux/session/sessionSelectors';

const BottomHeaderDesk = ({
  titleNick,
  titlename,
  waterWeb,
  waterWebTel,
  canalization,
  canalizationtel1,
  canalizationtel2,
  abon,
  abonTel1,
  abonTel2,
  abonTel3,
  abonTel4,
  abonMulti,
  trust,
  trustTel,
  locale,
  waterWebTelAdd,
  canalizationtel1Add,
  canalizationtel2Add,

}) => (
  <div className={style.wrapper}>
    <div className={style.logoBlock}>
      <Link className={style.link} to={`/${locale}/`}>
        <img className={style.logo} src={nickLogo} alt="nikolaevLogo" />
        <span className={style.logoTextWrapper}>
          <span className={style.logoTitle}>{titleNick}</span>
          <span className={style.logoName}>&ldquo;{titlename}&rdquo;</span>
        </span>
      </Link>
    </div>
    <div className={style.line} />
    <div className={style.telsBlock}>
      <h3 className={style.service}>{waterWeb}</h3>
      <p className={style.serviceTel}>
        {waterWebTel}
        {` `}
        <span className={style.smallText}>{waterWebTelAdd}</span>
      </p>
    </div>
    <div className={style.line} />
    <div className={style.telsBlock}>
      <h3 className={style.service}>{canalization}</h3>
      <p className={style.serviceTel}>
        {canalizationtel1}
        {` `}
        <span className={style.smallText}>{canalizationtel1Add}</span>
      </p>
      <p className={style.serviceTel}>
        {canalizationtel2}
        {` `}
        <span className={style.smallText}>{canalizationtel2Add}</span>
      </p>
    </div>
    <div className={style.line} />
    <div className={style.telsBlock}>
      <h3 className={style.service}>{abon}</h3>
     <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
       <div>
         {abonMulti}
         <p className={style.serviceTel}>{abonTel1}, {abonTel2}</p>

       </div>
       <div >
         {/*style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}*/}
         {/*<span className={style.smallText}>{abonTel3}, </span>*/}
         <span className={style.smallText}>{abonTel4}</span>
       </div>
     </div>

    </div>
    <div className={style.line} />
    <div className={style.telsBlock}>
      <h3 className={style.service}>{trust}</h3>
      <p className={style.serviceTel}>{trustTel}</p>
    </div>
  </div>
);

const mapStateToProps = state => ({
  locale: sessionSelectors.locale(state),
});

export default connect(mapStateToProps)(BottomHeaderDesk);
