import React, { Component, Fragment } from 'react';
import Media from 'react-media';
import { Motion, spring } from 'react-motion';
import SizeReporter from 'react-size-reporter';
import LocaleSelector from '../../localSelector/localeSelector';
import style from './topHeader.module.css';
import ModalMenu from './modalMenu/modalMenu';
import MenuDesk from './menuDesk/menuDesk';

export default class TopHeader extends Component {
  state = {
    open: false,
    openTels: false,
    height: 0,
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  onOpenModalTels = () => {
    this.setState(prevState => ({ openTels: !prevState.openTels }));
  };

  handleSizeChange = ({ height }) => {
    this.setState({
      height,
    });
  };

  render() {
    const { open, openTels, height } = this.state;
    const {
      waterWeb,
      waterWebTel,
      canalization,
      canalizationtel1,
      canalizationtel2,
      abon,
      abonTel1,
      abonTel2,
      abonTel3,
      abonTel4,
      trust,
      trustTel,
      waterWebTelAdd,
      canalizationtel1Add,
      canalizationtel2Add,
    } = this.props;
    return (
      <div className={style.wrapper}>
        <div className="wrapper">
          <div className={style.flexWrapper}>
            <Media query={{ maxWidth: 1199 }}>
              {matches =>
                matches ? (
                  <Fragment>
                    <div onClick={this.onOpenModal} className={style.burger}>
                      <div className={style.burgerLine} />
                      <div className={style.burgerLine} />
                      <div className={style.burgerLine} />
                    </div>
                    <span
                      className={style.spanWrapper}
                      onClick={this.onOpenModalTels}
                    >
                      <svg
                        className={
                          !openTels ? style.imgSupport : style.imgSupportOpen
                        }
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g>
                          <path d="M22.2178 16.8713C21.0917 17.9988 19.3328 19.7596 17.8599 21.2428C16.1092 20.0967 14.5267 18.8322 13.2541 17.5596C11.6826 15.9881 9.71742 13.7505 8.65541 12.1655C10.0038 10.8268 11.7671 9.06578 13.0609 7.77306L5.28787 0L1.40947 3.80859L1.40375 3.81432C0.318392 4.89967 -0.141203 6.38123 0.0377829 8.21777C0.610216 14.0904 7.79161 22.5968 14.1399 26.8572C17.8153 29.3239 23.0981 31.6072 26.2228 28.4823C28.4915 26.2138 29.3587 25.2896 29.3946 25.2514L29.9764 24.6302L22.2178 16.8713ZM24.979 27.2385C22.6135 29.6038 17.9224 27.2772 15.12 25.3965C9.22899 21.4428 2.30509 13.3454 1.7885 8.04703C1.66307 6.76117 1.95124 5.75638 2.64475 5.06104L5.27666 2.4765L10.5723 7.77237C7.8866 10.4539 7.0775 11.2532 6.43411 11.8902L6.77538 12.4784C7.81175 14.2637 10.2155 17.0087 12.0104 18.8036C13.9792 20.7724 16.0002 22.1624 18.1135 23.4851L18.6086 22.9855C19.6948 21.8893 21.2734 20.3066 22.2185 19.36L27.5215 24.6629C27.0303 25.1699 26.2097 26.0076 24.979 27.2385Z" />
                        </g>
                      </svg>
                    </span>
                    <ModalMenu open={open} handleClose={this.onCloseModal} />
                  </Fragment>
                ) : (
                  <div>
                    <MenuDesk />
                  </div>
                )
              }
            </Media>

            {/* <LocaleSelector /> */}
          </div>
          <Media query={{ maxWidth: 1199 }}>
            {matches =>
              matches && (
                <Motion style={{ height: spring(!openTels ? 0 : height) }}>
                  {interpolatedStyle => (
                    <div
                      className={style.heightWrapper}
                      style={{
                        height: interpolatedStyle.height,
                      }}
                    >
                      <SizeReporter onSizeChange={this.handleSizeChange}>
                        <div className={style.heightZero}>
                          <div className={style.telsBlock}>
                            <h3 className={style.service}>{waterWeb}</h3>
                            <p className={style.serviceTel}>
                              {waterWebTel}
                              {` `}
                              <span className={style.smallText}>
                                {waterWebTelAdd}
                              </span>
                            </p>
                          </div>
                          <div className={style.telsBlock}>
                            <h3 className={style.service}>{canalization}</h3>
                            <p className={style.serviceTel}>
                              {canalizationtel1}
                              {` `}
                              <span className={style.smallText}>
                                {canalizationtel1Add}
                              </span>
                            </p>
                            <p className={style.serviceTel}>
                              {canalizationtel2}
                              {` `}
                              <span className={style.smallText}>
                                {canalizationtel2Add}
                              </span>
                            </p>
                          </div>
                          <div className={style.telsBlock}>
                            <h3 className={style.service}>{abon}</h3>
                            <p className={style.serviceTel}>{abonTel1}</p>
                            <p className={style.serviceTel}>{abonTel2}</p>
                            {/*<p className={style.serviceTel}>{abonTel3}</p>*/}
                   {/*         <p className={style.serviceTel}>{abonTel4}</p>*/}
                          </div>
                          <div className={style.telsBlock}>
                            <h3 className={style.service}>{trust}</h3>
                            <p className={style.serviceTel}>{trustTel}</p>
                          </div>
                        </div>
                      </SizeReporter>
                    </div>
                  )}
                </Motion>
              )
            }
          </Media>
        </div>
      </div>
    );
  }
}
