export const ActionType = {
  INIT_DATA_REQUEST: 'INIT_DATA_REQUEST',
  INIT_DATA_MODAL_SUCCESS_UK: 'INIT_DATA_MODAL_SUCCESS_UK',
  INIT_DATA_MODAL_SUCCESS_RU: 'INIT_DATA_MODAL_SUCCESS_RU',
  INIT_DATA_VIDZHETY_SUCCESS_UK: 'INIT_DATA_VIDZHETY_SUCCESS_UK',
  INIT_DATA_VIDZHETY_SUCCESS_RU: 'INIT_DATA_VIDZHETY_SUCCESS_RU',
  INIT_DATA_ERROR_UK: 'INIT_DATA_ERROR_UK',
  INIT_DATA_ERROR_RU: 'INIT_DATA_ERROR_RU',
};

export const initDataRequest = () => ({
  type: ActionType.INIT_DATA_REQUEST,
});

export const initDataSuccessUK = response => ({
  type: ActionType.INIT_DATA_MODAL_SUCCESS_UK,
  payload: response,
});

export const initDataSuccessRU = response => ({
  type: ActionType.INIT_DATA_MODAL_SUCCESS_RU,
  payload: response,
});

export const initDataVidzhetySuccessUK = response => ({
  type: ActionType.INIT_DATA_VIDZHETY_SUCCESS_UK,
  payload: response,
});

export const initDataVidzhetySuccessRU = response => ({
  type: ActionType.INIT_DATA_VIDZHETY_SUCCESS_RU,
  payload: response,
});

export const initDataErrorUK = error => ({
  type: ActionType.INIT_DATA_ERROR_UK,
  payload: error,
});

export const initDataErrorRU = error => ({
  type: ActionType.INIT_DATA_ERROR_RU,
  payload: error,
});
