import axios from 'axios';
import {
  newsCategory3Request,
  newsCategory3SuccessUK,
  newsCategory3SuccessRU,
  newsCategory3ErrorUK,
  newsCategory3ErrorRU,
  incrmentNewsCategory3PageNumberUK,
  incrmentNewsCategory3PageNumberRU,
} from './newsCategory3Actions';
import { locale } from '../../session/sessionSelectors';
import baseURL from '../../baseURL';

import {
  newsCategory3PageNumberUK,
  newsCategory3PageNumberRU,
} from './newsCategory3Selectors';

const setAxiosLang = lang => {
  axios.defaults.headers.common['Accept-Language'] = lang;
};

// eslint-disable-next-line import/prefer-default-export
export const newsCategory3Operation = () => (dispatch, getState) => {
  axios.defaults.baseURL = `${baseURL}`;
  dispatch(newsCategory3Request());

  const page =
    locale(getState()) === 'uk'
      ? newsCategory3PageNumberUK(getState())
      : newsCategory3PageNumberRU(getState());

  setAxiosLang(locale(getState()));

  axios
    .get(`news?categoriesId=3&limit=12&page=${page}`)
    .then(response => {
      if (locale(getState()) === 'uk')
        try {
          if (
            response.data.realizatciia_proektiv.length > 0 &&
            response.data.realizatciia_proektiv[0].id
          ) {
            dispatch(incrmentNewsCategory3PageNumberUK());
            dispatch(
              newsCategory3SuccessUK(response.data.realizatciia_proektiv),
            );
          } else {
            dispatch(incrmentNewsCategory3PageNumberUK());
            dispatch(newsCategory3SuccessUK(null));
          }
        } catch (error) {
          dispatch(newsCategory3ErrorUK(error));
        }
      else if (locale(getState()) === 'ru') {
        try {
          if (
            response.data.realizatciia_proektiv.length > 0 &&
            response.data.realizatciia_proektiv[0].id
          ) {
            dispatch(incrmentNewsCategory3PageNumberRU());
            dispatch(
              newsCategory3SuccessRU(response.data.realizatciia_proektiv),
            );
          } else {
            dispatch(incrmentNewsCategory3PageNumberRU());
            dispatch(newsCategory3SuccessRU(null));
          }
        } catch (error) {
          dispatch(newsCategory3ErrorRU(error));
        }
      }
    })
    .catch(error => {
      console.log(error);
      if (locale(getState()) === 'uk') dispatch(newsCategory3ErrorUK(error));
      else {
        dispatch(newsCategory3ErrorRU(error));
      }
    });
};
