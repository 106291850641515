import React from 'react';
import { compose, withProps } from 'recompose';
import PropTypes from 'prop-types';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import coldWater from '../../assets/img/icons/icon_cold water.svg';
import canalization from '../../assets/img/icons/icon_canalisation.svg';

const iconPath = type => {
  return type === 'v' ? coldWater : canalization;
};

const MyMapComponent = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyDsZuExwaMyYtqIDfcw_iyrzml77jEX_zA&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: (
      <div
        style={{
          height: `100%`,
        }}
      />
    ),
    containerElement: (
      <div
        style={{
          height: `100%`,
        }}
      />
    ),
    mapElement: (
      <div
        style={{
          height: `100%`,
        }}
      />
    ),
  }),
  withScriptjs,
  withGoogleMap,
)(props => {
  const { currentLat, currentLng, markerList } = props;
  return (
    <GoogleMap
      defaultZoom={13}
      defaultCenter={{
        lat: 46.964691,
        lng: 31.983631,
      }}
      center={{
        lat: Number(currentLat),
        lng: Number(currentLng),
      }}
    >
      {markerList.map(marker => (
        <Marker
          key={marker.id}
          position={{
            lat: Number(marker.location.lat),
            lng: Number(marker.location.lng),
          }}
          icon={{
            url: iconPath(marker.type),
            scaledSize: new window.google.maps.Size(50, 50),
          }}
          onClick={() => props.handleClick(marker.id)}
        />
      ))}
    </GoogleMap>
  );
});

export default class MyFancyComponent extends React.PureComponent {
  state = {
    // isMarkerShown: false,
  };

  componentDidMount() {
    // this.delayedShowMarker();
  }

  // delayedShowMarker = () => {
  //   setTimeout(() => {
  //     this.setState({
  //       isMarkerShown: true,
  //     });
  //   }, 3000);
  // };

  // handleMarkerClick = () => {
  //   this.setState({
  //     isMarkerShown: false,
  //   });
  //   this.delayedShowMarker();
  // };

  render() {
    // const { isMarkerShown } = this.state;
    return (
      <MyMapComponent
        // isMarkerShown={isMarkerShown}
        // onMarkerClick={this.handleMarkerClick}
        markerList={this.props.markerList}
        handleClick={this.props.handleClick}
        currentLat={this.props.currentLat}
        currentLng={this.props.currentLng}
      />
    );
  }
}

MyFancyComponent.defaultProps = {
  markerList: [
    {
      repair_id: '',
      location: {
        lat: '46.479787',
        lng: '30.737273',
      },
    },
  ],
};

MyFancyComponent.propTypes = {
  markerList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      location: PropTypes.shape({
        lat: PropTypes.string,
        lng: PropTypes.string,
      }),
    }),
  ),
};
