import { combineReducers } from 'redux';
import { ActionType } from './singleNewsActions';

const loading = (state = false, { type }) => {
  switch (type) {
    case ActionType.SINGLE_NEWS_REQUEST:
      return true;

    case ActionType.SINGLE_NEWS_SUCCESS_UK:
    case ActionType.SINGLE_NEWS_SUCCESS_RU:
    case ActionType.SINGLE_NEWS_ERROR_UK:
    case ActionType.SINGLE_NEWS_ERROR_RU:
      return false;

    default:
      return state;
  }
};

const singleNewsUK = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.SINGLE_NEWS_SUCCESS_UK:
      return payload;

    case ActionType.RESET_SINGLE_NEWS:
      return null;

    default:
      return state;
  }
};

const singleNewsRU = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.SINGLE_NEWS_SUCCESS_RU:
      return payload;

    case ActionType.RESET_SINGLE_NEWS:
      return null;

    default:
      return state;
  }
};

const singleNewsErrorUK = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.SINGLE_NEWS_ERROR_UK:
      return payload;

    case ActionType.SINGLE_NEWS_REQUEST:
    case ActionType.SINGLE_NEWS_SUCCESS_UK:
    case ActionType.RESET_SINGLE_NEWS:
      return null;

    default:
      return state;
  }
};

const singleNewsErrorRU = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.SINGLE_NEWS_ERROR_RU:
      return payload;

    case ActionType.SINGLE_NEWS_REQUEST:
    case ActionType.SINGLE_NEWS_SUCCESS_RU:
    case ActionType.RESET_SINGLE_NEWS:
      return null;

    default:
      return state;
  }
};

export default combineReducers({
  loading,
  singleNewsUK,
  singleNewsRU,
  singleNewsErrorUK,
  singleNewsErrorRU,
});
