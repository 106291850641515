import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as sessionSelectors from '../../../../redux/session/sessionSelectors';
import nickLogo from '../../../../assets/img/logo/logoNik.svg';
import style from './bottomHeaderMob.module.css';

const BottomHeaderMob = ({ titleNick, titlename, locale }) => (
  <div className={style.wrapper}>
    <div className={style.logoBlock}>
      <Link className={style.link} to={`/${locale}/`}>
        <img className={style.logo} src={nickLogo} alt="nikolaevLogo" />
        <span className={style.logoTextWrapper}>
          <span className={style.logoTitle}>{titleNick}</span>
          <span className={style.logoName}>&ldquo;{titlename}&rdquo;</span>
        </span>
      </Link>
    </div>
  </div>
);

const mapStateToProps = state => ({
  locale: sessionSelectors.locale(state),
});

export default connect(mapStateToProps)(BottomHeaderMob);
