import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Media from 'react-media';
import style from './footer.module.css';
import logoNickImg from '../../assets/img/logo/logoNik.svg';
import * as sessionSelectors from '../../redux/session/sessionSelectors';
import contactsImg from '../../assets/img/icons/footerLocation.svg';
import telsImg from '../../assets/img/icons/footerTel.svg';
import graffikImg from '../../assets/img/icons/footerTime.svg';
import facebookIcon from '../../assets/img/icons/footerFacebook.svg';
import logoCCT from '../../assets/img/logo/LogoCCT.svg';

const Footer = ({
  titleNick,
  titleName,
  contacts,
  tels,
  schedule,
  cyber,
  titleNickMob,
  locale,
}) => (
  <footer className={style.footer}>
    <div className="wrapper">
      <div>
        <Media query={{ maxWidth: 1199 }}>
          {matches =>
            matches ? (
              <div className={style.linkWrapper}>
                <Link to={`/${locale}/`} className={style.homeLink}>
                  <img
                    className={style.logoNickImg}
                    src={logoNickImg}
                    alt="Nikolaev"
                  />
                  <span className={style.titleNickMob}>{titleNickMob}</span>
                </Link>
              </div>
            ) : (
              <div className={style.linkWrapper}>
                <Link to={`/${locale}/`} className={style.homeLink}>
                  <img
                    className={style.logoNickImg}
                    src={logoNickImg}
                    alt="Nikolaev"
                  />
                  <span className={style.textHeadWrapper}>
                    <span className={style.titleNick}>{titleNick}</span>
                    <span className={style.titleName}>{titleName}</span>
                  </span>
                </Link>
              </div>
            )
          }
        </Media>
        <div className={style.footerInfoWrapper}>
          <div className={style.infoEl}>
            <img src={contactsImg} alt="contacts" className={style.blocImg} />
            <h3 className={style.infoElHead}>{contacts.name}</h3>

            <div className={style.infoElTextBlock}>
              <p className={style.infoElText}>{contacts.tel}</p>
            </div>
            <div className={style.infoElTextBlock}>
              <p className={style.infoElText}>
                e-mail: <a href={`mailto:${contacts.mail}`}>{contacts.mail}</a>
              </p>
            </div>
            <div className={style.infoElTextBlockFacebook}>
              <h3 className={style.infoElTextFacebook}>
                <span>{contacts.join}</span>
                <a
                  className={style.facebookLink}
                  href="https://www.facebook.com/nikolaevvodokanal/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={facebookIcon}
                    alt="facebook"
                    className={style.facebookIcon}
                  />
                </a>
              </h3>
            </div>
          </div>
          <div className={style.infoEl}>
            <img src={telsImg} alt="tels" className={style.blocImg} />
            <h3 className={style.infoElHead}>{tels.name}</h3>
            <div className={style.infoElTextBlockLong}>
              <p className={style.infoElText}>{tels.manager1}</p>
              <p className={style.infoElTextTel}>
                {tels.tel1}{' '}
                <span className={style.textAddon}>{tels.addon1}</span>
              </p>
            </div>
            <div className={style.infoElTextBlockLong}>
              <p className={style.infoElText}>{tels.manager2}</p>
              <p className={style.infoElTextTel}>
                {tels.tel21}{' '}
                <span className={style.textAddon}>{tels.addon21}</span>
              </p>
              <p className={style.infoElTextTel}>
                {tels.tel22}{' '}
                <span className={style.textAddon}>{tels.addon22}</span>
              </p>
            </div>
            <div className={style.infoElTextBlockLong}>
              <p className={style.infoElText}>{tels.abon}</p>

              <p className={style.infoElTextTel}>
                {tels.abonTel1}, <span>{tels.abonTel2}</span> <span style={{fontSize: 'small'}}>{tels.abonMulti}</span>
              </p>
              {/*<p className={style.infoElTextTel}><span>{tels.abonTel4}</span></p>*/}

            </div>
            <div className={style.infoElTextBlockLong}>
              <p className={style.infoElText}>{tels.trust}</p>
              <p className={style.infoElTextTel}>{tels.trustTel}</p>
            </div>
          </div>
          <div className={style.infoEl}>
            <img src={graffikImg} alt="time" className={style.blocImg} />
            <h3 className={style.infoElHead}>{schedule.name}</h3>
            <table className={style.footerTable}>
              <tbody>
                <tr className={style.footerTableTr}>
                  <td className={style.footerTableTd}>{schedule.first}</td>
                  <td className={style.footerTableTd}>{schedule.firstTime}</td>
                </tr>
                {/*<tr className={style.footerTableTr}>
                  <td className={style.footerTableTd}>{schedule.second}</td>
                  <td className={style.footerTableTd}>{schedule.secondTime}</td>
                </tr>*/}
                <tr className={style.footerTableTr}>
                  <td className={style.footerTableTd}>{schedule.third}</td>
                  <td className={style.footerTableTd}>{schedule.thirdTime}</td>
                </tr>
                <tr className={style.footerTableTr}>
                  <td className={style.footerTableTd}>{schedule.last}</td>
                  <td className={style.footerTableTd}>{schedule.lastTime}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div className={style.cyberBack}>
      <div className="wrapper">
        <div className={style.cctWrapper}>
          <div className={style.left}>{cyber.publication}</div>
          <div className={style.right}>
            <a
              href="https://cybercreation.team/"
              target="_blank"
              rel="noopener noreferrer"
              className={style.cctImgLink}
            >
              <img src={logoCCT} alt="CCT" className={style.cctLogo} />
            </a>
            <div className={style.rightText}>
              <p className={style.rightTextInner}>
                {cyber.work}{' '}
                <a
                  href="https://cybercreation.team/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={style.cctName}
                >
                  Cyber Creation Team
                </a>
              </p>
              <p className={style.rightTextInner}>{cyber.allRights}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

const mapStateToProps = state => ({
  locale: sessionSelectors.locale(state),
});

export default connect(mapStateToProps)(Footer);
