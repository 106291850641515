import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import style from './singlePublicationSideNav.module.css';
import * as sessionSelectors from '../../../redux/session/sessionSelectors';
import * as initDataSelectors from '../../../redux/initData/initDataSelectors';

const SinglePublicationSideNav = ({ vidzetyUK, vidzetyRU, locale }) => {
  const vidzety = locale === 'uk' ? vidzetyUK : vidzetyRU;
  return (
    <div className={style.contentBlockWrapper + " right-menu"}>
      {vidzety &&
        Array.isArray(vidzety) &&
        vidzety.map((el) => {

               return  !window.location.pathname.includes(el.url) ?
                    <div key={el.id} className={style.blockWrapper}>
                        <Link className={style.blockLink} to={`/${locale}${el.url}`}>
                            <img className={style.blockIcon} src={el.icon} alt="icon"/>
                            {el.name}
                        </Link>
                    </div>
                    : ''
        })}
    </div>
  );
};

SinglePublicationSideNav.defaultProps = {
  vidzetyUK: [
    {
      id: '1',
      icon: '',
      name: 'serviceName',
      url: '/',
    },
  ],
  vidzetyRU: [
    {
      id: '1',
      icon: '',
      name: 'serviceName',
      url: '/',
    },
  ],
};

SinglePublicationSideNav.propTypes = {
  vidzetyUK: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      icon: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  vidzetyRU: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      icon: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
};

const mapStateToProps = state => ({
  locale: sessionSelectors.locale(state),
  vidzetyUK: initDataSelectors.initDataVidzhetyUK(state),
  vidzetyRU: initDataSelectors.initDataVidzhetyRU(state),
});

export default connect(mapStateToProps)(SinglePublicationSideNav);
