import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import style from './errorPage.module.css';
import * as sessionSelectors from '../../redux/session/sessionSelectors';
import badSmile from '../../assets/img/icons/badSmile.svg';
import baseURL from '../../redux/baseURL';

class InPropgress extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
    axios
      .get(`${baseURL}redirect?search=${this.props.history.location.pathname}`)
      .then(response => {
        try {
          if (response.data.action && response.data.url)
            this.props.history.push(response.data.url);
        } catch (error) {}
      })
      .catch(error => console.log(error));
  };

  render() {
    const { locale, error } = this.props;
    const { title, text, link, bacText } = error;
    return (
      <div className={style.stub}>
        <div className="wrapper">
          <div className={style.errorWrapper}>
            <div className={style.background}>
              <p className={style.backText}>{bacText}</p>
              <p className={style.bacText404}>404</p>
            </div>
            <div className={style.errorTextWrapper}>
              <h1 className={style.errorTitle}>
                {title}
                <img className={style.smile} alt="smile" src={badSmile} />
              </h1>
              <p className={style.errorText}>{text}</p>
              <Link className={style.errorLink} to={`/${locale}/`}>
                {link}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  locale: sessionSelectors.locale(state),
});

export default connect(mapStateToProps)(withRouter(InPropgress));
