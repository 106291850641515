export const ActionType = {
  NEWS_CATEGORY_6_REQUEST: 'NEWS_CATEGORY_6_REQUEST',
  NEWS_CATEGORY_6_SUCCESS_UK: 'NEWS_CATEGORY_6_SUCCESS_UK',
  NEWS_CATEGORY_6_SUCCESS_RU: 'NEWS_CATEGORY_6_SUCCESS_RU',
  NEWS_CATEGORY_6_ERROR_UK: 'NEWS_CATEGORY_6_ERROR_UK',
  NEWS_CATEGORY_6_ERROR_RU: 'NEWS_CATEGORY_6_ERROR_RU',
  NEWS_CATEGORY_6_PAGE_NUMBER_UK: 'NEWS_CATEGORY_6_NUMBER_UK',
  NEWS_CATEGORY_6_PAGE_NUMBER_RU: 'NEWS_CATEGORY_6_NUMBER_RU',
};

export const newsCategory6Request = () => ({
  type: ActionType.NEWS_CATEGORY_6_REQUEST,
});

export const newsCategory6SuccessUK = response => ({
  type: ActionType.NEWS_CATEGORY_6_SUCCESS_UK,
  payload: response,
});

export const newsCategory6SuccessRU = response => ({
  type: ActionType.NEWS_CATEGORY_6_SUCCESS_RU,
  payload: response,
});

export const newsCategory6ErrorUK = error => ({
  type: ActionType.NEWS_CATEGORY_6_ERROR_UK,
  payload: error,
});

export const newsCategory6ErrorRU = error => ({
  type: ActionType.NEWS_CATEGORY_6_ERROR_RU,
  payload: error,
});

export const incrmentnewsCategory6PageNumberUK = () => ({
  type: ActionType.NEWS_CATEGORY_6_PAGE_NUMBER_UK,
});

export const incrmentnewsCategory6PageNumberRU = () => ({
  type: ActionType.NEWS_CATEGORY_6_PAGE_NUMBER_RU,
});
