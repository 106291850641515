export const ActionType = {
  SIDE_NEWS_REQUEST: 'SIDE_NEWS_REQUEST',
  SIDE_NEWS_SUCCESS_UK: 'SIDE_NEWS_SUCCESS_UK',
  SIDE_NEWS_SUCCESS_RU: 'SIDE_NEWS_SUCCESS_RU',
  SIDE_NEWS_ERROR_UK: 'SIDE_NEWS_ERROR_UK',
  SIDE_NEWS_ERROR_RU: 'SIDE_NEWS_ERROR_RU',
};

export const sideNewsRequest = () => ({
  type: ActionType.SIDE_NEWS_REQUEST,
});

export const sideNewsSuccessUK = response => ({
  type: ActionType.SIDE_NEWS_SUCCESS_UK,
  payload: response,
});

export const sideNewsSuccessRU = response => ({
  type: ActionType.SIDE_NEWS_SUCCESS_RU,
  payload: response,
});

export const sideNewsErrorUK = error => ({
  type: ActionType.SIDE_NEWS_ERROR_UK,
  payload: error,
});

export const sideNewsErrorRU = error => ({
  type: ActionType.SIDE_NEWS_ERROR_RU,
  payload: error,
});
