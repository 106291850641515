import React, { Fragment } from "react"
import { RotateSpinLoader } from "react-css-loaders"
import PropTypes from "prop-types"
import style from "./loggedOffice.module.css"
import userLoggedImg from "../../../assets/img/icons/userLogged.svg"

const LoggedInfo = ({ onLogout, textFields, user, loading, error, locale }) => {
  let name
  let email
  let link
  // let avatar;
  const accaunts = []

  if (user) {
    try {
      name = user.profile.name
      email = user.profile.email
      link = user.profile.link
    } catch (error) {}
  }

  const { toOffice, exit } = textFields
  return (
    <div className={style.wrapper}>
      {loading && (
        <div className={style.loader}>
          <RotateSpinLoader color="#FBFBFB" />
        </div>
      )}
      {error && (
        <h2
          style={{
            fontSize: 36,
            textAlign: "center",
            lineHeight: 1.5,
          }}
        >
          {error.message}
        </h2>
      )}
      {!loading && (
        <div>
          <div className={style.userAvaWrapper}>
            <img
              src={userLoggedImg}
              className={style.userAva}
              alt="user avatar"
            />
          </div>
          <div className={style.userTextWrapper}>
            {name && name !== "" && (
              <Fragment>
                <p className={style.userName}>
                  {locale === "uk" ? "Ви увійшли як" : "Вы вошли как"}
                </p>
                <p className={style.userName}>{name}</p>
              </Fragment>
            )}
            {name === "" && (
              <Fragment>
                <p className={style.userName}>
                  {locale === "uk" ? "Ви увійшли як" : "Вы вошли как"}
                </p>
                <p className={style.userName}>Абонент</p>
              </Fragment>
            )}
            <span className={style.userMail}>{email}</span>
          </div>
          <div className={style.buttonsWrapper}>
            <a
              href="https://cpp.infoxvod.com.ua/"
              target="_blank"
              rel="noopener noreferrer"
              className={style.homeLink}
            >
              {toOffice}
            </a>

            <button className={style.exit} onClick={onLogout}>
              {exit}
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

LoggedInfo.defaultProps = {
  user: {
    profile: {
      name: "",
      email: "",
      link: "",
    },
  },
  error: {
    message: "Some error",
  },
}

LoggedInfo.propTypes = {
  user: PropTypes.shape({
    profile: PropTypes.object,
  }),
  error: PropTypes.objectOf(PropTypes.string),
}

export default LoggedInfo
