import { combineReducers } from 'redux';
import { ActionType } from './newsCategory1Actions';

const loading = (state = false, { type }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_1_REQUEST:
      return true;

    case ActionType.NEWS_CATEGORY_1_SUCCESS_UK:
    case ActionType.NEWS_CATEGORY_1_SUCCESS_RU:
    case ActionType.NEWS_CATEGORY_1_ERROR_UK:
    case ActionType.NEWS_CATEGORY_1_ERROR_RU:
      return false;

    default:
      return state;
  }
};

const newsCategory1UK = (state = [], { type, payload }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_1_SUCCESS_UK:
      return state.concat(payload);

    default:
      return state;
  }
};

const newsCategory1RU = (state = [], { type, payload }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_1_SUCCESS_RU:
      return state.concat(payload);

    default:
      return state;
  }
};

const newsCategory1ErrorUK = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_1_ERROR_UK:
      return payload;

    case ActionType.NEWS_CATEGORY_1_REQUEST:
    case ActionType.NEWS_CATEGORY_1_SUCCESS_UK:
      return null;

    default:
      return state;
  }
};

const newsCategory1ErrorRU = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_1_ERROR_RU:
      return payload;

    case ActionType.NEWS_CATEGORY_1_REQUEST:
    case ActionType.NEWS_CATEGORY_1_SUCCESS_RU:
      return null;

    default:
      return state;
  }
};

const newsCategory1PageNumberUK = (state = 1, { type }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_1_PAGE_NUMBER_UK:
      return state + 1;

    default:
      return state;
  }
};

const newsCategory1PageNumberRU = (state = 1, { type }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_1_PAGE_NUMBER_RU:
      return state + 1;

    default:
      return state;
  }
};

export default combineReducers({
  loading,
  newsCategory1UK,
  newsCategory1RU,
  newsCategory1ErrorUK,
  newsCategory1ErrorRU,
  newsCategory1PageNumberUK,
  newsCategory1PageNumberRU,
});
