import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Media from 'react-media';
import style from './indexArticles.module.css';
import * as sessionSelectors from '../../redux/session/sessionSelectors';
import * as startPageNewsSelectors from '../../redux/startPageIndex/startPageIndexSelectors';
import * as onStartPageInit from '../../redux/startPageIndex/startPageIndexOperations';
import IndexArticlesDesk from './indexArticlesDesk/indexArticlesDesk';
import IndexArticlesMob from './indexArticlesMob/indexArticlesMob';

class IndexArticles extends Component {
  componentDidMount = () => {
    const { onInitData } = this.props;
    if (
      (this.props.locale === 'uk' && !this.props.newsListUK) ||
      (this.props.locale === 'ru' && !this.props.newsListRU)
    )
      onInitData();
  };

  componentDidUpdate = prevProps => {
    const { onInitData } = this.props;
    if (
      prevProps.locale !== this.props.locale &&
      this.props.locale === 'ru' &&
      !this.props.newsListRU
    ) {
      onInitData();
    }
    if (
      prevProps.locale !== this.props.locale &&
      this.props.locale === 'uk' &&
      !this.props.newsListUK
    ) {
      onInitData();
    }
  };

  render() {
    const {
      newsListRU = [],
      newsListUK = [],
      locale,
      newsIndex,
      routes,
    } = this.props;
    const { title, button } = newsIndex;
    const news = locale === 'uk' ? newsListUK : newsListRU;
    return (
      <div className="wrapper">
        <h2 className={style.newsName}>
          <span>{title}</span>
          <Media query={{ minWidth: 1200 }}>
            {matches =>
              matches && (
                <Link
                  className={style.linkDesk}
                  to={`/${locale}/${routes.news}`}
                >
                  {button}
                </Link>
              )
            }
          </Media>
        </h2>
        <Media query={{ maxWidth: 1199 }}>
          {matches =>
            matches ? (
              <ul className={style.listMob}>
                {news && Array.isArray(news) && (
                  <IndexArticlesMob locale={locale} articles={news} />
                )}
              </ul>
            ) : (
              <ul className={style.listDesk}>
                {news && Array.isArray(news) && (
                  <IndexArticlesDesk locale={locale} articles={news} />
                )}
              </ul>
            )
          }
        </Media>
        <Media query={{ maxWidth: 1199 }}>
          {matches =>
            matches && (
              <div className={style.buttonMobWrapper}>
                <Link
                  className={style.linkMob}
                  to={`/${locale}/${routes.news}`}
                >
                  {button}
                </Link>
              </div>
            )
          }
        </Media>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  locale: sessionSelectors.locale(state),
  newsListRU: startPageNewsSelectors.startPageNewsRU(state),
  newsListUK: startPageNewsSelectors.startPageNewsUK(state),
});

const mapDispatchToProps = {
  onInitData: onStartPageInit.onStartPageNewsOperation,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexArticles);
