import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Media from 'react-media';
import NewsListDesk from '../../../components/newsList/newsListDesk/newsListDesk';
import NewsListMob from '../../../components/newsList/newsListMob/newsListMob';
import style from './buildingNewsList.module.css';
import * as newsDateSelector from '../../../redux/news/newsDate/newsDateSelectors';
import * as newsDateOperation from '../../../redux/news/newsDate/newsDateOperations';
import * as sessionSelectors from '../../../redux/session/sessionSelectors';
import { newsDateReset } from '../../../redux/news/newsDate/newsDateActions';

class NewsDate extends Component {
  componentDidMount = () => {
    const { onInitData, newsDateReset } = this.props;
    const { date } = this.props.match.params;
    const month = date.slice(-2);
    const year = date.slice(0, 4);
    newsDateReset();
    onInitData(month, year);
  };

  componentDidUpdate = prevProps => {
    const { onInitData, newsDateReset } = this.props;
    const prevDate = prevProps.match.params.date;
    const { date } = this.props.match.params;
    const month = date.slice(-2);
    const year = date.slice(0, 4);
    if (prevDate !== date) {
      newsDateReset();
      onInitData(month, year);
    }
  };

  onLoadMore = () => {
    const { onInitData } = this.props;
    const { date } = this.props.match.params;
    const month = date.slice(-2);
    const year = date.slice(0, 4);
    onInitData(month, year);
  };

  render() {
    const {
      button,
      locale,
      newsDateUK,
      newsDateRU,
      // newsDateErrorUK,
      // newsDateErrorRU,
      newsDateLoading,
    } = this.props;
    const articles =
      locale === 'uk'
        ? newsDateUK.filter(el => el !== null)
        : newsDateRU.filter(el => el !== null);
    const articlesForButton = locale === 'uk' ? newsDateUK : newsDateRU;
    const text =
      locale === 'uk'
        ? 'немає новин по цій категорії'
        : 'нет новостей по этой категории';
    return (
      <Fragment>
        {articles && articles.length > 0 && (
          <Media query={{ minWidth: 1200 }}>
            {matches =>
              matches ? (
                <NewsListDesk news={articles} />
              ) : (
                <NewsListMob news={articles} />
              )
            }
          </Media>
        )}
        {articles.length > 11 &&
          articlesForButton[articlesForButton.length - 1] !== null && (
            <button
              disabled={newsDateLoading}
              onClick={this.onLoadMore}
              className={style.showMore}
            >
              {button}
            </button>
          )}
        {articles.length === 0 && !newsDateLoading && (
          <h2 className={style.error}>{text}</h2>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  locale: sessionSelectors.locale(state),
  newsDateUK: newsDateSelector.newsDateUK(state),
  newsDateRU: newsDateSelector.newsDateRU(state),
  newsDateErrorUK: newsDateSelector.newsDateErrorUK(state),
  newsDateErrorRU: newsDateSelector.newsDateErrorRU(state),
  newsDateLoading: newsDateSelector.newsDateLoading(state),
});

const mapDispatchToProps = {
  onInitData: newsDateOperation.newsDateOperation,
  newsDateReset,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(NewsDate));
