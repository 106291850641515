import React, { Fragment, Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import NewsListDesk from '../../../components/newsList/newsListDesk/newsListDesk';
import NewsListMob from '../../../components/newsList/newsListMob/newsListMob';
import style from './buildingNewsList.module.css';
import * as allNewsSelector from '../../../redux/news/allNews/allNewsSelectors';
import * as allNewsOperation from '../../../redux/news/allNews/allNewsOperations';
import * as sessionSelectors from '../../../redux/session/sessionSelectors';

class AllNews extends Component {
  componentDidMount = () => {
    const { onInitData } = this.props;
    if (
      (this.props.locale === 'uk' && !this.props.allNewsUK.length) ||
      (this.props.locale === 'ru' && !this.props.allNewsRU.length)
    ) {
      onInitData();
    }
  };

  componentDidUpdate = prevProps => {
    const { onInitData } = this.props;
    if (
      prevProps.locale !== this.props.locale &&
      this.props.locale === 'ru' &&
      !this.props.allNewsUK.length
    ) {
      onInitData();
    }
    if (
      prevProps.locale !== this.props.locale &&
      this.props.locale === 'uk' &&
      !this.props.allNewsRU.length
    ) {
      onInitData();
    }
  };

  onLoadMore = () => {
    const { onInitData } = this.props;
    onInitData();
  };

  render() {
    const {
      allNewsUK = [],
      allNewsRU = [],
      locale,
      button,
      allNewsLoading,
    } = this.props;
    const articles =
      locale === 'uk'
        ? allNewsUK.filter(el => el !== null)
        : allNewsRU.filter(el => el !== null);

    const articlesForButton = locale === 'uk' ? allNewsUK : allNewsRU;
    const text =
      locale === 'uk'
        ? 'немає новин по цій категорії'
        : 'нет новостей по этой категории';
    return (
      <Fragment>
        {articles && Array.isArray(articles) && articles.length > 0 && (
          <Media query={{ minWidth: 1200 }}>
            {matches =>
              matches ? (
                <NewsListDesk news={articles} />
              ) : (
                <NewsListMob news={articles} />
              )
            }
          </Media>
        )}
        {Array.isArray(articles) &&
          articles.length > 11 &&
          articlesForButton[articlesForButton.length - 1] !== null && (
            <button
              disabled={allNewsLoading}
              onClick={this.onLoadMore}
              className={style.showMore}
            >
              {button}
            </button>
          )}
        {Array.isArray(articles) &&
          articles.length === 0 &&
          !allNewsLoading && <h2 className={style.error}>{text}</h2>}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  locale: sessionSelectors.locale(state),
  allNewsUK: allNewsSelector.allNewsUK(state),
  allNewsRU: allNewsSelector.allNewsRU(state),
  allNewsErrorUK: allNewsSelector.allNewsErrorUK(state),
  allNewsErrorRU: allNewsSelector.allNewsErrorRU(state),
  allNewsLoading: allNewsSelector.allNewsLoading(state),
});

const mapDispatchToProps = {
  onInitData: allNewsOperation.allNewsOperation,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllNews);
