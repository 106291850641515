import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import style from './newsNavMob.module.css';
import * as sessionSelectors from '../../../redux/session/sessionSelectors';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    width: '100%',
    marginBottom: 20,
    minHeight: 26,
    backgroundColor: 'transparent',
    '& .MuiAppBar-colorDefault': {
      backgroundColor: 'transparent',
    },
    '& .MuiPaper-elevation4': {
      boxShadow: 'none',
    },
    '& .MuiSvgIcon-root': {
      fill: '#315F8F',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#315F8F',
    },
    '& .MuiTabs-scrollButtons': {
      width: 20,
    },
    '& .MuiTab-wrapper': {
      height: '100%',
    },
    '& .MuiTabs-root': {
      minHeight: 26,
    },
    '& .MuiTab-root': {
      padding: 0,
      maxWidth: 'unset',
      minWidth: 'unset',
      minHeight: 26,
      '&:not(:last-child)': {
        marginRight: 20,
      },
    },
  },
}));

function ScrollableTabsButtonForce(props) {
  const { newsNav = [], locale, allName, routes } = props;
  const { news } = routes;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    props.history.push(`/${locale}/${news}`);
  }, []);

  useEffect(() => {
    props.history.push(`/${locale}/${news}`);
  }, [locale]);

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          <Tab
            label={
              <Link className={style.link} to={`/${locale}/${news}`}>
                {allName}
              </Link>
            }
          />
          {newsNav &&
            Array.isArray(newsNav) &&
            newsNav.map(el => (
              <Tab
                key={el.id}
                label={
                  <Link
                    className={style.link}
                    to={`/${locale}/${news}/${el.url}`}
                  >
                    {el.name}
                  </Link>
                }
              />
            ))}
        </Tabs>
      </AppBar>
    </div>
  );
}

ScrollableTabsButtonForce.defaultProps = {
  newsNav: [
    {
      id: '1',
      name: 'menu name',
      url: '',
    },
  ],
};

ScrollableTabsButtonForce.propTypes = {
  newsNav: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
};

const mapStateToProps = state => ({
  locale: sessionSelectors.locale(state),
});

export default connect(mapStateToProps)(withRouter(ScrollableTabsButtonForce));
