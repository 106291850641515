import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import style from './menu.module.css';
import MenuItems from './menuChildren/menuChildren';
import * as sessionSelectors from '../../../../redux/session/sessionSelectors';
import * as initData from '../../../../redux/initData/initDataSelectors';

class MenuDesk extends Component {
  state = {};

  render() {
    const { modalUK = [], modalRU = [], locale } = this.props;
    const modal = locale === 'uk' ? modalUK : modalRU;
    // if (Array.isArray(modal)) modal.length = 5;
    return (
      <div className={style.wrapper}>
        <ul className={style.list}>
          {modal &&
            Array.isArray(modal) &&
            modal.map(el =>
              el.children && el.children.length > 0 ? (
                <li className={style.listItem} key={el.id}>
                  <MenuItems title={el.name} links={el.children} />
                  <svg
                    className={style.arrow}
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.8801 1.22225L11.2789 0.621137C11.1988 0.540864 11.1066 0.500854 11.0022 0.500854C10.8982 0.500854 10.8059 0.540864 10.7258 0.621137L6.00006 5.34665L1.27451 0.621263C1.19436 0.540991 1.10213 0.500981 0.997933 0.500981C0.893696 0.500981 0.801463 0.540991 0.721359 0.621263L0.120283 1.22242C0.04001 1.30253 0 1.39476 0 1.499C0 1.60315 0.0401363 1.69538 0.120283 1.77549L5.72349 7.37881C5.80359 7.45896 5.89587 7.49901 6.00006 7.49901C6.10426 7.49901 6.19637 7.45896 6.27643 7.37881L11.8801 1.77549C11.9602 1.69534 12 1.60311 12 1.499C12 1.39476 11.9602 1.30253 11.8801 1.22225Z"
                      fill="#FBFBFB"
                    />
                  </svg>
                </li>
              ) : (
                <li className={style.listItem} key={el.id}>
                  {!el.external ? (
                    <Link
                      className={style.link}
                      to={el.url ? `/${locale}${el.url}` : '/'}
                    >
                      {el.name}
                    </Link>
                  ) : (
                    <a
                      className={style.link}
                      href={el.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {el.name}
                    </a>
                  )}
                </li>
              ),
            )}
        </ul>
      </div>
    );
  }
}

MenuDesk.defaultProps = {
  modalUK: [
    {
      id: '1',
      name: 'menu',
      external: null,
      url: '',
      children: [],
    },
  ],
  modalRU: [
    {
      id: '1',
      name: 'menu',
      external: null,
      url: '',
      children: [],
    },
  ],
};

MenuDesk.propTypes = {
  modalUK: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      external: PropTypes.number,
      url: PropTypes.string,
      children: PropTypes.array,
    }),
  ),
  modalRU: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      external: PropTypes.number,
      url: PropTypes.string,
      children: PropTypes.array,
    }),
  ),
};

const mapStateToProps = state => ({
  locale: sessionSelectors.locale(state),
  modalUK: initData.initDataModalUK(state),
  modalRU: initData.initDataModalRU(state),
});

export default connect(mapStateToProps)(MenuDesk);
