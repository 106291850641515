import { combineReducers } from 'redux';
import allNews from './allNews/allNewsReducer';
import newsCategory1 from './newsCategory1/newsCategory1Reducer';
import newsCategory2 from './newsCategory2/newsCategory2Reducer';
import newsCategory3 from './newsCategory3/newsCategory3Reducer';
import newsCategory4 from './newsCategory4/newsCategory4Reducer';
import newsCategory5 from './newsCategory5/newsCategory5Reducer';
import newsCategory6 from './newsCategory6/newsCategory6Reducer';
import newsCategory7 from './newsCategory7/newsCategory7Reducer';
import newsDate from './newsDate/newsDateReducer';
import newsNav from './newsNav/newsNavReducer';

export default combineReducers({
  allNews,
  newsCategory1,
  newsCategory2,
  newsCategory3,
  newsCategory4,
  newsCategory5,
  newsCategory6,
  newsCategory7,
  newsDate,
  newsNav,
});
