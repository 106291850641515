import axios from 'axios';
import {
  newsDateRequest,
  newsDateSuccessUK,
  newsDateSuccessRU,
  newsDateErrorUK,
  newsDateErrorRU,
  incrmentNewsDatePageNumberUK,
  incrmentNewsDatePageNumberRU,
} from './newsDateActions';
import { locale } from '../../session/sessionSelectors';
import baseURL from '../../baseURL';

import {
  newsDatePageNumberUK,
  newsDatePageNumberRU,
} from './newsDateSelectors';

const setAxiosLang = lang => {
  axios.defaults.headers.common['Accept-Language'] = lang;
};
// eslint-disable-next-line import/prefer-default-export
export const newsDateOperation = (month, year) => (dispatch, getState) => {
  axios.defaults.baseURL = `${baseURL}`;
  dispatch(newsDateRequest());

  const page =
    locale(getState()) === 'uk'
      ? newsDatePageNumberUK(getState())
      : newsDatePageNumberRU(getState());

  setAxiosLang(locale(getState()));

  axios
    .get(`news?interval=true&month=${month}&year=${year}&limit=12&page=${page}`)
    .then(response => {
      if (locale(getState()) === 'uk')
        try {
          if (response.data.length > 0 && response.data[0].id) {
            dispatch(incrmentNewsDatePageNumberUK());
            dispatch(newsDateSuccessUK(response.data));
          } else {
            dispatch(incrmentNewsDatePageNumberUK());
            dispatch(newsDateSuccessUK(null));
          }
        } catch (error) {
          dispatch(newsDateErrorUK(error));
        }
      else if (locale(getState()) === 'ru') {
        try {
          if (response.data.length > 0 && response.data[0].id) {
            dispatch(incrmentNewsDatePageNumberRU());
            dispatch(newsDateSuccessRU(response.data));
          } else {
            dispatch(incrmentNewsDatePageNumberRU());
            dispatch(newsDateSuccessRU(null));
          }
        } catch (error) {
          dispatch(newsDateErrorRU(error));
        }
      }
    })
    .catch(error => {
      console.log(error);
      if (locale(getState()) === 'uk') dispatch(newsDateErrorUK(error));
      else {
        dispatch(newsDateErrorRU(error));
      }
    });
};
