import { combineReducers } from 'redux';
import { ActionType } from './newsCategory7Actions';

const loading = (state = false, { type }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_7_REQUEST:
      return true;

    case ActionType.NEWS_CATEGORY_7_SUCCESS_UK:
    case ActionType.NEWS_CATEGORY_7_SUCCESS_RU:
    case ActionType.NEWS_CATEGORY_7_ERROR_UK:
    case ActionType.NEWS_CATEGORY_7_ERROR_RU:
      return false;

    default:
      return state;
  }
};

const newsCategory7UK = (state = [], { type, payload }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_7_SUCCESS_UK:
      return state.concat(payload);

    default:
      return state;
  }
};

const newsCategory7RU = (state = [], { type, payload }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_7_SUCCESS_RU:
      return state.concat(payload);

    default:
      return state;
  }
};

const newsCategory7ErrorUK = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_7_ERROR_UK:
      return payload;

    case ActionType.NEWS_CATEGORY_7_REQUEST:
    case ActionType.NEWS_CATEGORY_7_SUCCESS_UK:
      return null;

    default:
      return state;
  }
};

const newsCategory7ErrorRU = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_7_ERROR_RU:
      return payload;

    case ActionType.NEWS_CATEGORY_7_REQUEST:
    case ActionType.NEWS_CATEGORY_7_SUCCESS_RU:
      return null;

    default:
      return state;
  }
};

const newsCategory7PageNumberUK = (state = 1, { type }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_7_PAGE_NUMBER_UK:
      return state + 1;

    default:
      return state;
  }
};

const newsCategory7PageNumberRU = (state = 1, { type }) => {
  switch (type) {
    case ActionType.NEWS_CATEGORY_7_PAGE_NUMBER_RU:
      return state + 1;

    default:
      return state;
  }
};

export default combineReducers({
  loading,
  newsCategory7UK,
  newsCategory7RU,
  newsCategory7ErrorUK,
  newsCategory7ErrorRU,
  newsCategory7PageNumberUK,
  newsCategory7PageNumberRU,
});
