export const newsCategory5UK = state =>
  state.news.newsCategory5.newsCategory5UK;
export const newsCategory5RU = state =>
  state.news.newsCategory5.newsCategory5RU;
export const newsCategory5ErrorUK = state =>
  state.news.newsCategory5.newsCategory5ErrorRU;
export const newsCategory5ErrorRU = state =>
  state.news.newsCategory5.newsCategory5ErrorRU;
export const newsCategory5Loading = state => state.news.newsCategory5.loading;
export const newsCategory5PageNumberUK = state =>
  state.news.newsCategory5.newsCategory5PageNumberUK;
export const newsCategory5PageNumberRU = state =>
  state.news.newsCategory5.newsCategory5PageNumberRU;
