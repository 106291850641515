export const newsCategory7UK = state =>
  state.news.newsCategory7.newsCategory7UK;
export const newsCategory7RU = state =>
  state.news.newsCategory7.newsCategory7RU;
export const newsCategory7ErrorUK = state =>
  state.news.newsCategory7.newsCategory7ErrorRU;
export const newsCategory7ErrorRU = state =>
  state.news.newsCategory7.newsCategory7ErrorRU;
export const newsCategory7Loading = state => state.news.newsCategory7.loading;
export const newsCategory7PageNumberUK = state =>
  state.news.newsCategory7.newsCategory7PageNumberUK;
export const newsCategory7PageNumberRU = state =>
  state.news.newsCategory7.newsCategory7PageNumberRU;
