import React from 'react'
import { connect } from 'react-redux'
import { Form, Button, Row, Col, Spinner, Dropdown } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import style from '../debtorsPage.module.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import * as debtorsSelectors from '../../../redux/debtors/debtorsSelectors'
import * as debtorsActions from '../../../redux/debtors/debtorsActions'
import * as debtorsOperations from '../../../redux/debtors/debtorsOperations'
import { SearchAlert } from './Alert'
import { Input } from '@material-ui/core'
import { DistrictsSelect } from './DistrictsSelect'

class DebtorsForm extends React.Component {
  constructor(props) {
    super(props)
    this.distsArr = [
      { id: 0, name: '-' },
      { id: 18, name: 'Варварівка' },
      { id: 19, name: 'Велика Корениха' },
      { id: 5, name: 'Заводський' },
      { id: 3, name: 'Інгульський' },
      { id: 4, name: 'Корабельний' },
      { id: 20, name: 'Матвєєвка' },
      { id: 17, name: 'Надбузьке' },
      { id: 2, name: 'Центральний' },
    ]
    this.state = {
      district: { id: 0, name: '-' },
      searchType: 0,
      street: [],
      building: [],
      streetsArr: [],
      buildingsArr: [],
      serialNumber: '',
    }
    this.formRef = React.createRef()
    this.alertRef = React.createRef()
  }

  focusStbdHandler = (event) => {
    if (this.state.searchType === 1) return
    this.setState({ searchType: 1 })
  }

  focusSnHandler = (event) => {
    if (this.state.searchType === 2) return
    this.setState({ searchType: 2 })
  }

  toggleForm = (event) => {
    event.preventDefault()
    if (this.props.form.isOpened) this.props.collapseForm()
    else this.props.openForm()
  }

  districtChangeHandler = (event) => {
    event.preventDefault()
    this.setState({ street: [], building: [], serialNumber: [] })

    this.props.changeStreet({ street: '' })
    this.props.changeBuilding({ building: '' })
    this.props.changeSerialNumber({ serialNumber: '' })

    const districtId = Number(event.target.value)
    const districtsInStorage = []
    const districtObj = { id: districtId, name: this.distsArr[districtId] }

    this.props.changeDistrict({
      district: districtObj,
    })

    this.props.districtsData.storage.map((item) =>
      districtsInStorage.push(item.id)
    )

    if (districtId !== 0 && !districtsInStorage.includes(districtId)) {
      this.props.getDistrictData()
    }
  }

  changeSerialNumberHandler = (event) => {
    event.persist()
    this.setState((prev) => ({
      ...prev,
      ...{
        [event.target.name]: event.target.value,
      },
    }))
  }

  streetChangeHandler = () => {
    this.props.changeStreet({ street: this.state.street })
    this.props.getStreetData()
  }

  getStreets() {
    let streets = []
    this.props.districtsData.storage.map((item) => {
      if (item.id === 0) streets = []
      else if (item.id === this.props.search.district.id) {
        streets = item.streets.map((street) => {
          return {
            id: street.id,
            name: street.street,
          }
        })
      }
    })

    return streets
  }

  getBuildings() {
    let buildings = []

    this.props.streetData.Data.map((street) => {
      buildings.push(street.dom)
    })

    buildings.sort()

    const uniqueBuildings = new Set(buildings)
    buildings = [...uniqueBuildings]
    return buildings
  }

  submitHandler = (event) => {
    event.preventDefault()
    let submitError = false

    switch (this.state.searchType) {
      case 1:
        if (this.state.street.length > 0) {
          if (this.state.building.length > 0)
            this.props.changeBuilding({
              building: this.state.building.toString(),
            })
          this.props.displayDataOperation(this.state.searchType)
          this.submitStateReset()
        } else {
          submitError = true
          this.props.showAlert(`Невірно вказана вулиця.`, 10)
        }
        break
      case 2:
        let sn = this.state.serialNumber || ''
        if (sn.length === 8 || sn.length === 10) {
          sn = Number(sn)

          if (!isNaN(sn)) {
            this.props.changeSerialNumber({
              serialNumber: sn.toString(),
            })
            this.props.displayDataOperation(this.state.searchType)
            this.submitStateReset()
          } else {
            submitError = true
            this.props.showAlert(
              'Розрахунковий рахунок може скаладатися лише з цифер.',
              5
            )
          }
        } else {
          submitError = true
          this.props.showAlert(
            `Невірно вказаний розрахунковий рахунок. Ви ввели ${sn.length} символів.`,
            10
          )
        }

        break
      default:
        submitError = true
        this.props.showAlert('Не обрано жодного параметру для пошуку.', 5)
        break
    }
    if (submitError) {
      window.scrollTo(0, this.alertRef.current.offsetTop - 250)
    } else window.scrollTo(0, this.formRef.current.offsetTop - 10)
  }

  submitStateReset() {
    this.setState({
      building: [],
      street: [],
      serialNumber: '',
      formOpened: false,
      searchType: 0,
    })
  }

  render() {
    return (
      <Form
        className={`${style.searchForm} ${
          this.props.form.isOpened ? '' : style.formCollapse
        }`}
        onSubmit={this.submitHandler}
        ref={this.formRef}
      >
        <Row onClick={this.toggleForm} style={{ cursor: 'pointer' }}>
          <Col l={6}>
            <span className={style.searchTitle}>Пошук</span>{' '}
            {this.props.loading ? (
              <Spinner animation='grow' role='status' variant='primary'>
                <span className='sr-only'>Loading...</span>
              </Spinner>
            ) : (
              ''
            )}
          </Col>
          <Col l={6}>
            <div className='d-flex justify-content-end'>
              <button
                type='button'
                onClick={this.toggleForm}
                className={`${style.toggleDebtorsButton} ${
                  this.props.form.isOpened
                    ? style.toggleDebtorsButtonActive
                    : ''
                }`}
              ></button>
            </div>
          </Col>
        </Row>
        <Form.Group className={style.districtSelect}>
          <Form.Label>Оберіть район</Form.Label>
          <DistrictsSelect
            changeDistrictHandler={this.districtChangeHandler}
            districts={this.distsArr}
          />
        </Form.Group>
        <Form.Group
          className={`${style.someSearch} ${
            this.state.searchType === 1 ? style.someSearchActive : ''
          }`}
        >
          <h5>Пошук по вулиці і будинку:</h5>
          <Row>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Вулиця</Form.Label>
                <Typeahead
                  {...this.state}
                  name='street'
                  id='street'
                  onChange={(street) => {
                    this.setState({ street })
                    if (street.length > 0) {
                      this.props.changeStreet({ street: street[0] })
                      this.props.getStreetData()
                    }
                  }}
                  selected={this.state.street}
                  options={this.getStreets()}
                  labelKey='name'
                  onFocus={this.focusStbdHandler}
                  placeholder='Виберіть вулицю'
                  disabled={
                    this.props.search.district.id === 0 || this.props.loading
                  }
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label>Будинок</Form.Label>
                <Typeahead
                  {...this.state}
                  name='building'
                  id='building'
                  onChange={(building) => {
                    this.setState({ building })
                  }}
                  selected={this.state.building}
                  options={
                    this.props.streetData.Data.length > 0
                      ? this.getBuildings()
                      : []
                  }
                  labelKey='name'
                  placeholder='Виберіть будинок'
                  onFocus={this.focusStbdHandler}
                  disabled={
                    this.props.search.district.id === 0 ||
                    this.props.loading ||
                    !this.state.street.length > 0
                  }
                />
              </Form.Group>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group
          className={`${style.someSearch} ${
            this.state.searchType === 2 ? style.someSearchActive : ''
          }`}
        >
          <h5>Пошук за розрахунковим рахунком:</h5>
          <Form.Label>Розрахунковий рахунок №: </Form.Label>

          <Form.Control
            type='text'
            name='serialNumber'
            id='serialNumber'
            maxLength='10'
            placeholder='Зразок: хххххххх або 21хххххххх'
            value={this.state.serialNumber}
            onChange={this.changeSerialNumberHandler}
            onFocus={this.focusSnHandler}
            disabled={this.props.search.district.id === 0 || this.props.loading}
          />

          <Form.Text id='serialNumber' className='mb-2 text-white'>
            Номер рахунку може складатися з 8 або 10 цифер.
          </Form.Text>
        </Form.Group>{' '}
        <div ref={this.alertRef}>
          {this.props.alert.text && (
            <SearchAlert text={this.props.alert.text} />
          )}
        </div>
        <div className='d-flex flex-row-reverse'>
          <button
            type='submit'
            disabled={this.props.search.district.id === 0 || this.props.loading}
            className={style.searchButton}
          >
            Знайти
          </button>
        </div>
      </Form>
    )
  }
}

const mapDispatchToProps = {
  changeDistrict: debtorsActions.changeDistrict,
  changeStreet: debtorsActions.changeStreet,
  changeBuilding: debtorsActions.changeBuilding,
  changeSerialNumber: debtorsActions.changeSerialNumber,

  getDistrictData: debtorsOperations.getDistrictData,
  getStreetData: debtorsOperations.getStreetData,

  showAlert: debtorsActions.showAlert,
  enableLoader: debtorsActions.enableLoader,
  disableLoader: debtorsActions.disableLoader,

  setDisplayData: debtorsActions.setDisplayData,
  resetDisplayData: debtorsActions.resetDisplayData,
  setDataReadyToDisplay: debtorsActions.setDataReadyToDisplay,

  openForm: debtorsActions.openForm,
  collapseForm: debtorsActions.collapseForm,

  displayDataOperation: debtorsOperations.displayDataOperation,
}
const mapStateToProps = (state) => ({
  loading: debtorsSelectors.loading(state),
  alert: debtorsSelectors.alert(state),
  search: debtorsSelectors.search(state),
  districtsData: debtorsSelectors.districtsData(state),
  streetData: debtorsSelectors.streetData(state),
  serialNumberData: debtorsSelectors.serialNumberData(state),
  form: debtorsSelectors.form(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(DebtorsForm)
