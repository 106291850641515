export const ActionType = {
  CURRENT_DISTRICT_DATA_REQUEST: 'CURRENT_DISTRICT_DATA_REQUEST',
  CURRENT_DISTRICT_DATA_SUCCESS: 'CURRENT_DISTRICT_DATA_SUCCESS',
  CURRENT_DISTRICT_DATA_ERROR: 'CURRENT_DISTRICT_DATA_ERROR',
  RESET_CURRENT_DISTRICT_DATA: 'RESET_CURRENT_DISTRICT_DATA',

  STREET_DATA_REQUEST: 'STREET_DATA_REQUEST',
  STREET_DATA_SUCCESS: 'STREET_DATA_SUCCESS',
  STREET_DATA_ERROR: 'STREET_DATA_ERROR',
  RESET_STREET_DATA: 'RESET_STREET_DATA',

  SN_DATA_REQUEST: 'SN_DATA_REQUEST',
  SN_DATA_SUCCESS: 'SN_DATA_SUCCESS',
  SN_DATA_ERROR: 'SN_DATA_ERROR',
  RESET_SN_DATA: 'RESET_SN_DATA',

  SAVE_DISTRICTS_DATA: 'SAVE_DISTRICT_DATA',
  CHANGE_DISTRICT: 'CHANGE_DISTRICT',
  CHANGE_STREET: 'CHANGE_STREET',
  CHANGE_BUILDING: 'CHANGE_BUILDING',
  CHANGE_PERSONS_SERIAL_NUMBER: 'CHANGE_PERSONS_SERIAL_NUMBER',
  RESET_SEARCH_VALUES: 'RESET_SEARCH_VALUES',

  SHOW_ALERT: 'SHOW_ALERT',
  HIDE_ALERT: 'HIDE_ALERT',

  OPEN_FORM: 'OPEN_FORM',
  COLLAPSE_FORM: 'COLLAPSE_FORM',

  ENABLE_LOADER: 'ENABLE_LOADER',
  DISABLE_LOADER: 'DISABLE_LOADER',

  SET_DATA_READY_TO_DISPLAY: 'SET_DATA_READY_TO_DISPLAY',
  SET_DISPLAY_DATA: 'SET_DISPLAY_DATA',
  RESET_DISPLAY_DATA: 'RESET_DISPLAY_DATA',
}

export const currentDataRequest = () => ({
  type: ActionType.CURRENT_DISTRICT_DATA_REQUEST,
})

export const currentDataSuccess = (response) => ({
  type: ActionType.CURRENT_DISTRICT_DATA_SUCCESS,
  payload: response,
})

export const currentDataError = (error) => ({
  type: ActionType.CURRENT_DISTRICT_DATA_ERROR,
  payload: error,
})

export const resetCurrentData = () => ({
  type: ActionType.RESET_CURRENT_DISTRICT_DATA,
})

//-------------------------------------

export const streetDataRequest = () => ({
  type: ActionType.STREET_DATA_REQUEST,
})

export const streetDataSuccess = (response) => ({
  type: ActionType.STREET_DATA_SUCCESS,
  payload: response,
})

export const streetDataError = (error) => ({
  type: ActionType.STREET_DATA_ERROR,
  payload: error,
})

export const streetDataReset = () => ({
  type: ActionType.RESET_STREET_DATA,
})

//-----------------------------------------

export const snDataRequest = () => ({
  type: ActionType.SN_DATA_REQUEST,
})

export const snDataSuccess = (response) => ({
  type: ActionType.SN_DATA_SUCCESS,
  payload: response,
})

export const snDataError = (error) => ({
  type: ActionType.SN_DATA_ERROR,
  payload: error,
})

export const snDataReset = () => ({
  type: ActionType.RESET_SN_DATA,
})

//-------------------------------------

export const saveDistrictsData = (data) => ({
  type: ActionType.SAVE_DISTRICTS_DATA,
  payload: data,
})

export const changeDistrict = (newDistrict) => ({
  type: ActionType.CHANGE_DISTRICT,
  payload: newDistrict,
})

export const changeStreet = (newStreet) => ({
  type: ActionType.CHANGE_STREET,
  payload: newStreet,
})

export const changeBuilding = (newBuilding) => ({
  type: ActionType.CHANGE_BUILDING,
  payload: newBuilding,
})

export const changeSerialNumber = (newSerialNumber) => ({
  type: ActionType.CHANGE_PERSONS_SERIAL_NUMBER,
  payload: newSerialNumber,
})

export function showAlert(text, time = 4) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SHOW_ALERT,
      payload: text,
    })

    setTimeout(() => {
      dispatch(hideAlert())
    }, time * 1000)
  }
}

export function hideAlert() {
  return {
    type: ActionType.HIDE_ALERT,
  }
}

export const enableLoader = () => ({
  type: ActionType.ENABLE_LOADER,
})

export const disableLoader = () => ({
  type: ActionType.DISABLE_LOADER,
})

export const setDisplayData = (data) => ({
  type: ActionType.SET_DISPLAY_DATA,
  payload: data,
})

export const resetDisplayData = () => ({
  type: ActionType.RESET_DISPLAY_DATA,
})

export const setDataReadyToDisplay = (state) => ({
  type: ActionType.SET_DATA_READY_TO_DISPLAY,
  payload: state,
})

export const collapseForm = () => ({
  type: ActionType.COLLAPSE_FORM,
})

export const openForm = () => ({
  type: ActionType.OPEN_FORM,
})

export const resetSearchValues = () => ({
  type: ActionType.RESET_SEARCH_VALUES,
})
