import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import style from './newsListDesk.module.css';
import * as sessionSelectors from '../../../redux/session/sessionSelectors';

const length = someString => {
  try {
    if (someString.length <= 56) return someString;
    return `${someString.slice(0, 53)}...`;
  } catch (error) {}
};

const NewsListDesk = ({ news = [], locale }) => {
  return (
    <Fragment>
      {news.length > 0 && Array.isArray(news) && (
        <ul className={style.listDesk}>
          {news.map(article => (
            <li className={style.listItem} key={article.id}>
              <div className={style.imgWrapper}>
                <picture>
                  <source srcSet={article.icon} type="image/webp" />
                  <source srcSet={article.icon} type="image/jp2" />
                  <source srcSet={article.icon} type="image/jpg" />
                  <img
                    className={style.img}
                    src={article.icon}
                    alt="news img"
                  />
                </picture>
                <div className={style.darkBack} />
              </div>
              <div className={style.textArticle}>
                <h3 className={style.title}>{length(article.title)}</h3>
                <p className={style.text}>{article.brief}</p>
              </div>
              <Link
                className={style.linkArticle}
                to={`/${locale}${article.url}`}
              />
              <div className={style.date}>{article.published}</div>
              <div className={style.line} />
            </li>
          ))}
        </ul>
      )}
      {!news.length && (
        <h2 className={style.error}>Not found news by this category</h2>
      )}
    </Fragment>
  );
};

NewsListDesk.defaultProps = {
  news: [
    {
      icon: '',
      id: 1,
      brief: 'Article about',
      url: ' ',
      published: '01.01.2019',
    },
  ],
};

NewsListDesk.propTypes = {
  news: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      id: PropTypes.number,
      brief: PropTypes.string,
      url: PropTypes.string,
      published: PropTypes.string,
    }),
  ),
};

const mapStateToProps = state => ({
  locale: sessionSelectors.locale(state),
});

export default connect(mapStateToProps)(NewsListDesk);
