export const newsCategory2UK = state =>
  state.news.newsCategory2.newsCategory2UK;
export const newsCategory2RU = state =>
  state.news.newsCategory2.newsCategory2RU;
export const newsCategory2ErrorUK = state =>
  state.news.newsCategory2.newsCategory2ErrorRU;
export const newsCategory2ErrorRU = state =>
  state.news.newsCategory2.newsCategory2ErrorRU;
export const newsCategory2Loading = state => state.news.newsCategory2.loading;
export const newsCategory2PageNumberUK = state =>
  state.news.newsCategory2.newsCategory2PageNumberUK;
export const newsCategory2PageNumberRU = state =>
  state.news.newsCategory2.newsCategory2PageNumberRU;
