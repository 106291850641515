import { combineReducers } from 'redux';
import { ActionType } from './mapActions';

const loading = (state = false, { type }) => {
  switch (type) {
    case ActionType.MAP_REQUEST:
      return true;

    case ActionType.MAP_SUCCESS:
    case ActionType.MAP_ERROR:
      return false;

    default:
      return state;
  }
};

const map = (state = {}, { type, payload }) => {
  switch (type) {
    case ActionType.MAP_SUCCESS:
      return payload;

    default:
      return state;
  }
};

const mapError = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.MAP_ERROR:
      return payload;

    case ActionType.MAP_REQUEST:
    case ActionType.MAP_SUCCESS:
      return null;

    default:
      return state;
  }
};

export default combineReducers({
  loading,
  map,
  mapError,
});
