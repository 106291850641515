import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import style from './magicIndex.module.css';
import MagicIndexMob from './magicIndexMob/magicIndexMob';
import MagicIndexDesk from './magicIndexDesk/magicIndexDesk';
import * as sessionSelectors from '../../redux/session/sessionSelectors';
import * as startPageNewsSelectors from '../../redux/startPageIndex/startPageIndexSelectors';
import * as onStartPageInit from '../../redux/startPageIndex/startPageIndexOperations';

class MagicIndex extends Component {
  componentDidMount = () => {
    const { onInitData } = this.props;
    if (
      (this.props.locale === 'uk' && !this.props.newsListUK) ||
      (this.props.locale === 'ru' && !this.props.newsListRU)
    )
      onInitData();
  };

  componentDidUpdate = prevProps => {
    const { onInitData } = this.props;
    if (
      prevProps.locale !== this.props.locale &&
      this.props.locale === 'ru' &&
      !this.props.newsListRU
    ) {
      onInitData();
    }
    if (
      prevProps.locale !== this.props.locale &&
      this.props.locale === 'uk' &&
      !this.props.newsListUK
    ) {
      onInitData();
    }
  };

  render() {
    const { viber, office, viberMob } = this.props;
    return (
      <Media query={{ maxWidth: 1199 }}>
        {matches =>
          matches ? (
            <MagicIndexMob office={office} viberMob={viberMob} />
          ) : (
            <div className={style.overflow}>
              <div className={style.wrapper} />
              <MagicIndexDesk office={office} viber={viber} />
            </div>
          )
        }
      </Media>
    );
  }
}

const mapStateToProps = state => ({
  locale: sessionSelectors.locale(state),
  newsListRU: startPageNewsSelectors.startPageNewsRU(state),
  newsListUK: startPageNewsSelectors.startPageNewsUK(state),
});

const mapDispatchToProps = {
  onInitData: onStartPageInit.onStartPageNewsOperation,
};
export default connect(mapStateToProps, mapDispatchToProps)(MagicIndex);
