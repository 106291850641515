import axios from 'axios';
import {
  newsCategory5Request,
  newsCategory5SuccessUK,
  newsCategory5SuccessRU,
  newsCategory5ErrorUK,
  newsCategory5ErrorRU,
  incrmentnewsCategory5PageNumberUK,
  incrmentnewsCategory5PageNumberRU,
} from './newsCategory5Actions';
import { locale } from '../../session/sessionSelectors';
import baseURL from '../../baseURL';

import {
  newsCategory5PageNumberUK,
  newsCategory5PageNumberRU,
} from './newsCategory5Selectors';

const setAxiosLang = lang => {
  axios.defaults.headers.common['Accept-Language'] = lang;
};
// eslint-disable-next-line import/prefer-default-export
export const newsCategory5Operation = () => (dispatch, getState) => {
  axios.defaults.baseURL = `${baseURL}`;
  dispatch(newsCategory5Request());

  const page =
    locale(getState()) === 'uk'
      ? newsCategory5PageNumberUK(getState())
      : newsCategory5PageNumberRU(getState());

  setAxiosLang(locale(getState()));

  axios
    .get(`news?categoriesId=5&limit=12&page=${page}`)
    .then(response => {
      if (locale(getState()) === 'uk')
        try {
          if (response.data.proekti.length > 0 && response.data.proekti[0].id) {
            dispatch(incrmentnewsCategory5PageNumberUK());
            dispatch(newsCategory5SuccessUK(response.data.proekti));
          } else {
            dispatch(incrmentnewsCategory5PageNumberUK());
            dispatch(newsCategory5SuccessUK(null));
          }
        } catch (error) {
          dispatch(newsCategory5ErrorUK(error));
        }
      else if (locale(getState()) === 'ru') {
        try {
          if (response.data.proekti.length > 0 && response.data.proekti[0].id) {
            dispatch(incrmentnewsCategory5PageNumberRU());
            dispatch(newsCategory5SuccessRU(response.data.proekti));
          } else {
            dispatch(incrmentnewsCategory5PageNumberRU());
            dispatch(newsCategory5SuccessRU(null));
          }
        } catch (error) {
          dispatch(newsCategory5ErrorRU(error));
        }
      }
    })
    .catch(error => {
      console.log(error);
      if (locale(getState()) === 'uk') dispatch(newsCategory5ErrorUK(error));
      else {
        dispatch(newsCategory5ErrorRU(error));
      }
    });
};
