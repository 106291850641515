export const ActionType = {
  SINGLE_NEWS_REQUEST: 'SINGLE_NEWS_REQUEST',
  SINGLE_NEWS_SUCCESS_UK: 'SINGLE_NEWS_SUCCESS_UK',
  SINGLE_NEWS_SUCCESS_RU: 'SINGLE_NEWS_SUCCESS_RU',
  SINGLE_NEWS_ERROR_UK: 'SINGLE_NEWS_ERROR_UK',
  SINGLE_NEWS_ERROR_RU: 'SINGLE_NEWS_ERROR_RU',
  RESET_SINGLE_NEWS: 'RESET_SINGLE_NEWS',
};

export const singleNewsRequest = () => ({
  type: ActionType.SINGLE_NEWS_REQUEST,
});

export const singleNewsSuccessUK = response => ({
  type: ActionType.SINGLE_NEWS_SUCCESS_UK,
  payload: response,
});

export const singleNewsSuccessRU = response => ({
  type: ActionType.SINGLE_NEWS_SUCCESS_RU,
  payload: response,
});

export const singleNewsErrorUK = error => ({
  type: ActionType.SINGLE_NEWS_ERROR_UK,
  payload: error,
});

export const singleNewsErrorRU = error => ({
  type: ActionType.SINGLE_NEWS_ERROR_RU,
  payload: error,
});

export const resetSingleNews = () => ({
  type: ActionType.RESET_SINGLE_NEWS,
});
